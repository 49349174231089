import React, { useContext, useEffect, useState } from 'react';
import AgencyContext from '../../contexts/agencyContext';
import Resizer from 'react-image-file-resizer';
import checkBlanc from '../../assets/checkBlanc.png';
//import PhoneInput from "react-phone-number-input";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, storage } from '../../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

export default function ParametresAgence(props) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const agency = useContext(AgencyContext);

	const [widthEnregistre, setWidthEnregistre] = useState(0);
	const [heightEnregistre, setHeightEnregistre] = useState(0);
	const [opacityEnregistre, setOpacityEnregistre] = useState(0);
	const [isCopied, setIsCopied] = useState(false);
	const [modalDefaultCollaborators, setModalDefaultCollaborators] = useState(false);

	const [uid, setUid] = useState();
	const [userSession, setUserSession] = useState(null);

	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
				setUid(user.uid);
			} else navigate('/');
		});
		return () => {
			listener();
		};
	}, [userSession, agency, uid]);

	//-------------------------------------------------------------------------------------

	const [newAgencyData, setNewAgencyData] = useState();

	useEffect(() => {
		if (agency) {
			const subscriber = firestore.doc(`agencies/${agency}`).onSnapshot((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setLogo(documentSnapshot.data().logoUrl);
					setNewAgencyData(documentSnapshot.data());
				}
			});
			return () => subscriber();
		}
	}, [agency]);

	function handleChange(e) {
		setNewAgencyData({ ...newAgencyData, [e.target.id]: e.target.value });
	}

	//-------------------------------------------------------------------------------------

	const [logo, setLogo] = useState();
	const [isLoading, setIsLoading] = useState(false);

	function generateUniqueFirestoreId() {
		const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let autoId = '';
		for (let i = 0; i < 20; i++) {
			autoId += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return autoId;
	}

	const imageHandler1 = async (e) => {
		setIsLoading(true);

		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setLogo(reader.result);
			}
		};
		reader.readAsDataURL(e.target.files[0]);

		const photo = e.target.files[0];
		const storageRef = storage.ref(`agencies/${agency}/logo`);
		const fileRef = storageRef.child(generateUniqueFirestoreId());
		Resizer.imageFileResizer(
			photo,
			2000,
			2000,
			'jpeg',
			50,
			0,
			async (file) => {
				await fileRef.put(file);
				setNewAgencyData({ ...newAgencyData, logoUrl: await fileRef.getDownloadURL() });
				setIsLoading(false);
			},
			'file'
		);
	};

	//-------------------------------------------------------------------------------------

	return (
		<>
			{newAgencyData && (
				<div className={'contenu'}>
					<div className={'inner'} style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
						<div
							className={'enregistre'}
							style={{ width: widthEnregistre, height: heightEnregistre, opacity: opacityEnregistre }}>
							<img src={checkBlanc} alt="" className={'checkBlanc'} />
							<p className={'textEnregistre'}>Enregistré</p>
						</div>
						<p className="title" style={{ marginTop: 20, fontWeight: 700 }}>
							{t('settings_agency.settings_agency')}
						</p>

						<div>
							<div
								className={'banniere'}
								style={{
									height: 60,
									paddingTop: 10,
									marginTop: 20,
									marginBottom: 5,
								}}>
								<p className={'title'}>{t('settings_agency.requirement_settings')}</p>
								<p className={'subtitle'}>{t('settings_agency.alert_settings_agency')}</p>
							</div>
						</div>

						<p className={'information'} style={{ marginBottom: '0px', fontSize: 13 }}>
							{t('settings_agency.infos_settings_agency')}
						</p>

						<div style={{ display: 'flex' }}>
							<div style={{ width: 260 }}>
								<p className={'designantInput'} style={{ marginTop: 65 }}>
									{t('settings_agency.agency_logo')}
								</p>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginLeft: 150,
									alignItems: 'center',
								}}>
								<img src={logo} alt={''} className={'agencePicture'} />
								<div className="label">
									<label htmlFor="inputLogo" className="image-upload" style={{ cursor: 'pointer' }}>
										{t('common.edit')}
									</label>
								</div>
								<p style={{ fontSize: 12, opacity: 0.8, marginTop: 0, letterSpacing: 0.6 }}>
									( {t('common.optimal')} : 1670  ×  424 )
								</p>
								<input
									type="file"
									id="inputLogo"
									accept="image/*,.heic, .heif"
									onChange={imageHandler1}
								/>
							</div>
						</div>

						<div>
							<div className={'longBar'} style={{ marginBottom: 15, marginTop: 15 }} />
						</div>

						<div style={{ display: 'flex' }}>
							<div style={{ width: 260 }}>
								<p className={'designantInput'}>{t('settings_agency.company_name')}</p>
							</div>
							<input
								type="text"
								id={'name'}
								placeholder={t('settings_agency.company_name')}
								value={newAgencyData?.name}
								onChange={handleChange}
							/>
						</div>

						<div>
							<div className={'longBar'} style={{ marginBottom: 15, marginTop: 15 }} />
						</div>

						<div style={{ display: 'flex' }}>
							<div style={{ width: 260 }}>
								<p className={'designantInput'}>{t('onboarding.company_mini_name')}</p>
							</div>
							<input
								type="text"
								id={'diminutifAgency'}
								value={newAgencyData.diminutifAgency}
								placeholder={t('onboarding.company_mini_name_five')}
								maxLength={5}
								onChange={handleChange}
							/>
							<p
								style={{
									marginLeft: 10,
									marginTop: 30,
									fontSize: 14,
									fontWeight: '700',
									color:
										newAgencyData.diminutifAgency?.length === 0
											? 'rgba(0,0,0,0.7)'
											: newAgencyData.diminutifAgency?.length < 6
												? '#19a81f'
												: '#b41111',
								}}>
								{newAgencyData.diminutifAgency?.length}
							</p>
						</div>

						<div>
							<div className={'longBar'} style={{ marginBottom: 15, marginTop: 15 }} />
						</div>

						{/*<div style={{ display: 'flex' }}>
							<div style={{ width: 200, marginTop: -10, marginBottom: 10 }}>
								<p className={'designantInput'}>{t('settings_agency.collabs_project')}</p>
							</div>

							<button
								style={{
									alignSelf: 'center',
									marginLeft: 60,
									height: 27,
									border: 'none',
									borderRadius: 8,
									backgroundColor: 'rgba(79,102,228,1)',
									padding: '5px 12px',
									color: '#fff',
									fontWeight: 600,
									fontSize: 13,
								}}
								onClick={() => setModalDefaultCollaborators(true)}>
								{t('settings_agency.manage_collaborators')}
							</button>
						</div>

						<div>
							<div className={'longBar'} style={{ marginBottom: 15, marginTop: 15 }} />
						</div>*/}

						<div style={{ display: 'flex' }}>
							<div style={{ width: 260 }}>
								<p className={'designantInput'}>{t('settings_agency.postal_address')}</p>
							</div>

							<input
								type="text"
								id={'address'}
								placeholder={t('settings_agency.postal_address')}
								value={newAgencyData.address}
								onChange={handleChange}
							/>
						</div>

						<div>
							<div className={'longBar'} style={{ marginBottom: 15, marginTop: 15 }} />
						</div>

						<div style={{ display: 'flex' }}>
							<div style={{ width: 260 }}>
								<p className={'designantInput'}>{t('common.email_adresse')}</p>
							</div>

							<input
								type="text"
								id={'email'}
								placeholder={t('common.email_adresse')}
								value={newAgencyData.email}
								onChange={handleChange}
							/>
						</div>

						<div>
							<div className={'longBar'} style={{ marginBottom: 15, marginTop: 15 }} />
						</div>
						<div style={{ display: 'flex' }}>
							<div style={{ width: 260 }}>
								<p className={'designantInput'}>{t('common.phone_number')}</p>
							</div>

							{/*<PhoneInput
                                withCountryCallingCode={true}
                                international={true}
                                value={newAgencyData.phone}
                                placeholder={t('common.phone_number')}
                                onChange={phone => setNewAgencyData({...newAgencyData, phone: phone ?? ''})}
                                style={{
                                    marginLeft: -41,
                                }}
                            />*/}
							<input
								type="text"
								id={'phone'}
								placeholder={t('common.phone_number')}
								value={newAgencyData.phone}
								onChange={handleChange}
							/>
						</div>

						<div>
							<div className={'longBar'} style={{ marginBottom: 15, marginTop: 15 }} />
						</div>

						<div style={{ display: 'flex' }}>
							<div style={{ width: 260 }}>
								<p className={'designantInput'}>{t('common.website')}</p>
							</div>

							<input
								type="text"
								id={'site'}
								placeholder={t('common.website')}
								value={newAgencyData.site}
								onChange={handleChange}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 30 }}>
							<div className={'longBar'} style={{ marginTop: 15 }} />
							<div style={{ width: 200, marginLeft: -200, display: 'flex', justifyContent: 'flex-end' }}>
								<button
									style={{ marginLeft: 0 }}
									className={'enregistrerButton'}
									onClick={async () => {
										await firestore.doc(`agencies/${agency}`).update(newAgencyData);
										setWidthEnregistre(200);
										setHeightEnregistre(170);
										setOpacityEnregistre(1);
										setTimeout(() => {
											setWidthEnregistre(0);
											setHeightEnregistre(0);
											setOpacityEnregistre(0);
										}, 1000);
									}}>
									<p>{t('common.save')}</p>
								</button>
							</div>
						</div>

						<div style={{ display: 'flex', marginTop: -25 }}>
							<p className={'information'} style={{ marginBottom: '50px', marginTop: -30 }}>
								{t('common.id')} : {agency}
							</p>

							<div style={{ marginTop: -36 }}>
								<button
									className={'copier'}
									onClick={() => {
										setIsCopied(true);
										navigator.clipboard.writeText(agency);
									}}>
									{t('common.copy')}
								</button>
							</div>
							{isCopied ? (
								<p className={'information'} style={{ marginTop: -30, marginLeft: 10 }}>
									{t('common.copied')}
								</p>
							) : null}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
