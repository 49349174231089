import React, { useContext, useState, useRef } from 'react';
import downloadIcon from '../../../../../../assets/downloadIcon.svg';
import fullBlueStar from '../../../../../../assets/fullBlueStar.svg';
import emptyStar from '../../../../../../assets/emptyStar.svg';
import trashIcon from '../../../../../../assets/trashIcon.svg';
import bigEditIcon from '../../../../../../assets/bigEditIcon.svg';
import moveIcon from '../../../../../../assets/moveIcon.svg';
import { getPathStorageFromUrl } from '../../../../../../firebase/utils';
import ModalTyping from '../../../../../../components/ModalTyping/ModalTyping';
import { auth, firestore } from '../../../../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../../firebase/paths';
import chargement from '../../../../../../assets/chargement.png';
import { useTranslation } from 'react-i18next';
import { normalizeString } from '../../../../../../useful/UsefulFunctions';
import AgencyContext from '../../../../../../contexts/agencyContext';
import { useClientsContext } from '../../../../../../contexts/clientsContext';
import ModalNameVersion from '../modaux/Modal Name Version/ModalNameVersion';
import ModalDelete from '../../../../../../components/Modal Delete/ModalDelete';

export default function ButtonsDocumentInfos({
	projectId,
	setSelectedFileIds,
	setFiles,
	path,
	setPath,
	canModify,
	isInTrash,
	selectedFiles,
	setModalMoveItem,
	setIsLoading,
	isLoading,
	selectedVersionId,
	setSelectedVersionId,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const [clients] = useClientsContext();
	const [modalDelete, setModalDelete] = useState(false);

	const selectedFile = selectedFiles?.[0];
	const selectedVersion =
		selectedFile?.versions?.length > 0
			? selectedVersionId
				? selectedFile?.versions?.find((it) => it.id === selectedVersionId)
				: selectedFile.versions.at(-1)
			: selectedFile;

	const [modalRename, setModalRename] = useState(false);

	// Download
	const handleDownload = async () => {
		try {
			const file = selectedFiles[0];
			const a = document.createElement('a');
			if (file.isFolder) {
				a.href = `https://transfer.opusoft.app/downloadFolder/${encodeURIComponent(projectId)}/${encodeURIComponent(
					file.id
				)}/${encodeURIComponent(file.name)}/${encodeURIComponent(uid)}`;
			} else {
				a.href = `https://transfer.opusoft.app/download/${encodeURIComponent(
					getPathStorageFromUrl(selectedVersion.url)
				)}/${encodeURIComponent(selectedVersion.name)}`;
			}
			a.download = selectedVersion.name;
			a.click();
		} catch (error) {
			console.error('Download failed:', error);
			alert(t('translation.failedToDownload')); // TODO: [Low] translate
		}
	};

	// Favorite
	const handleFavorite = async () => {
		setIsLoading(true);
		const newFavorite = selectedFiles.every((it) => it.isFavorite);
		setFiles((prevState) => {
			const nextState = [...prevState];
			for (const file of selectedFiles) {
				const index = nextState.findIndex((f) => f.id === file.id);
				if (index !== -1) {
					nextState[index].isFavorite = newFavorite;
				}
			}
			return nextState;
		});
		for (const file of selectedFiles) {
			await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`).update({
				isFavorite: newFavorite,
			});
		}
		setIsLoading(false);
	};

	const handleDelete = async () => {
		if (!window.confirm(t('translation.areYouSureToDelete'))) {
			return;
		}

		setIsLoading(true);

		const batchs = [];
		const promises = [];

		try {
			for (const file of selectedFiles) {
				if (path.includes(file.id)) {
					setPath(path.slice(0, path.indexOf(file.id)));
				}

				setFiles((prevState) =>
					prevState.filter((it) => it.id !== file.id && !it.path.includes(`${file.path}/${file.id}/`))
				);
				setSelectedFileIds([]);

				let batch = firestore.batch();
				let batchCount = 500;

				if (file.isFolder) {
					promises.push(
						new Promise((resolve, reject) => {
							firestore
								.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
								.where('path', '>=', `${file.path}${file.id}/`)
								.where('path', '<', `${file.path}${file.id}/\uf8ff`)
								.get()
								.then((querySnapshot) => {
									querySnapshot.forEach(async (doc) => {
										if (doc.exists) {
											batch.update(
												firestore.doc(
													`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${doc.id}`
												),
												{
													path: doc.data().path.replace(file.path, '/trash/'),
													previousPath: doc.data().path,
													deletedAt: new Date().toISOString(),
													modifiedAt: new Date().toISOString(),
													clientPermission:
														doc.data().createdBy?.userType === 'client' ||
														clients?.some(
															(item) => item.id === doc.data().createdBy?.agencyId
														),
													permissions:
														agencyId === doc.data().createdBy?.agencyId ||
														doc.data().createdBy?.userType === 'client'
															? [agencyId]
															: [agencyId, doc.data().createdBy.agencyId],
													permissionsReadOnly: [],
												}
											);
											batchCount--;
											if (batchCount === 0) {
												batchs.push(batch);
												batchCount = 500;
												batch = firestore.batch();
											}
										}
									});

									resolve();
								})
								.catch(reject);
						})
					);
				} else if (file.versions?.length > 0) {
					file.versions.map(
						(version) =>
							new Promise((resolve, reject) => {
								firestore
									.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${version.id}`)
									.update({
										path: '/trash/',
										previousPath: file.path,
										deletedAt: new Date().toISOString(),
										modifiedAt: new Date().toISOString(),
										clientPermission:
											file.createdBy?.userType === 'client' ||
											clients?.some((item) => item.id === file.createdBy?.agencyId),
										clientPermissionReadOnly: false,
										permissions:
											agencyId === file.createdBy?.agencyId ||
											file.createdBy?.userType === 'client'
												? [agencyId]
												: [agencyId, file.createdBy.agencyId],
										permissionsReadOnly: [],
									})
									.then(resolve)
									.catch(reject);
							})
					);
				}

				batch.update(firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`), {
					path: '/trash/',
					previousPath: file.path,
					deletedAt: new Date().toISOString(),
					modifiedAt: new Date().toISOString(),
					clientPermission:
						file.createdBy?.userType === 'client' ||
						clients?.some((item) => item.id === file.createdBy?.agencyId),
					clientPermissionReadOnly: false,
					permissions:
						agencyId === file.createdBy?.agencyId || file.createdBy?.userType === 'client'
							? [agencyId]
							: [agencyId, file.createdBy.agencyId],
					permissionsReadOnly: [],
				});

				batchs.push(batch);
			}

			await Promise.all(promises);

			for (const batch of batchs) {
				await batch.commit();
			}

			setIsLoading(false);
		} catch (error) {
			console.error('Error deleting files:', error);
			setIsLoading(false);
		}
	};

	const handleDeleteVersion = () => {
		if (selectedFile?.versions?.length > 1 && selectedFile?.versions?.at(-1)?.id === selectedVersion.id) {
			firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${selectedFile.id}`).update({
				lastVersionIndex: selectedFile?.versions?.length > 0 ? selectedFile?.versions?.at(-2)?.versionIndex : 0,
				modifiedAt: selectedVersion.modifiedAt || new Date().toISOString(),
				name: selectedFile?.versions?.at(-2)?.name || '',
				normalizedName: normalizeString(selectedFile?.versions?.at(-2)?.name || ''),
				notes: selectedFile?.versions?.at(-2)?.notes || '',
				seenBy: selectedFile?.versions?.at(-2)?.seenBy || [],
				size: selectedFile?.versions?.at(-2)?.size || 0,
				type: selectedFile?.versions?.at(-2)?.type || '',
				url: selectedFile?.versions?.at(-2)?.url || '',
			});
		}

		firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${selectedVersion.id}`).update({
			path: '/trash/',
			previousPath: selectedVersion.path,
			deletedAt: new Date().toISOString(),
			modifiedAt: new Date().toISOString(),
			clientPermission:
				selectedVersion.createdBy?.userType === 'client' ||
				clients?.some((item) => item.id === selectedVersion.createdBy?.agencyId),
			clientPermissionReadOnly: false,
			permissions:
				agencyId === selectedVersion.createdBy?.agencyId || selectedVersion.createdBy?.userType === 'client'
					? [agencyId]
					: [agencyId, selectedVersion.createdBy.agencyId],
			permissionsReadOnly: [],
		});

		setFiles((prevState) =>
			prevState.map((it) =>
				it.id === selectedFile.id
					? { ...it, versions: it.versions?.filter((v) => v.id !== selectedVersion.id) }
					: it
			)
		);
		setSelectedVersionId(null);
		setModalDelete(false);
	};

	// Rename
	const handleRename = () => {
		setModalRename(true);
	};

	const renameFile = async (newName, file) => {
		setFiles((prevFiles) => {
			return prevFiles.map((prevFile) => {
				if (prevFile.id === file.id) {
					return {
						...prevFile,
						name: newName.length > 0 ? newName : prevFile.name,
						normalizedName: normalizeString(newName.length > 0 ? newName : prevFile.name),
					};
				} else {
					return prevFile;
				}
			});
		});
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`).update({
			name: newName.length > 0 ? newName : file.name,
			normalizedName: normalizeString(newName.length > 0 ? newName : file.name),
		});
	};

	const renameVersion = async (newName, versionIndex) => {
		if (projectId && selectedVersion && selectedFile) {
			await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${selectedVersion.id}`).update({
				name: newName,
				normalizedName: normalizeString(newName),
				versionIndex: versionIndex,
			});

			if (
				String(versionIndex) >= String(selectedFile.lastVersionIndex) ||
				selectedVersion.versionIndex === selectedFile.lastVersionIndex
			) {
				await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${selectedFile.id}`).update({
					lastVersionIndex: versionIndex,
					modifiedAt: selectedVersion.modifiedAt,
					name: newName,
					normalizedName: normalizeString(newName),
					notes: selectedVersion.notes,
					seenBy: selectedVersion.seenBy,
					size: selectedVersion.size,
					type: selectedVersion.type,
					url: selectedVersion.url,
				});
			}
		}
	};

	// Move item
	function handleMoveItem() {
		setModalMoveItem(true);
	}

	return (
		<div className="containerButtons">
			<div>
				<div className="hover" onClick={handleDownload}>
					<img src={downloadIcon} alt="" />
				</div>

				{canModify && !isInTrash && (
					<div className="hover" onClick={handleMoveItem}>
						<img src={moveIcon} alt="" />
					</div>
				)}

				{selectedFiles.every((it) => !it.isFolder) && !isInTrash && (
					<div className="hover" onClick={handleFavorite}>
						<img src={selectedFiles.every((it) => it.isFavorite) ? fullBlueStar : emptyStar} alt="" />
					</div>
				)}

				{canModify && !isInTrash && (
					<div
						className="hover"
						onClick={() => {
							if (selectedVersion.isVersion && selectedFile?.versions?.length > 1) {
								setModalDelete(true);
							} else {
								handleDelete();
							}
						}}>
						{isLoading ? (
							<div className={'chargementContainer'} style={{ width: 20, height: 20, marginLeft: 0 }}>
								<img src={chargement} alt="" className={'chargement'} />
							</div>
						) : (
							<img src={trashIcon} alt="" />
						)}
					</div>
				)}

				{selectedFiles.length === 1 && canModify && !isInTrash && (
					<div className="hover" onClick={handleRename}>
						<img src={bigEditIcon} alt="" />
					</div>
				)}
			</div>

			{selectedFile?.versions?.length > 0 ? (
				<ModalNameVersion
					isOpen={modalRename}
					onClose={() => setModalRename(false)}
					onSubmit={renameVersion}
					defaultName={selectedVersion.name}
					defaultVersion={selectedVersion.versionIndex}
				/>
			) : (
				<ModalTyping
					modalTyping={modalRename}
					setModalTyping={setModalRename}
					func={(text) => renameFile(text, selectedFiles[0])}
					defaultValue={selectedFiles[0]?.name}
					placeholder={t('common.last_name')}
					title={t('common.rename')}
				/>
			)}

			<ModalDelete
				modal={modalDelete}
				setModal={setModalDelete}
				onDelete={handleDeleteVersion}
				onDeleteSecond={handleDelete}
				firstDelete={t('translation.deleteVersion')}
				secondDelete={t('translation.deleteAllVersions')}
			/>
		</div>
	);
}
