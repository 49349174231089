import React from 'react';
import PropTypes from 'prop-types';
import './ItemAccessibilityPhotos.css';
import { useTranslation } from 'react-i18next';

ItemAccessibilityPhotos.propTypes = {
	name: PropTypes.string.isRequired,
	isActivated: PropTypes.bool.isRequired,
	handleSimpleChoice: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	isOwner: PropTypes.bool,
};

export default function ItemAccessibilityPhotos({ name, isActivated, handleSimpleChoice, isDisabled, isOwner }) {
	const { t } = useTranslation();

	return (
		<div
			className={'itemAccessibilityPhotos transition'}
			style={{
				backgroundColor: isActivated && '#EAEEFB',
				pointerEvents: isDisabled ? 'none' : 'auto',
			}}
			onClick={handleSimpleChoice}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div style={{ width: 300 }}>
					<p className={'titleAccessibilityPhotos'} style={{ color: isActivated && '#2D56D5' }}>
						{name}
						{isOwner && ' (' + t('translation.owner') + ')'}
					</p>
				</div>

				<div className={'roundedButton'} style={{ borderColor: isActivated && '#2D56D5', marginTop: 0 }}>
					<div className={'littleCircle'} style={{ backgroundColor: isActivated && '#2D56D5' }} />
				</div>
			</div>
		</div>
	);
}
