import React, { useContext, useState } from 'react';
import crossIcon from '../../../../assets/crossIcon.png';
import { auth, firestore, functions } from '../../../../firebase/config';
import checkBleu from '../../../../assets/checkBleu.png';
import chargement from '../../../../assets/chargement.png';
import ModalPassword from '../../../../components/ModalPassword';
import ModalAddProjet from '../../ProjectComponents/ModalAddProjet';
import AgencyContext from '../../../../contexts/agencyContext';
import { generateUniqueFirestoreId } from '../../../../firebase/utils';
import O_Opus from '../../../../assets/O_Opus.png';
import floderPlus from '../../../../assets/floderPlus.svg';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import {
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	signInWithCredential,
	signOut,
} from 'firebase/auth';
import { createdByConstructor } from '../../../Fiche Projet/utils';
import AgencyDataContext from '../../../../contexts/agencyDataContext';

export default function ModalNewCollaborateur({
	isCreationCompte,
	setIsCreationCompte,
	authProjects,
	setAccounts,
	setModalFelicitationCreationCollab,
	modalFelicitationCreationCollab,
}) {
	const { t, i18n } = useTranslation();

	const agency = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);

	// Création collaborateurs

	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [nom, setNom] = useState('');
	const [prenom, setPrenom] = useState('');
	const [newCollabId, setNewCollabId] = useState();
	const [newCollabData, setNewCollabData] = useState();
	const [projectsToAdd, setProjectsToAdd] = useState([]);

	// Modaux

	const [modalPassword, setModalPassword] = useState(false);
	const [modalAddProjet, setModalAddProjet] = useState(false);

	// Choix type autorisation de compte

	const [administrateurSelected, setAdministrateurSelected] = useState(true);
	const [collaborateurSelected, setCollaborateurSelected] = useState(false);
	const [personnaliseSelected, setPersonnaliseSelected] = useState(false);

	// Choix autorisation du compte personnalisé

	const [creationProjet, setCreationProjet] = useState(false);
	const [suppressionProjet, setSuppressionProjet] = useState(false);
	const [creationCollaborateur, setCreationCollaborateur] = useState(false);
	const [suppressionCollaborateur, setSuppressionCollaborateur] = useState(false);
	const [creationClient, setcreationClient] = useState(false);
	const [suppressionClient, setSuppressionClient] = useState(false);
	const [ajoutAgencePartenaire, setAjoutAgencePartenaire] = useState(false);
	const [suppressionAgencePartenaire, setSuppressionAgencePartenaire] = useState(false);
	const [accesFacturation, setAccesFacturation] = useState(false);

	async function createCollaborator(cred) {
		setModalFelicitationCreationCollab(true);
		setLoading(true);

		const password = generateUniqueFirestoreId().slice(0, 6);

		createUserWithEmailAndPassword(auth, email, password).then(async (authUser) => {
			signOut(auth).then(() => {
				signInWithCredential(auth, cred).then(async () => {
					const uid = authUser.user.uid;
					setNewCollabId(uid);

					await firestore.doc(`accounts/${uid}`).set({
						agency,
						uid,
						firstPassword: password,
						tokens: [],
						type: 'collaborators',
					});

					const data = personnaliseSelected
						? {
								agency: agency,
								date: new Date().toISOString(),
								imgUrl: O_Opus,
								subtype: 'custom',
								uid,
								email,
								name: prenom,
								surname: nom,
								language: i18n.language,
								canCreateProject: creationProjet,
								canDeleteProject: suppressionProjet,
								canCreateCollaborator: creationCollaborateur,
								canDeleteCollaborator: suppressionCollaborateur,
								canCreateClient: creationClient,
								canDeleteClient: suppressionClient,
								canCreatePartnerAgency: ajoutAgencePartenaire,
								canDeletePartnerAgency: suppressionAgencePartenaire,
								canAccesBilling: accesFacturation,
								lastConnexion: null,
								active: false,
								job: '',
							}
						: {
								agency: agency,
								date: new Date().toISOString(),
								imgUrl: O_Opus,
								subtype: administrateurSelected
									? 'administrator'
									: collaborateurSelected
										? 'collaborator'
										: null,
								uid,
								email,
								name: nom,
								surname: prenom,
								language: i18n.language,
								canCreateProject: false,
								canDeleteProject: false,
								canCreateCollaborator: false,
								canDeleteCollaborator: false,
								canCreateClient: false,
								canDeleteClient: false,
								canCreatePartnerAgency: false,
								canDeletePartnerAgency: false,
								canAccesBilling: false,
								lastConnexion: null,
								active: false,
								job: '',
							};

					setNewCollabData(data);

					const call = httpsCallable(functions, 'addUserToAgency');
					call({
						userData: { ...data, id: uid, type: 'collaborators', agency: agency },
						agencyId: agency,
					}).then((r) => {
						setAccounts((oldState) => [data, ...oldState]);

						setNom('');
						setPrenom('');

						setLoading(false);
					});
				});
			});
		});
	}

	async function addProjects() {
		if (!loading) {
			for (const item of projectsToAdd) {
				setLoading(true);

				const call = httpsCallable(functions, 'addUserToProject');
				call({
					createdBy: createdByConstructor(newCollabId, 'collaborator', agency),
					projectId: item.id,
					parent: agencyData,
					data: newCollabData,
					senderName: newCollabData.surname + ' ' + newCollabData.name,
					projectName: item.name,
					projectImgUrl: item.imgUrl,
					withoutNotification: true,
				}).then((r) => {});
			}

			setLoading(false);
			setModalFelicitationCreationCollab(false);
			setIsCreationCompte(false);
			setEmail('');
			setProjectsToAdd([]);
		}
	}

	return (
		<div
			className={'modalCreationCompte'}
			style={{
				width: isCreationCompte ? 430 : 0,
				transform: isCreationCompte ? 'scale(1)' : 'scale(0.8)',
				height: modalFelicitationCreationCollab ? 450 : 600,
			}}>
			{modalFelicitationCreationCollab ? (
				<div className={'modalFelicitationCreationCollab'}>
					{loading ? (
						<div className={'center'}>
							<p className={'felicitation'} style={{ marginTop: 90 }}>
								{t('new_collab_account.creation_in_progress')}
							</p>
							<p
								className={'detailFelicitation'}
								style={{
									textAlign: 'center',
									lineHeight: 1.4,
								}}>
								{t('new_collab_account.preparing_collab_space')}
								<br /> {t('new_collab_account.and_send_him_an_email')}
							</p>

							<div className={'chargementContainerFinanceProgression'} style={{ marginTop: -180 }}>
								<img src={chargement} alt="" className={'chargement'} />
							</div>
						</div>
					) : (
						<>
							<img
								src={crossIcon}
								alt="crossIcon"
								className={'crossIcon'}
								onClick={() => {
									setModalFelicitationCreationCollab(false);
									setEmail('');
									setNom('');
									setPrenom('');
								}}
							/>
							<div className={'center'}>
								<p className={'felicitation'}>{t('new_partner_agency.congrats')}</p>
								<p className={'detailFelicitation'} style={{ marginBottom: 10, marginTop: 0 }}>
									{t('new_collab_account.email_send_to_this_address')}
								</p>
								<p
									className={'detailFelicitation'}
									style={{
										marginTop: 0,
										opacity: 0.5,
										textAlign: 'center',
										marginLeft: 30,
										marginRight: 30,
									}}>
									{t('new_collab_account.inf_email_sent')}
								</p>

								<div className={'emailContainer'}>
									<p className={'email'}>{email}</p>
								</div>

								<div
									style={{
										display: 'flex',
										marginRight: 0,
										width: 250,
										marginTop: 22,
										marginLeft: 8,
										backgroundColor: '#eeeeee',
										paddingLeft: 10,
										paddingRight: 10,
										borderRadius: 5,
										marginBottom: 20,
										height: 30,
									}}
									onClick={() => setModalAddProjet(true)}
									className={'hover'}>
									<img
										style={{
											marginLeft: 0,
											marginTop: 8,
											height: 14,
											marginRight: 5,
											objectFit: 'contain',
										}}
										src={floderPlus}
										alt="iconPlus"
									/>
									<p
										style={{
											marginLeft: 3,
											fontSize: 12,
											marginTop: 7,
											fontWeight: 600,
											color: '#000',
										}}>
										{t('new_collab_account.add_this_collab_to_projects')}
									</p>
								</div>

								<div style={{ marginTop: 20 }}>
									<div className={'ajouterProjet'} onClick={addProjects}>
										<p className={'textAjouterProjet'}>{t('common.finish')}</p>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			) : (
				<div className={'contentCreationCollaborateur'} style={{ opacity: isCreationCompte ? 1 : 0 }}>
					<div style={{ display: 'flex' }}>
						<div style={{ width: 400 }}>
							<p className={'titleModal'}>{t('new_collab_account.invite_a_new_collab')}</p>
							<p className={'subtitleModal'}>{t('new_collab_account.type_name_email_etc')}</p>
						</div>
						<img
							src={crossIcon}
							alt="crossIcon"
							className={'crossIcon'}
							onClick={() => setIsCreationCompte(false)}
						/>
					</div>

					<form
						onSubmit={(e) => {
							e.preventDefault();
							setLoading(true);
							fetchSignInMethodsForEmail(auth, email).then((task) => {
								if (task.length === 0) {
									setModalPassword(true);
								} else {
									window.alert(t('new_collab_account.this_user_has_an_account'));
								}
							});
							setLoading(false);
						}}>
						<div style={{ display: 'flex' }}>
							<input
								type="text"
								className={'inputNom'}
								placeholder={t('common.first_name')}
								value={prenom}
								onChange={(e) => setPrenom(e.target.value)}
								required
							/>
							<input
								type="text"
								className={'inputNom'}
								placeholder={t('common.last_name')}
								value={nom}
								onChange={(e) => setNom(e.target.value)}
								required
							/>
						</div>

						<div style={{ display: 'flex', marginTop: 15 }}>
							<input
								type="email"
								className={'inputEmail'}
								placeholder={t('common.email_address')}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>

						<div style={{ display: 'flex' }}>
							<div
								style={{
									height: 50,
								}}
								className={'cardAutor'}
								onClick={() => {
									setAdministrateurSelected(true);
									setCollaborateurSelected(false);
									setPersonnaliseSelected(false);
								}}>
								<div className={'noChoiceAutor'}>
									<img
										src={checkBleu}
										alt="choice"
										className={'choiceAutor'}
										style={{ opacity: administrateurSelected ? 1 : 0 }}
									/>
								</div>
								<p className={'titleAutor'}>{t('common.admin')}</p>
							</div>

							<div
								style={{ marginRight: 30, marginLeft: 20 }}
								className={'cardAutor'}
								onClick={() => {
									setAdministrateurSelected(false);
									setCollaborateurSelected(true);
									setPersonnaliseSelected(false);
								}}>
								<div className={'noChoiceAutor'}>
									<img
										src={checkBleu}
										alt="choice"
										className={'choiceAutor'}
										style={{ opacity: collaborateurSelected ? 1 : 0 }}
									/>
								</div>
								<p className={'titleAutor'}>{t('common.collab')}</p>
							</div>
						</div>

						<div
							className={'cardAutor'}
							style={{ flexDirection: 'column', marginTop: 20, width: 370 }}
							onClick={() => {
								setAdministrateurSelected(false);
								setCollaborateurSelected(false);
								setPersonnaliseSelected(true);
							}}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 65,
									marginLeft: -20,
									width: 350,
								}}>
								<div className={'noChoiceAutor'}>
									<img
										src={checkBleu}
										alt="choiceAutor"
										className={'choiceAutor'}
										style={{ opacity: personnaliseSelected ? 1 : 0 }}
									/>
								</div>
								<p className={'titleAutor'}>{t('billing.personalised')}</p>
							</div>

							<div className={'choixAutorPersonalise'}>
								<div
									className={creationProjet ? 'autorActive' : 'autor'}
									onClick={() => {
										setCreationProjet(!creationProjet);
									}}>
									<p className={'typeAutor'}>{t('new_collab_account.creating_project')}</p>
								</div>

								<div
									className={suppressionProjet ? 'autorActive' : 'autor'}
									onClick={() => {
										setSuppressionProjet(!suppressionProjet);
									}}>
									<p className={'typeAutor'}>{t('new_collab_account.deletion_project')}</p>
								</div>

								<div
									className={creationCollaborateur ? 'autorActive' : 'autor'}
									onClick={() => {
										if (!creationCollaborateur) {
											setAccesFacturation(true);
										}
										setCreationCollaborateur(!creationCollaborateur);
									}}>
									<p className={'typeAutor'}>{t('new_collab_account.creation_collab')}</p>
								</div>

								<div
									className={suppressionCollaborateur ? 'autorActive' : 'autor'}
									onClick={() => {
										if (!suppressionCollaborateur) {
											setAccesFacturation(true);
										}
										setSuppressionCollaborateur(!suppressionCollaborateur);
									}}>
									<p className={'typeAutor'}>{t('new_collab_account.deletion_collab')}</p>
								</div>

								<div
									className={creationClient ? 'autorActive' : 'autor'}
									onClick={() => {
										setcreationClient(!creationClient);
									}}>
									<p className={'typeAutor'}>{t('new_collab_account.client_creation')}</p>
								</div>

								<div
									className={suppressionClient ? 'autorActive' : 'autor'}
									onClick={() => {
										setSuppressionClient(!suppressionClient);
									}}>
									<p className={'typeAutor'}>{t('new_collab_account.client_deletion')}</p>
								</div>

								<div
									className={accesFacturation ? 'autorActive' : 'autor'}
									onClick={() => {
										if (accesFacturation) {
											setCreationCollaborateur(false);
											setSuppressionCollaborateur(false);
										}
										setAccesFacturation(!accesFacturation);
									}}>
									<p className={'typeAutor'}>{t('new_collab_account.billing_access')}</p>
								</div>
							</div>
						</div>
						<button
							style={{
								width: 370,
								marginTop: 30,
								marginLeft: 30,
								height: 35,
								backgroundColor: '#D2E7F8',
								color: '#4B5FB0',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 7,
								fontSize: 13,
								fontWeight: 600,
								border: 0,
							}}>
							{loading ? t('common.loading') : t('new_collab_account.send_the_invite')}
						</button>
					</form>
				</div>
			)}

			<ModalPassword
				modalPassword={modalPassword}
				setModalPassword={setModalPassword}
				toExecute={createCollaborator}
			/>

			<ModalAddProjet
				modal={modalAddProjet}
				setModal={setModalAddProjet}
				authProjects={authProjects}
				projectsToAdd={projectsToAdd}
				setProjectsToAdd={setProjectsToAdd}
			/>
		</div>
	);
}
