import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../assets/crossIcon.png';
import leftArrow from '../../../assets/leftArrow.svg';
import { useTranslation } from 'react-i18next';
import getRoleById, { createdByConstructor, Pages, rolesWithMOA } from '../../Fiche Projet/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestore, functions } from '../../../firebase/config';
import TypeContext from '../../../contexts/typeContext';
import AgencyContext from '../../../contexts/agencyContext';
import {
	AGENCY_COLLECTION,
	CLIENT_COLLECTION,
	COLLABORATOR_COLLECTION,
	PROJECT_COLLECTION,
	USER_COLLECTION,
} from '../../../firebase/paths';
import {
	getNotificationObject,
	sendNotificationToCollaborators,
	timeSince,
} from '../../Fiche Projet/Notifications/utils';
import AuthDataContext from '../../../contexts/authDataContext';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import { httpsCallable } from 'firebase/functions';
import { ProfileNotification, TextContentNotification } from '../../Fiche Projet/Notifications/NotificationComponents';
import { getFirstLetters } from '../../../useful/UsefulFunctions';

export default function ModalJoinProject({ modalJoinProject, setModalJoinProject, setModalWaitForApproval }) {
	const location = useLocation();
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const uid = auth.currentUser?.uid;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const userData = useContext(AuthDataContext);
	const agencyData = useContext(AgencyDataContext);

	const [isLoading, setIsLoading] = useState(false);
	const [projectId, setProjectId] = useState('');
	const [projectName, setProjectName] = useState('');
	const [projectImg, setProjectImg] = useState('');
	const [roleIds, setRoleIds] = useState(['']);
	const [projectMandataireId, setProjectMandataireId] = useState('');
	const [partnerIds, setPartnerIds] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);
	const [agencyNameToNotify, setAgencyNameToNotify] = useState('');

	useEffect(() => {
		if (location.state) {
			setModalJoinProject(location.state.fromJoinProject);
			setProjectId(location.state.projectId);
			setProjectName(location.state.projectName);
			setProjectImg(location.state.projectImg);
		}
	}, [location]);

	useEffect(() => {
		if (projectId) {
			firestore
				.doc(`${PROJECT_COLLECTION}/${projectId}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setProjectMandataireId(doc.data().creator);
					}
				});
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${USER_COLLECTION}`)
				.where('type', '==', 'agencies')
				.get()
				.then((query) => {
					const entreprises = [];
					query.forEach((doc) => {
						if (doc.exists) {
							entreprises.push(doc.id);
						}
					});
					setPartnerIds(entreprises);
					setDataLoading(false);
				});
		}
	}, [projectId]);

	useEffect(() => {
		if (partnerIds?.includes(agencyId)) {
			setAgencyNameToNotify(agencyData?.name);
		} else if (projectMandataireId) {
			firestore
				.doc(`${AGENCY_COLLECTION}/${projectMandataireId}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setAgencyNameToNotify(doc.data().name);
					}
				});
		}
	}, [partnerIds, projectMandataireId]);

	const handleAskJoinProject = async () => {
		if (!isLoading && !dataLoading) {
			setIsLoading(true);
			if (type === 'collaborators') {
				firestore
					.doc(`${PROJECT_COLLECTION}/${projectId}/${USER_COLLECTION}/${agencyId}`)
					.get()
					.then(async (doc) => {
						if (doc.exists) {
							firestore
								.doc(`${AGENCY_COLLECTION}/${agencyId}/${COLLABORATOR_COLLECTION}/${uid}`)
								.get()
								.then(async (doc) => {
									if (doc.exists) {
										if (doc.data().status === 'pending') {
											await addToWaitingList(agencyId);
										} else {
											const call = httpsCallable(functions, 'addUserToProject');
											call({
												createdBy: createdByConstructor(uid, type, agencyId),
												projectId,
												parent: agencyData,
												data: userData,
												senderName: userData.surname + ' ' + userData.name,
												projectName,
												projectImgUrl: projectImg,
											}).then(async (r) => {
												setIsLoading(false);
												setModalJoinProject(false);
												navigate('/fiche-projet', { state: { projectId } });
												localStorage.setItem('projectId', projectId);
												localStorage.setItem('page', Pages.FICHIERS);
												if (type === 'collaborators') {
													await firestore
														.doc(
															`agencies/${agencyId}/${type}/${uid}/projects/${projectId}`
														)
														.update({ lastClick: new Date().toISOString(), seen: true });
													await firestore
														.doc(`agencies/${agencyId}/collaborators/${uid}`)
														.update({ lastConnexion: new Date().toISOString() });
												} else {
													await firestore
														.doc(`${type}/${uid}/projects/${projectId}`)
														.update({ lastClick: new Date().toISOString(), seen: true });
												}
											});
										}
									}
								});
						} else {
							await addToWaitingList(projectMandataireId);
						}
					});
			} else {
				await addToWaitingList(projectMandataireId);
			}
		}
	};

	const addToWaitingList = async (agencyToSend) => {
		await firestore
			.doc(
				type === 'collaborators'
					? `${AGENCY_COLLECTION}/${agencyId}/${COLLABORATOR_COLLECTION}/${uid}/${PROJECT_COLLECTION}/${projectId}`
					: `${CLIENT_COLLECTION}/${uid}/${PROJECT_COLLECTION}/${projectId}`
			)
			.set({
				id: projectId,
				lastClick: null,
				active: true,
				name: projectName,
				tag: projectName,
				waitingStatus: 'pending',
				roleIds: type === 'clients' ? ['0'] : roleIds,
			});
		sendNotificationToCollaborators(
			createdByConstructor(uid, type, agencyId),
			agencyToSend,
			false,
			projectId,
			'joinProject',
			type === 'collaborators' ? { ...agencyData, roleIds } : null,
			[{ ...userData, status: 'pending' }],
			userData.name + ' ' + userData.surname,
			projectName,
			projectImg
		);
		setIsLoading(false);
		setModalJoinProject(false);
		setModalWaitForApproval(true);
	};

	const mockNotification = {
		source: 'joinProject',
		senderName: userData?.name + ' ' + userData?.surname,
		createdAt: new Date().toISOString(),
		roleIds: type === 'clients' ? ['0'] : roleIds,
		isCompany: !partnerIds?.includes(agencyId),
	};

	return (
		<Modal
			isOpen={modalJoinProject}
			className={'modalJoinProject projectToJoin'}
			overlayClassName="OverlayModal"
			onRequestClose={() => {
				setModalJoinProject(false);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalJoinProject(false);
				}}
			/>
			<h1 style={{ fontSize: 50 }}>🎉</h1>
			<p className={'titleModal'}>{t('translation.felicitationProjet')} </p>
			<p className={'subtitleModal'}>{t('translation.contributorsAreNotified')}</p>
			{!dataLoading && (!partnerIds?.includes(agencyId) || userData?.waitingStatus === 'pending') && (
				<>
					<p className={'whichRole'}>
						{t('translation.hereTheNotification', { agencyName: agencyNameToNotify })} :{' '}
					</p>
					<div className={'notificationItemBlue'}>
						<div className={'notificationItemContent'}>
							<ProfileNotification
								initiales={getFirstLetters(mockNotification.senderName)}
								entreprise={mockNotification.isCompany}
							/>
							<div className={'rightPartNotification'}>
								<TextContentNotification
									object={getNotificationObject(mockNotification)}
									initializer={mockNotification.senderName}
									role={getRoleById(t, mockNotification.roleIds[0])?.name}
									sameAgency={mockNotification.isCompany}
								/>
								<p className={'notifDate'}>{timeSince(t, mockNotification.createdAt)}</p>
								<div className={'containerButtons'}>
									<button className={'acceptButton'} disabled={true}>
										{t('translation.accept')}
									</button>
									<button className={'refuseButton'} disabled={true}>
										{t('translation.refuse')}{' '}
									</button>
								</div>
							</div>
							<div className={'pastille'} style={{ display: 'flex' }} />
						</div>
					</div>
				</>
			)}
			{!dataLoading && type === 'collaborators' && !partnerIds?.includes(agencyId) && (
				<>
					<p className={'whichRole'}>{t('translation.whatIsYourRole')}</p>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
						{roleIds.map((roleId) => (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<select
									className={'select'}
									value={roleId}
									onChange={(e) => {
										let newRoles = roleIds;
										if (e.target.value === '') {
											newRoles = [''];
										} else {
											newRoles = [e.target.value];
										}
										setRoleIds(newRoles);
									}}>
									<option value="">{t('translation.selectARole')}</option>
									{[...new Set(rolesWithMOA(t).map((role) => role.category))].map((category) => (
										<optgroup label={category} key={category}>
											{rolesWithMOA(t)
												.filter((role) => role.category === category)
												.map((role) => (
													<option key={role.id} value={role.id}>
														{role.name}
													</option>
												))}
										</optgroup>
									))}
								</select>
								<img src={leftArrow} alt="" className={'leftArrow'} />
							</div>
						))}
					</div>
				</>
			)}
			<button className={'containerButton'} disabled={isLoading || dataLoading} onClick={handleAskJoinProject}>
				<div className={'blueButton'}>{isLoading ? t('common.loading') : t('translation.joinTheProject')}</div>
			</button>
		</Modal>
	);
}
