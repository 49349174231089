import React, { useEffect, useRef } from 'react';
import './styles.css';

export default function ContextMenu({ x, y, onClose, options, check }) {
	const menuRef = useRef();

	const handleClick = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	const adjustPosition = () => {
		const menu = menuRef.current;
		if (menu) {
			const { innerWidth, innerHeight } = window;
			const rect = menu.getBoundingClientRect();

			let newX = x;
			let newY = y;

			if (rect.right > innerWidth) {
				newX = innerWidth - rect.width;
			}

			if (rect.bottom > innerHeight) {
				newY = innerHeight - rect.height;
			}

			if (newX !== x || newY !== y) {
				menu.style.top = `${newY}px`;
				menu.style.left = `${newX}px`;
			}
		}
	};

	useEffect(() => {
		adjustPosition();
	}, [x, y]);

	return (
		<div className={'context-menu'} ref={menuRef} style={{ top: `${y}px`, left: `${x}px` }}>
			<ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
				{options.map((option, index) => (
					<li
						key={index}
						onClick={() => {
							if (!check) {
								onClose();
							}
							option.action();
						}}
						className={'context-menu-item'}
						style={{ color: option.color ?? 'black' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{check && <img src={option.icon} alt="" className={'itemIcon'} />}
							{option.label}
						</div>

						{check && (
							<input
								type="checkbox"
								id="myCheckbox"
								name="myCheckbox"
								checked={option.state}
								style={{ marginLeft: 15 }}
							/>
						)}
					</li>
				))}
			</ul>
		</div>
	);
}
