import React, { useContext, useEffect, useRef, useState } from 'react';
import './_containerFolder.scss';
import bluePlus from '../../../../../../assets/bluePlus.svg';
import favorisFolder from '../../../../../../assets/favorisFolder.png';
import recentFolder from '../../../../../../assets/recentFolder.png';
import trashIcon from '../../../../../../assets/trashIcon.svg';
import whiteTrashIcon from '../../../../../../assets/whiteTrashIcon.svg';
import blurFolders from '../../../../../../assets/blurFolders.png';
import whiteFolderPlus from '../../../../../../assets/whiteFolderPlus.svg';
import whiteFilePlus from '../../../../../../assets/whiteFilePlus.svg';
import DocumentItem from '../Document Item/DocumentItem';
import { generateUniqueFirestoreId, uploadOnStorage } from '../../../../../../firebase/utils';
import { auth, firestore } from '../../../../../../firebase/config';
import TypeContext from '../../../../../../contexts/typeContext';
import AgencyContext from '../../../../../../contexts/agencyContext';
import UploadContext from '../../../../../../contexts/uploadContext';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../../firebase/paths';
import ModalTyping from '../../../../../../components/ModalTyping/ModalTyping';
import DropZoneFile from './DropZoneFile';
import chargement from '../../../../../../assets/chargement.png';
import rightIconWhite from '../../../../../../assets/rightIconWhite.svg';
import rightIcon from '../../../../../../assets/rightIcon.svg';
import { favoritesId, optionFolders, recentsId, trashId } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { normalizeString } from '../../../../../../useful/UsefulFunctions';
import { useClientsContext } from '../../../../../../contexts/clientsContext';
import { useProjectContext } from '../../../../../../contexts/projectContext';
import mixpanel from 'mixpanel-browser';
import AuthDataContext from 'src/contexts/authDataContext';

// Add this class before the component
class PriorityQueue {
	constructor() {
		this.values = [];
	}

	enqueue(file, priority) {
		this.values.push({ file, priority });
		this.sort();
	}

	dequeue() {
		if (this.values.length > 0) {
			return this.values.shift();
		}
	}

	sort() {
		this.values.sort((a, b) => a.priority - b.priority);
	}
}

export default function ContainerFolder({
	index,
	projectId,
	isFirst,
	allFiles,
	files,
	setFiles,
	actualPath,
	allPath,
	selectedFileIds,
	toggleFileSelection,
	modalPreview,
	setModalPreview,
	setPreviewFile,
	modalMoveItem,
	diminutifs,
	selectedPart,
	setUploadedFileIds,
	setModalSetAccessDuringImport,
	isModeLecture,
}) {
	const uid = auth.currentUser?.uid;
	const { t } = useTranslation();

	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [_, setUploadProgress] = useContext(UploadContext);
	const [clients] = useClientsContext();
	const [project] = useProjectContext();
	const authData = useContext(AuthDataContext);

	// Import
	const [dropZoneIsActive, setDropZoneIsActive] = useState(false);
	const [optionsImports, setOptionsImports] = useState(false);

	// Is not editable folders
	const isFavorite = !!allPath.find((it) => it === favoritesId);
	const isRecent = !!allPath.find((it) => it === recentsId);

	// Modal
	const [modalNewFolder, setModalNewFolder] = useState(false);

	// Loading
	const [isLoading, setIsLoading] = useState(true);

	const isAdmin = authData?.subtype === 'administrator';
	const currentFolder = allFiles.find((it) => it.id === allPath[allPath.length - 1]);
	const canAdd =
		currentFolder?.createdBy?.agencyId === agencyId ||
		(type === 'clients' && currentFolder?.createdBy?.userType === 'client') ||
		project?.creator === agencyId ||
		((type === 'clients' || clients?.map((item) => item.id).includes(agencyId)) &&
			!currentFolder?.clientPermissionReadOnly) ||
		(type === 'collaborators' &&
			!clients?.map((item) => item.id).includes(agencyId) &&
			!currentFolder?.permissionsReadOnly?.includes(agencyId));

	// Fetch files
	useEffect(() => {
		if (projectId && actualPath && uid && type && (type === 'clients' || agencyId) && clients) {
			setIsLoading(true);
			let ref;
			if (actualPath === `/${recentsId}/`) {
				if (type === 'collaborators' && !clients?.map((item) => item.id).includes(agencyId)) {
					ref = firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('isFolder', '==', false)
						.where('permissions', 'array-contains-any', [agencyId, uid])
						.where('deletedAt', '==', null)
						.orderBy('createdAt', 'desc')
						.limit(50);
				} else {
					ref = firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('isFolder', '==', false)
						.where('clientPermission', '==', true)
						.where('deletedAt', '==', null)
						.orderBy('createdAt', 'desc')
						.limit(50);
				}
			} else if (actualPath === `/${favoritesId}/`) {
				if (type === 'collaborators' && !clients?.map((item) => item.id).includes(agencyId)) {
					ref = firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('isFavorite', '==', true)
						.where('permissions', 'array-contains-any', [agencyId, uid])
						.where('deletedAt', '==', null)
						.orderBy('normalizedName', 'asc');
				} else {
					ref = firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('isFavorite', '==', true)
						.where('clientPermission', '==', true)
						.where('deletedAt', '==', null)
						.orderBy('normalizedName', 'asc');
				}
			} else if (type === 'collaborators' && !clients?.map((item) => item.id).includes(agencyId)) {
				ref = firestore
					.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
					.where('path', '==', actualPath)
					.where('permissions', 'array-contains-any', [agencyId, uid])
					.orderBy('normalizedName', 'asc');
			} else {
				ref = firestore
					.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
					.where('path', '==', actualPath)
					.where('clientPermission', '==', true)
					.orderBy('normalizedName', 'asc');
			}
			const listener = ref.onSnapshot((querySnapshot) => {
				if (querySnapshot && !querySnapshot.empty) {
					const items = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists) {
							const data = { ...doc.data(), id: doc.id, path: actualPath };
							if (data.isVersion) {
								const index = items.findIndex((item) => item.id === data.parentId);
								if (index !== -1) {
									items[index].versions = [...items[index].versions, data].sort((a, b) =>
										String(a.versionIndex) < String(b.versionIndex) ? -1 : 1
									);
								} else {
									items.push({
										id: data.parentId,
										path: actualPath,
										versions: [data],
									});
								}
							} else {
								const index = items.findIndex((item) => item.id === data.id);
								if (index !== -1) {
									const { versions, ...parentData } = items[index];
									items[index] = {
										...data,
										versions,
									};
								} else {
									items.push({
										...data,
										versions: [],
									});
								}
							}
						}
					});

					setIsLoading(false);

					setFiles((prevState) => [
						...prevState.filter((it) => it.path !== actualPath),
						...optionFolders,
						...items.filter((it) => isAdmin || !it.adminPermissions?.includes(agencyId)),
					]);
				} else {
					setIsLoading(false);
					setFiles((prevState) => [...prevState.filter((it) => it.path !== actualPath), ...optionFolders]);
				}
			});
			return () => listener();
		}
	}, [projectId, type, agencyId, uid, actualPath, clients, isAdmin]);

	// Create folder
	function createFolder(text, path, isImport, batch, folderId) {
		let id = generateUniqueFirestoreId();
		if (isImport) {
			id = folderId;
		}

		const data = {
			adminPermissions: actualPath === '/' ? [] : (currentFolder?.adminPermissions ?? []),
			clientPermission: type === 'clients' || clients?.map((item) => item.id).includes(agencyId),
			createdAt: new Date().toISOString(),
			createdBy: {
				userId: uid,
				userType: type === 'clients' ? 'client' : 'collaborator',
				agencyId: type === 'clients' ? uid : agencyId,
			},
			deletedAt: null,
			id,
			isFavorite: false,
			isFolder: true,
			modifiedAt: new Date().toISOString(),
			name: text.length > 0 ? text : t('translation.newFolder'),
			normalizedName: normalizeString(text.length > 0 ? text : t('translation.newFolder')),
			path,
			permissions:
				type === 'clients' || clients?.map((item) => item.id).includes(agencyId)
					? [project.creator]
					: type === 'collaborators'
						? [agencyId]
						: [],
			previousPath: null,
			projectId,
			seenBy: [{ userId: uid, seenAt: new Date().toISOString() }],
			size: null,
			type: null,
			url: null,
		};

		setFiles((prevState) => [{ ...data, isLoading: true }, ...prevState]);

		if (isImport) {
			setUploadedFileIds((prevState) => [...prevState, id]);
		}

		if (isImport) {
			batch.set(firestore.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`).doc(id), {
				...data,
				url: null,
			});
		} else {
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
				.doc(id)
				.set(data)
				.then(() => {
					setFiles((prevState) => {
						const index = prevState.findIndex((it) => it.id === id);
						const newState = [...prevState];
						newState[index] = { ...data, isLoading: false };
						return newState;
					});
				})
				.catch((error) => {
					console.error('Error adding document: ', error);
				});
		}
	}

	// Upload
	const handleUpload = async (e) => {
		if (e.target && e.target.files) {
			if (type === 'collaborators' && !clients?.map((item) => item.id).includes(agencyId)) {
				setModalSetAccessDuringImport(true);
			}
			if (e.target.files[0].webkitRelativePath) {
				await uploadFolders(e.target.files, actualPath);
			} else {
				await uploadFiles(e.target.files, actualPath);
			}
		}
	};

	// Upload folder
	const uploadFolders = async (acceptedFiles, actualPath, isDropZone = false) => {
		try {
			// Filter out zero-size files
			const validFiles = Array.from(acceptedFiles).filter((file) => file.size > 0);

			if (validFiles.length === 0) {
				console.warn('No valid files to upload (all files were empty)');
				return;
			}

			mixpanel.track('Import Folders', {
				'Project ID': projectId,
				Page: 'files',
			});

			const MAX_CONCURRENT_UPLOADS = 10;
			const CHUNK_SIZE = 50;
			const paths = [];
			const pathsIds = [];
			const files = [];
			const filePaths = [];
			const foldersToCreate = [];
			const pq = new PriorityQueue();

			// Prepare folder structure
			for (const file of validFiles) {
				const stringPath = isDropZone ? file.path.split('/') : file.webkitRelativePath.split('/');
				if (isDropZone) {
					stringPath.shift();
				}
				stringPath.pop();
				paths.push(stringPath);
				const fileId = generateUniqueFirestoreId();
				files.push({ file, id: fileId });
				pq.enqueue({ file, id: fileId }, file.size);
				setFiles((prevState) => [{ file, id: fileId, isLoading: true }, ...prevState]);
			}

			// Initialize upload progress
			setUploadProgress((prevState) => {
				const newState = { ...prevState };
				files.forEach((item) => {
					const file = item.file;
					newState[item.id] = {
						name: file.name,
						type: file.type,
						projectId,
						containerName: paths[paths.length - 1][paths[paths.length - 1].length - 1],
						bytesTransferred: 0,
						totalBytes: file.size,
					};
				});
				return newState;
			});

			// Create folder structure (same as before)
			for (let i = 0; i < paths.length; i++) {
				const currentPath = paths[i];
				const pathIds = [];
				for (let j = 0; j < currentPath.length; j++) {
					let needCreation = true;
					for (let k = 0; k < i + 1; k++) {
						const previousPath = paths[k];
						if (
							k !== i &&
							previousPath.length > j &&
							currentPath.slice(0, j + 1).every((value, index) => value === previousPath[index])
						) {
							needCreation = false;
							pathIds.push(pathsIds[k][j]);
							break;
						}
					}
					if (needCreation) {
						let path = actualPath;
						for (let l = 0; l < j; l++) {
							path = path + pathIds[l] + '/';
						}
						const id = generateUniqueFirestoreId();
						foldersToCreate.push({ name: currentPath[j], path, id });
						pathIds.push(id);
					}
				}
				pathsIds.push(pathIds);
				let path = actualPath;
				for (let l = 0; l < currentPath.length; l++) {
					path = path + pathIds[l] + '/';
				}
				filePaths.push(path);
			}

			// Create folders in batches
			const foldersByBatch = [];
			for (let i = 0; i < foldersToCreate.length; i += 500) {
				foldersByBatch.push(foldersToCreate.slice(i, i + 500));
			}

			// Create folders with retries
			for (const batch of foldersByBatch) {
				let retries = 3;
				while (retries > 0) {
					try {
						const dbBatch = firestore.batch();
						batch.forEach((folder) => {
							createFolder(folder.name, folder.path, true, dbBatch, folder.id);
						});
						await dbBatch.commit();
						setFiles((prevFiles) => {
							return prevFiles.map((file) => {
								if (batch.some((folder) => folder.id === file.id)) {
									return { ...file, isLoading: false };
								}
								return file;
							});
						});
						break;
					} catch (error) {
						retries--;
						console.error(`Folder creation batch failed, ${retries} retries left:`, error);
						if (retries === 0) throw error;
						await new Promise((resolve) => setTimeout(resolve, 1000 * (4 - retries)));
					}
				}
				// Pause entre chaque lot de dossiers
				await new Promise((resolve) => setTimeout(resolve, 500));
			}

			// Upload files using priority queue and chunks
			const running = new Set();
			const maxConcurrentChunks = 2; // Limite le nombre de chunks simultanés

			while (pq.values.length > 0) {
				const chunk = [];
				// Récupérer un lot de fichiers à uploader
				for (let i = 0; i < CHUNK_SIZE && pq.values.length > 0; i++) {
					const { file: fileData } = pq.dequeue();
					chunk.push(fileData);
				}

				// Traiter chaque fichier dans le lot
				for (const fileData of chunk) {
					while (running.size >= MAX_CONCURRENT_UPLOADS) {
						await Promise.race([...running]).catch((error) =>
							console.error('Upload failed in race:', error)
						);
					}

					const fileIndex = files.findIndex((f) => f.id === fileData.id);
					const promise = uploadFile(fileData.file, filePaths[fileIndex], fileData.id)
						.catch((error) => {
							console.error(`Failed to upload file in folder: ${fileData.file.name}`, error);
						})
						.finally(() => running.delete(promise));

					running.add(promise);
				}

				// Lancer un nouveau chunk si le nombre de chunks en cours est inférieur à la limite
				if (running.size < maxConcurrentChunks) {
					// Attendre que tous les uploads du lot soient terminés
					if (running.size > 0) {
						await Promise.allSettled([...running]);
					}
				}

				// Pause entre les lots pour éviter la surcharge
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		} catch (error) {
			console.error('Folder upload error:', error);
			throw error;
		}
	};

	// Upload files
	const uploadFiles = async (files, actualPath) => {
		// Filter out zero-size files
		const validFiles = Array.from(files).filter((file) => file.size > 0);

		if (validFiles.length === 0) {
			console.warn('No valid files to upload (all files were empty)');
			return;
		}

		const MAX_CONCURRENT_UPLOADS = 10;
		const CHUNK_SIZE = 50;
		const pq = new PriorityQueue();
		const running = new Set();
		const maxConcurrentChunks = 2; // Limite le nombre de chunks simultanés

		// Ajouter les fichiers à la queue avec priorité
		for (const file of validFiles) {
			pq.enqueue(file, file.size);
		}

		// Traiter les fichiers par lots
		while (pq.values.length > 0) {
			const chunk = [];
			// Récupérer un lot de fichiers à uploader
			for (let i = 0; i < CHUNK_SIZE && pq.values.length > 0; i++) {
				const { file } = pq.dequeue();
				chunk.push(file);
			}

			// Traiter chaque fichier dans le lot
			for (const file of chunk) {
				while (running.size >= MAX_CONCURRENT_UPLOADS) {
					await Promise.race([...running]).catch(console.error);
				}

				const promise = uploadFile(file, actualPath)
					.catch((error) => {
						console.error(`Failed to upload ${file.name}:`, error);
					})
					.finally(() => running.delete(promise));

				running.add(promise);
			}

			// Lancer un nouveau chunk si le nombre de chunks en cours est inférieur à la limite
			if (running.size < maxConcurrentChunks) {
				// Attendre que tous les uploads du lot soient terminés
				if (running.size > 0) {
					await Promise.allSettled([...running]);
				}
			}

			// Petite pause entre les lots pour éviter la surcharge
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}

		mixpanel.track('Import Files', {
			'Project ID': projectId,
			'File Count': files.length,
			Page: 'files',
		});
	};

	const uploadFile = async (file, path, fileId) => {
		let id = fileId || generateUniqueFirestoreId();

		try {
			const data = {
				adminPermissions: actualPath === '/' ? [] : (currentFolder?.adminPermissions ?? []),
				clientPermission: type === 'clients' || clients?.map((item) => item.id).includes(agencyId),
				createdAt: new Date().toISOString(),
				createdBy: {
					userId: uid,
					userType: type === 'clients' ? 'client' : 'collaborator',
					agencyId: type === 'clients' ? uid : agencyId,
				},
				deletedAt: null,
				id,
				isFavorite: false,
				isFolder: false,
				modifiedAt: new Date().toISOString(),
				name: file.name,
				normalizedName: normalizeString(file.name),
				path: path,
				permissions:
					type === 'clients' || clients?.map((item) => item.id).includes(agencyId)
						? [project.creator]
						: type === 'collaborators'
							? [agencyId]
							: [],
				previousPath: null,
				projectId,
				seenBy: [{ userId: uid, seenAt: new Date().toISOString() }],
				size: file.size,
				type: file.type,
				url: null,
			};

			setFiles((prevState) => [{ ...data, isLoading: true }, ...prevState.filter((it) => it.id !== id)]);
			setUploadedFileIds((prevState) => [...prevState, id]);

			const url = await uploadOnStorage(
				id,
				file,
				data,
				`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`,
				setUploadProgress,
				actualPath.length > 1
					? allFiles.find((it) => it.id === actualPath.split('/')[actualPath.split('/').length - 2])?.name
					: t('common.files')
			);

			if (!url) throw new Error(`Failed to upload ${file.name}`);

			await firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
				.doc(id)
				.set({ ...data, url });

			setFiles((prevState) => {
				const index = prevState.findIndex((it) => it.id === id);
				if (index === -1) return prevState;
				const newState = [...prevState];
				newState[index] = { ...data, url, isLoading: false };
				return newState;
			});

			return id;
		} catch (error) {
			console.error('Upload error:', error);
			setFiles((prevState) => prevState.filter((f) => f.id !== id));
			setUploadedFileIds((prevState) => prevState.filter((fid) => fid !== id));
			setUploadProgress((prevState) => {
				const newState = { ...prevState };
				delete newState[id];
				return newState;
			});
			throw error;
		}
	};

	const onDrag = (e) => {
		e.preventDefault();

		const { clientX, clientY } = e;
		const targetRect = e.currentTarget.getBoundingClientRect();

		if (
			clientX >= targetRect.left &&
			clientX <= targetRect.right &&
			clientY >= targetRect.top &&
			clientY <= targetRect.bottom
		) {
			setDropZoneIsActive(true);
		} else {
			setDropZoneIsActive(false);
		}
	};

	const handleDrop = async (acceptedFiles, actualPath) => {
		setDropZoneIsActive(false);
		if (type === 'collaborators' && !clients?.map((item) => item.id).includes(agencyId)) {
			setModalSetAccessDuringImport(true);
		}
		if (acceptedFiles[0].path && acceptedFiles[0].path.includes('/')) {
			await uploadFolders(acceptedFiles, actualPath, true);
		} else {
			await uploadFiles(acceptedFiles, actualPath);
		}
	};

	const containerRef = useRef(null);

	const onMouseDown = (e) => {
		// Commence à suivre le mouvement de la souris
		e.preventDefault();
		const startX = e.clientX;
		const startWidth = containerRef.current.offsetWidth;

		const onMouseMove = (mouseMoveEvent) => {
			// Calcule la nouvelle largeur
			const newWidth = startWidth + mouseMoveEvent.clientX - startX;
			containerRef.current.style.width = `${newWidth}px`;
		};

		const onMouseUp = () => {
			// Arrête de suivre le mouvement de la souris
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		};

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
	};

	return (
		<div className={'containerContainerFolder'} onDragOver={onDrag} onDragLeave={onDrag}>
			{!modalPreview &&
				!modalMoveItem &&
				!isModeLecture &&
				actualPath !== `/${trashId}/` &&
				actualPath !== `/${favoritesId}/` &&
				actualPath !== `/${recentsId}/` &&
				(actualPath === '/' || canAdd) && (
					<DropZoneFile
						isDragging={dropZoneIsActive}
						setIsDragging={setDropZoneIsActive}
						handleDrop={handleDrop}
						actualPath={actualPath}
					/>
				)}
			<div
				className="containerFolder"
				ref={containerRef}
				style={{
					width: '400px',
					minWidth: 400,
					height: isModeLecture ? 'calc(100vh - 135px)' : 'calc(100vh - 190px)',
				}}>
				<div className="resizeHandle" onMouseDown={onMouseDown}>
					<div className={'dots'} />
					<div className={'dots'} />
					<div className={'dots'} />
				</div>
				{isFirst && (
					<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
						<div className={'recentFavorisAccess'}>
							<div
								className={!!allPath.find((it) => it === favoritesId) ? 'accessActive' : 'access'}
								onClick={(e) => {
									toggleFileSelection(optionFolders[0], false, false);
								}}>
								<img src={favorisFolder} alt="" className={'folderIcon'} />
								<p className={'titleAccess'}>{t('common.favorite')}</p>
							</div>
							<div
								className={!!allPath.find((it) => it === 'recents') ? 'accessActive' : 'access'}
								onClick={(e) => {
									toggleFileSelection(optionFolders[1], false, false);
								}}>
								<img src={recentFolder} alt="" className={'folderIcon'} />
								<p className={'titleAccess'}>{t('common.recent')}</p>
							</div>
						</div>
						<div className={'horizontalBar'} />
					</div>
				)}

				<div className={'documentListItem'}>
					{!isLoading && files ? (
						(!isFirst && files.length > 0) ||
						(isFirst && files.filter((it) => !it.isOptionFolder)?.length > 0) ? (
							files.map(
								(item, index) =>
									(item.name || item.type) &&
									!item.isOptionFolder && (
										<React.Fragment key={item.id}>
											{index !== 0 && <div className={'barreGrise'}></div>}

											<DocumentItem
												index={index}
												isSelected={!!selectedFileIds?.find((it) => it === item.id)}
												isInPath={!!allPath.find((it) => it === item.id)}
												file={item}
												toggleFileSelection={toggleFileSelection}
												setModalPreview={setModalPreview}
												setPreviewFile={setPreviewFile}
												diminutifs={diminutifs}
												projectId={projectId}
												selectedPart={selectedPart}
											/>
										</React.Fragment>
									)
							)
						) : isFirst && files.length < 4 ? (
							<div className={'placeholderFirst'}>
								<div className={'textPlaceholderFirst'} style={{ zIndex: 1 }}>
									<p>
										{t('translation.placeholderFiles1')}
										<br /> <br />
										{t('translation.placeholderFiles2')}
										<br /> <br />
										<div className={'bar'} />
										{t('translation.placeholderFiles3')}
									</p>
								</div>

								<img src={blurFolders} alt="" />
							</div>
						) : (
							<div className={'placeholder'}>
								<p>
									{isFavorite
										? t('translation.noFileAddedToFavorite')
										: isRecent
											? t('translation.noRecentFile')
											: selectedFileIds[0] === trashId
												? t('translation.noRecentlyDeletedFile')
												: t('translation.emptyFolder')}
								</p>
								<p>
									{isFavorite
										? t('translation.markYouFiles')
										: isRecent
											? t('translation.addYourDocsToSeeThemHere')
											: selectedFileIds[0] === trashId
												? t('translation.infoRecentlyDeletedFiles')
												: t('translation.classicPlaceholderFiles')}
								</p>
							</div>
						)
					) : (
						<div className={'chargementContainer'} style={{ width: 400, height: 150 }}>
							<img src={chargement} alt="" className={'chargement'} />
						</div>
					)}

					{!isLoading && files
						? isFirst && (
								<div
									className={
										selectedFileIds[0] === trashId
											? 'accessRecentDeletionSelected'
											: 'accessRecentDeletion'
									}
									onClick={() => toggleFileSelection(optionFolders[2], false, false)}>
									<div className={'bar'} />
									<div className={'access'}>
										<div className={'leftPart'}>
											<img
												src={selectedFileIds[0] === trashId ? whiteTrashIcon : trashIcon}
												alt=""
												className={'trashIcon'}
											/>
											<p className={'text'}>{t('translation.recentlyDeleted')}</p>
										</div>

										<div className={'rightPart'}>
											<img
												src={selectedFileIds[0] === trashId ? rightIconWhite : rightIcon}
												alt=""
												className={'rightIcon'}
											/>
										</div>
									</div>
								</div>
							)
						: null}

					<div className={'comble'}></div>
				</div>
			</div>

			{!modalPreview &&
				!modalMoveItem &&
				!isModeLecture &&
				actualPath !== `/${trashId}/` &&
				actualPath !== `/${favoritesId}/` &&
				actualPath !== `/${recentsId}/` &&
				(actualPath === '/' || canAdd) && (
					<div
						className={'containerPlusIcon'}
						style={{
							zIndex: 1,
							justifyContent: optionsImports ? 'center' : null,
						}}
						onClick={() => setOptionsImports(true)}>
						<img
							src={bluePlus}
							alt=""
							className={'plusIcon'}
							style={{
								marginTop: 10,
								marginBottom: 0,
								transform: 'scale(0)',
							}}
						/>

						<div className={'containerButtons'}>
							<div className={'buttonAddDocuments'} onClick={() => setModalNewFolder(true)}>
								<img src={bluePlus} alt="" className={'plus'} />
								{t('translation.createFolder')}
							</div>

							<div
								style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
								className={'containerImportElements'}>
								<div className={'filesOrFoldersContainer'} style={{ zIndex: 1 }}>
									<label htmlFor={`inputOnlyFiles${index}`} className={'buttonFilesOrFolders'}>
										<img src={whiteFilePlus} alt="" className={'whiteFilePlus'} />
										{t('translation.files')}
									</label>
									<label htmlFor={`inputFiles${index}`} className={'buttonFilesOrFolders'}>
										<img src={whiteFolderPlus} alt="" className={'whiteFolderPlus'} />
										{t('translation.folders')}
									</label>
								</div>

								<div className={'whiteSquare'} />

								<div className={'buttonAddDocuments'} style={{ zIndex: 2, minWidth: 145 }}>
									<img src={bluePlus} alt="" className={'plus'} />
									{t('translation.importElems')}
								</div>
							</div>

							<input
								type={'file'}
								id={`inputFiles${index}`}
								className={'input-hidden'}
								multiple={true}
								directory={''}
								webkitdirectory={''}
								onChange={handleUpload}
							/>
							<input
								type={'file'}
								id={`inputOnlyFiles${index}`}
								className={'input-hidden'}
								multiple={true}
								onChange={handleUpload}
							/>
						</div>
					</div>
				)}
			<ModalTyping
				modalTyping={modalNewFolder}
				setModalTyping={setModalNewFolder}
				title={t('translation.newFolder')}
				placeholder={t('translation.newFolder')}
				func={(text) => {
					createFolder(text, actualPath);
					mixpanel.track('Folder Created', {
						'Project ID': projectId,
						Page: 'files',
					});
				}}
			/>
		</div>
	);
}
