import React, { useCallback, useRef, useState, useEffect } from 'react';
import InputConv from './InputConv';
import ContainerMessages from './ContainerMessages';
import WidgetInfoConv from './InfoConv/WidgetInfoConv';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { resizeImage } from 'src/useful/image';

export default function ConvContainer({ conversations, selectedConvId }) {
	const { t } = useTranslation();

	const [messages, setMessages] = useState([]);
	const [responseMessage, setResponseMessage] = useState(null);
	const [temporaryFiles, setTemporaryFiles] = useState([]);
	const [sendMessageProgress, setSendMessageProgress] = useState(0);

	const isSending = useRef(false);
	const inputRef = useRef();
	const progressBarFillRef = useRef();

	const conversation = conversations.find((conv) => conv.id === selectedConvId);

	const onDrop = useCallback(async (acceptedFiles) => {
		const processedFiles = await Promise.all(
			acceptedFiles.map(async (file) => (file.type.includes('image') ? await resizeImage(file) : file))
		);
		setTemporaryFiles((prevState) => [...prevState, ...processedFiles]);
	}, []);

	const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
		onDrop,
		noClick: true,
	});

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (isSending.current && !rootRef.current?.contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				alert(t('translation.some_messages_are_sending'));
				return false;
			}
		};

		document.addEventListener('click', handleClickOutside, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		setSendMessageProgress(0);
	}, [selectedConvId]);

	useEffect(() => {
		if (sendMessageProgress === 100) {
			const timer = setTimeout(() => {
				setSendMessageProgress((prevState) => {
					if (prevState === 100) {
						progressBarFillRef.current.style.transition = 'none';
						return 0;
					}
					return prevState;
				});
			}, 2000);

			return () => clearTimeout(timer);
		} else {
			progressBarFillRef.current.style.transition = 'width 1s ease-in-out';
		}
	}, [sendMessageProgress]);

	return (
		<div className={'convContainer'} {...getRootProps()}>
			<div className="progress-bar">
				<div
					ref={progressBarFillRef}
					className={`progress-bar-fill`}
					style={{
						width: `${sendMessageProgress}%`,
					}}
				/>
			</div>

			<ContainerMessages
				conversations={conversations}
				conversationId={selectedConvId}
				setResponseMessage={setResponseMessage}
				messages={messages}
				setMessages={setMessages}
			/>
			{conversation && (
				<InputConv
					inputRef={inputRef}
					conversation={conversation}
					responseMessage={responseMessage}
					setResponseMessage={setResponseMessage}
					setMessages={setMessages}
					contacts={conversations}
					temporaryFiles={temporaryFiles}
					setTemporaryFiles={setTemporaryFiles}
					setSendMessageProgress={setSendMessageProgress}
					isSending={isSending}
				/>
			)}
			{conversation && <WidgetInfoConv data={conversation} inputRef={inputRef} conversations={conversations} />}

			<input {...getInputProps()} style={{ display: 'none' }} />
			{isDragActive && (
				<div className="messaging-dropzone-overlay">
					<p>{t('translation.dropFilesHere')}</p>
				</div>
			)}
		</div>
	);
}
