import React, { useContext, useEffect, useRef, useState } from 'react';
import searchIcon from '../../../assets/searchIcon.png';
import { transformString } from '../../../useful/UsefulFunctions';
import gerySearchIcon from '../../../assets/gerySearchIcon.png';
import placeholderVisit1 from '../../../assets/placeholderVisit1.png';
import ModalTyping from '../../../components/ModalTyping/ModalTyping';
import { auth, firestore, storage } from '../../../firebase/config';
import { generateUniqueFirestoreId, getPathStorageFromUrl } from '../../../firebase/utils';
import plusSquare from '../../../assets/plusSquare.svg';
import rightIcon from '../../../assets/rightIcon.svg';
import replacePictures from '../../../assets/replacePictures.svg';
import AgencyContext from '../../../contexts/agencyContext';
import PhotoAppIcon from '../../../assets/PhotoAppIcon.png';
import { useTranslation } from 'react-i18next';
import AlbumCard from './components/Album Card/AlbumCard';
import './_photos.scss';
import VisitCard from './components/VisitCard';
import { MEDIA_COLLECTION, PROJECT_COLLECTION } from '../../../firebase/paths';
import AuthDataContext from '../../../contexts/authDataContext';
import { useProjectContext } from '../../../contexts/projectContext';
import ModalReplaceTemplate from '../components/ModalReplaceTemplate';
import mixpanel from 'mixpanel-browser';
import doubleArrows from '../../../assets/doubleArrows.svg';
import GreyButton from '../../../components/Grey Button/GreyButton';
import { useClientsContext } from 'src/contexts/clientsContext';
import TypeContext from 'src/contexts/typeContext';

export default function AlbumsList({
	projectId,
	projectImgUrl,
	albums,
	setAlbums,
	visits,
	setVisits,
	research,
	setResearch,
	handleSelectAlbum,
	matterportActive,
	isModeLecture,
}) {
	const { t } = useTranslation();

	const [modalReplaceTemplate, setModalReplaceTemplate] = useState(false);

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agency = useContext(AgencyContext);
	const currentUser = useContext(AuthDataContext);
	const [currentProject] = useProjectContext();
	const [clients] = useClientsContext();

	const [modalNewAlbum, setModalNewAlbum] = useState(false);
	const [modalNewVisit, setModalNewVisit] = useState(false);
	const [selectedPartnerId, setSelectedPartnerId] = useState(agency);

	const globalContainerRef = useRef(null);
	const [globalScrollPosition, setGlobalScrollPosition] = useState(0);

	const containerRef = useRef(null);
	const [scrollPosition, setScrollPosition] = useState(0);

	const [isReorganizeMode, setIsReorganizeMode] = useState(false);
	const [slideDirections, setSlideDirections] = useState([]);
	const [endAnimationAlbumId, setEndAnimationAlbumId] = useState('');

	const handleScroll = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({
				left: containerRef.current.scrollLeft + 900, // Ajustez cette valeur selon le besoin
				behavior: 'smooth',
			});
		}
	};
	const handleScrollRight = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({
				left: containerRef.current.scrollLeft - 900, // Ajustez cette valeur selon le besoin
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		const updateGlobalScrollPosition = () => {
			if (globalContainerRef.current) {
				setGlobalScrollPosition(globalContainerRef.current.scrollTop);
			}
		};
		const globalContainer = globalContainerRef.current;
		globalContainer.addEventListener('scroll', updateGlobalScrollPosition);
		return () => globalContainer.removeEventListener('scroll', updateGlobalScrollPosition);
	}, []);

	useEffect(() => {
		const updateScrollPosition = () => {
			if (containerRef.current) {
				setScrollPosition(containerRef.current.scrollLeft);
			}
		};
		const container = containerRef.current;
		container.addEventListener('scroll', updateScrollPosition);
		return () => container.removeEventListener('scroll', updateScrollPosition);
	}, []);

	async function handleNewAlbum(name, permissions, isMatterport) {
		await createAlbum(name, false, isMatterport, permissions);
	}

	async function handleNewVisit(date, permissions) {
		await createAlbum(date, true, false, permissions);
	}

	async function createAlbum(nameOrDate, isVisit, isMatterport, permissions) {
		const id = generateUniqueFirestoreId();
		const data = {
			albumId: null,
			clientPermission:
				type === 'clients' ||
				clients?.map((client) => client.id).includes(agency) ||
				permissions.includes('all') ||
				permissions.includes('clients'),
			createdAt: new Date().toISOString(),
			createdBy: {
				userId: uid,
				userType: type === 'clients' ? 'client' : 'collaborator',
				agencyId: type === 'clients' ? uid : agency,
			},
			deletedAt: null,
			fileType: null,
			fileSize: 0,
			id,
			isFavorite: false,
			modifiedAt: new Date().toISOString(),
			name: isVisit ? '' : (nameOrDate ?? t('translation.newAlbum')),
			orderDate: isVisit ? new Date(nameOrDate).toISOString() : new Date().toISOString(),
			permissions:
				type === 'clients' || clients?.map((client) => client.id).includes(agency)
					? ['all']
					: [...permissions.filter((id) => id !== 'clients'), agency],
			previousAlbumId: null,
			projectId,
			type: isMatterport ? 'albumMatterport' : isVisit ? 'visit' : 'album',
			url: null,
		};
		if (isVisit) {
			setVisits((oldValue) => {
				const nextState = oldValue ? [...oldValue] : [];
				nextState.push(data);
				nextState.sort((a, b) => b.orderDate?.localeCompare(a.orderDate));
				return nextState;
			});
			mixpanel.track('Visit Created', {
				'Project ID': projectId,
				Page: 'albums',
			});
		} else {
			setAlbums((oldValue) => [data, ...oldValue]);
			mixpanel.track('Album Created', {
				'Project ID': projectId,
				Page: 'albums',
			});
		}
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${id}`).set(data);
	}

	async function deleteAllMedias() {
		const BATCH_SIZE = 500;
		const mediasSnapshot = await firestore
			.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
			.get();

		let batch = firestore.batch();
		let batchPromises = [];
		let deletePromises = [];
		let operationCounter = 0;

		for (const media of mediasSnapshot.docs) {
			batch.delete(media.ref);

			if (media.data().url) {
				const fileRef = storage.ref(getPathStorageFromUrl(media.data().url));
				deletePromises.push(fileRef.delete());
			}

			operationCounter++;

			if (operationCounter === BATCH_SIZE) {
				batchPromises.push(batch.commit());
				batch = firestore.batch();

				await Promise.all(deletePromises);
				deletePromises = [];

				operationCounter = 0;
			}
		}

		if (operationCounter > 0) {
			batchPromises.push(batch.commit());
			await Promise.all(deletePromises);
		}

		await Promise.all(batchPromises);

		setVisits([]);
		setAlbums([]);
	}

	async function replaceByTemplate(project) {
		try {
			await deleteAllMedias();

			const mediasSnapshot = await firestore
				.collection(`${PROJECT_COLLECTION}/${project.id}/${MEDIA_COLLECTION}`)
				.where('type', '==', 'album')
				.orderBy('orderDate')
				.get();

			let batch = firestore.batch();
			let albums = [];

			for (const media of mediasSnapshot.docs) {
				const id = generateUniqueFirestoreId();
				const data = {
					...media.data(),
					clientPermission: true,
					createdAt: new Date().toISOString(),
					createdBy: {
						userId: uid,
						userType: type === 'clients' ? 'client' : 'collaborator',
						agencyId: type === 'clients' ? uid : agency,
					},
					fileType: null,
					fileSize: 0,
					id,
					isFavorite: false,
					modifiedAt: new Date().toISOString(),
					orderDate: new Date().toISOString(),
					permissions: ['all'],
					previousAlbumId: null,
					projectId,
					type: 'album',
					url: null,
				};
				albums.push(data);

				const newMediaRef = firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${id}`);
				batch.set(newMediaRef, data);
			}

			await batch.commit();
			setAlbums(albums.reverse());
		} catch (e) {
			console.error(e);
			alert(t('templates.error'));
		}
	}

	const handleReorganize = () => {
		setIsReorganizeMode((p) => !p);
	};

	const handleMoveLeft = (value) => {
		const albumsWithoutMatterport = albums.filter((album) => album.type !== 'albumMatterport');
		const index = albumsWithoutMatterport.findIndex((album) => album.id === value.id);
		if (index > 0) {
			const currentAlbum = albumsWithoutMatterport[index];
			const previousAlbum = albumsWithoutMatterport[index - 1];

			setSlideDirections((prevState) => {
				const nextState = [...prevState];
				nextState[index] = 'slide-left';
				nextState[index - 1] = 'slide-right';
				return nextState;
			});

			setTimeout(() => {
				setSlideDirections([]);
				setEndAnimationAlbumId(currentAlbum.id);

				setTimeout(() => {
					setEndAnimationAlbumId('');
				}, 50);

				setAlbums((prevState) => {
					const nextState = [...prevState];
					const index = nextState.findIndex((album) => album.id === value.id);
					const currentAlbum = nextState[index];
					const previousAlbum = nextState[index - 1];
					nextState[index] = {
						...previousAlbum,
						orderDate: new Date(new Date(currentAlbum.orderDate).getTime() - 1).toISOString(),
					};
					nextState[index - 1] = {
						...currentAlbum,
						orderDate: new Date(new Date(previousAlbum.orderDate).getTime() + 1).toISOString(),
					};
					return nextState;
				});

				firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${currentAlbum.id}`).update({
					orderDate: new Date(new Date(previousAlbum.orderDate).getTime() + 1).toISOString(),
				});
				firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${previousAlbum.id}`).update({
					orderDate: new Date(new Date(currentAlbum.orderDate).getTime() - 1).toISOString(),
				});
			}, 500);
		}
	};

	const handleMoveRight = (value) => {
		const albumsWithoutMatterport = albums.filter((album) => album.type !== 'albumMatterport');
		const index = albumsWithoutMatterport.findIndex((album) => album.id === value.id);
		if (index !== -1 && index < albumsWithoutMatterport.length - 1) {
			const currentAlbum = albumsWithoutMatterport[index];
			const nextAlbum = albumsWithoutMatterport[index + 1];

			setSlideDirections((prevState) => {
				const nextState = [...prevState];
				nextState[index] = 'slide-right';
				nextState[index + 1] = 'slide-left';
				return nextState;
			});

			setTimeout(() => {
				setSlideDirections([]);
				setEndAnimationAlbumId(nextAlbum.id);

				setTimeout(() => {
					setEndAnimationAlbumId('');
				}, 50);

				setAlbums((prevState) => {
					const nextState = [...prevState];
					nextState[index] = {
						...nextAlbum,
						orderDate: new Date(new Date(currentAlbum.orderDate).getTime() + 1).toISOString(),
					};
					nextState[index + 1] = {
						...currentAlbum,
						orderDate: new Date(new Date(nextAlbum.orderDate).getTime() - 1).toISOString(),
					};
					return nextState;
				});

				firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${currentAlbum.id}`).update({
					orderDate: new Date(new Date(nextAlbum.orderDate).getTime() - 1).toISOString(),
				});
				firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${nextAlbum.id}`).update({
					orderDate: new Date(new Date(currentAlbum.orderDate).getTime() + 1).toISOString(),
				});
			}, 500);
		}
	};

	// Animations

	const [count, setCount] = useState(false);
	const [opacity1, setOpacity1] = useState(0);
	const [opacity2, setOpacity2] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 400);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setOpacity2(1);
			}
		}, 0);
	}, [count]);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setOpacity1(1);
			}
		}, 1000);
	}, [count]);

	return (
		<div className="contenuPhotos" ref={globalContainerRef}>
			<div className={'albums'}>
				<div style={{ display: 'flex', marginBottom: 10 }}>
					<div className={'containerTitleInput'}>
						<p className={'titlePage'}>{t('photos.photos')}</p>

						<div style={{ display: 'flex', marginTop: 5 }}>
							<input
								type={'text'}
								placeholder={t('photos.research_album')}
								className={'rechercheAlbum'}
								value={research}
								onChange={(e) => setResearch(e.target.value)}
							/>
							<img src={searchIcon} alt="" className={'searchIcon'} />
						</div>
					</div>

					{!isModeLecture && (
						<div className={'containerButtonsAlbums'}>
							{currentProject?.creator === agency && (
								<div style={{ display: 'flex' }}>
									<GreyButton
										title={t('progression_page.replace_by_a_template')}
										image={replacePictures}
										functionOnClick={() => setModalReplaceTemplate(true)}
										marginRight={20}
									/>
								</div>
							)}

							<div style={{ display: 'flex' }}>
								<GreyButton
									title={
										isReorganizeMode ? t('photos.valid_the_order') : t('photos.reorganize_albums')
									}
									image={doubleArrows}
									functionOnClick={handleReorganize}
									conditionNoImage={isReorganizeMode}
								/>
							</div>

							{visits && (
								<div style={{ display: 'flex' }}>
									<GreyButton
										title={t('photos.create_visit')}
										image={plusSquare}
										functionOnClick={() => setModalNewVisit(true)}
									/>
								</div>
							)}

							{albums && (
								<div style={{ display: 'flex' }}>
									<GreyButton
										title={t('photos.create_album')}
										image={plusSquare}
										functionOnClick={() => setModalNewAlbum(true)}
									/>
								</div>
							)}
						</div>
					)}
				</div>

				<div className={'horizontalBarPhotos'} />

				<div style={{ height: 'calc(100vh - 118px' }}>
					<div className="albumList">
						<div className={'inner'}>
							<p className={'titlePart'}>
								{t('translation.visiteOnSite')}{' '}
								<span className={'countAlbums'}>• {visits?.length}</span>
							</p>
							<p className={'infosAutorisationVisit'}>{t('translation.visitsViewableDefault')}</p>

							<div style={{ display: 'flex' }}>
								{visits && visits.length > 3 && scrollPosition > 100 && (
									<div style={{ display: 'flex' }}>
										<div className={'buttonScrollVisitsRight'} onClick={handleScrollRight}>
											<img src={rightIcon} alt="" />
										</div>
									</div>
								)}

								<div className={'containerVisits'} ref={containerRef}>
									{visits ? (
										visits.length > 0 ? (
											visits.map(
												(visit, index) =>
													visit && (
														<VisitCard
															isLastVisit={index === 0}
															visits={visits}
															index={index}
															visit={visit}
															handleSelectAlbum={handleSelectAlbum}
															displayEye={selectedPartnerId !== agency}
														/>
													)
											)
										) : (
											<div className={'placeholderVisits'}>
												<img src={placeholderVisit1} alt="" className={'placeholderVisit1'} />
												<div className={'textPartPlaceholderVisits'}>
													<p className={'titlePlaceholderVisits'}>
														{t('translation.noVisits')}
													</p>
													<p className={'subtitlePlaceholderVisits'}>
														{t('translation.subtitleNoVisits')}
													</p>

													{!isModeLecture && (
														<div style={{ display: 'flex' }}>
															<div
																className={'buttonPlaceholderVisits'}
																onClick={() => setModalNewVisit(true)}>
																{t('translation.createFirstVisit')}
															</div>
														</div>
													)}
												</div>
											</div>
										)
									) : (
										<div style={{ marginTop: -30, opacity: opacity1 }}>
											<div
												className={'chargementContainerFinanceProgression'}
												style={{ marginTop: 10, marginLeft: 15 }}></div>
										</div>
									)}
									<div style={{ display: 'flex' }}>
										<div className={'combleVisits'} />
									</div>
								</div>
								{visits && visits.length > 3 && (
									<div style={{ display: 'flex' }}>
										<div className={'buttonScrollVisits'} onClick={handleScroll}>
											<img src={rightIcon} alt="" />
										</div>
									</div>
								)}
							</div>
							<p className={'titlePart'}>
								{t('common.albums')} <span className={'countAlbums'}>• {albums?.length}</span>
							</p>

							<div className={'containerAlbums'}>
								{albums ? (
									(isReorganizeMode
										? albums.filter((album) => album.type !== 'albumMatterport')
										: albums
									).map((item, index) =>
										transformString(item.name).includes(transformString(research)) ? (
											<AlbumCard
												key={item.id}
												value={item}
												handleSelectAlbum={handleSelectAlbum}
												opacity2={opacity2}
												projectImgUrl={projectImgUrl}
												isReorganizeMode={isReorganizeMode}
												handleMoveLeft={handleMoveLeft}
												handleMoveRight={handleMoveRight}
												slideDirection={slideDirections[index]}
												endAnimation={endAnimationAlbumId === item.id}
											/>
										) : null
									)
								) : (
									<div style={{ marginTop: -30, opacity: opacity1 }}>
										<div
											className={'chargementContainerFinanceProgression'}
											style={{ marginTop: 10, marginLeft: 15 }}
										/>
									</div>
								)}
							</div>

							{albums &&
								research &&
								albums.filter((item) => transformString(item.name).includes(transformString(research)))
									.length === 0 && (
									<div className={'noResultAlbums'}>
										<img src={gerySearchIcon} alt="" className={'searchIconResearch'} />
										<p className={'textNoResult'}>{t('common.no_result_found')}</p>
										<p className={'secondTextNoResult'}>{t('research_project.no_result_2')}</p>
									</div>
								)}

							{albums && albums.length < 1 ? (
								<div className={'placeholderAlbums'}>
									<img src={PhotoAppIcon} alt="" />
									<p className={'titlePlaceholder'}>{t('photos.placeholder_album')}</p>
									<p className={'subtitlePlaceholder'}>{t('photos.infos_placeholder_album')}</p>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>

			<ModalTyping
				modalTyping={modalNewAlbum}
				setModalTyping={setModalNewAlbum}
				title={t('photos.album_name')}
				placeholder={t('photos.album_name')}
				func={handleNewAlbum}
				matterportActive={matterportActive}
				chooseAutorisations={type !== 'clients' && !clients?.map((client) => client.id).includes(agency)}
			/>

			<ModalTyping
				modalTyping={modalNewVisit}
				setModalTyping={setModalNewVisit}
				isDate={true}
				title={t('photos.visit_date')}
				placeholder={t('photos.visit_date')}
				func={handleNewVisit}
				chooseAutorisations={type !== 'clients' && !clients?.map((client) => client.id).includes(agency)}
			/>

			<ModalReplaceTemplate
				modalReplaceTemplate={modalReplaceTemplate}
				setModalReplaceTemplate={setModalReplaceTemplate}
				handleReplaceTemplate={replaceByTemplate}
			/>
		</div>
	);
}
