import React, { useContext, useEffect, useState, useRef } from 'react';
import '../messaging.scss';
import sendIcon from '../../../assets/sendIcon.png';
import newLinkIcon from '../../../assets/newLinkIcon.png';
import { updateBadge } from '../../Fiche Projet/Notifications/utils';
import { auth, firestore, functions, storage } from '../../../firebase/config';
import { generateUniqueFirestoreId, joinPaths, uploadOnStorage } from '../../../firebase/utils';
import { httpsCallable } from 'firebase/functions';
import AuthDataContext from '../../../contexts/authDataContext';
import { useTranslation } from 'react-i18next';
import { useProjectsContext } from '../../../contexts/projectsContext';
import TypeContext from '../../../contexts/typeContext';
import crossIcon from '../../../assets/blackCrossIcon.svg';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import ResponseMessage from './Message/Response Message/ResponseMessage';
import mixpanel from 'mixpanel-browser';
import { chooseIconFile, formatBytes } from 'src/pages/Fiche Projet/Documents/utils';
import { resizeImage } from 'src/useful/image';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

export default function InputConv({
	inputRef,
	conversation,
	responseMessage,
	setResponseMessage,
	setMessages,
	contacts,
	temporaryFiles,
	setTemporaryFiles,
	setSendMessageProgress,
	isSending,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const authData = useContext(AuthDataContext);
	const agencyData = useContext(AgencyDataContext);
	const [projects] = useProjectsContext();

	const [inputText, setInputText] = useState('');
	const [failedMessages, setFailedMessages] = useState([]);

	const projectName = projects?.find((p) => p.id === conversation.parentId)?.name;
	const messageQueue = useRef([]);

	useEffect(() => {
		if (inputRef && inputRef.current) {
			inputRef.current.style.height = '0px';
			const scrollHeight = inputRef.current.scrollHeight;
			inputRef.current.style.height = scrollHeight + 'px';
		}
	}, [inputText, inputRef]);

	useEffect(() => {
		setTemporaryFiles([]);
		setInputText('');
		setResponseMessage(null);
		setFailedMessages([]);
	}, [conversation?.id]);

	// ================================ FILES AND PASTE HANDLING ================================

	const handleFiles = async (e) => {
		e.preventDefault();

		if (e.target && e.target.files && e.target.files.length > 0) {
			for (const file of e.target.files) {
				const processedFile = file.type.includes('image') ? await resizeImage(file) : file;
				setTemporaryFiles((prevState) => [...prevState, processedFile]);
			}
		}
	};

	const handlePaste = async (e) => {
		e.preventDefault();
		const items = e.clipboardData.items;
		let text = e.clipboardData.getData('Text');
		setInputText((prev) => prev + text);

		for (let i = 0; i < items.length; i++) {
			if (items[i].kind === 'file') {
				const file = items[i].getAsFile();
				const processedFile = file.type.includes('image') ? await resizeImage(file) : file;
				setTemporaryFiles((prevState) => [...prevState, processedFile]);
				break;
			}
		}
	};

	// ================================ SEND MESSAGE ================================

	const sendNotification = (userId, content, contentType) => {
		firestore
			.doc(`accounts/${userId}`)
			.get()
			.then(async (documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					const tokens = documentSnapshot.data().tokens;

					const call = httpsCallable(functions, 'sendNotificationPush');
					call({
						tokens: tokens ?? [],
						title: conversation.isGroup
							? conversation.parentId === agencyData?.id
								? agencyData?.name
								: projectName
							: (authData?.surname ?? '') +
								(authData?.surname && authData?.name ? ' ' : '') +
								(authData?.name ?? ''),
						body: conversation.isGroup
							? conversation?.name +
								'\n' +
								(contentType === 'picture'
									? `${authData?.surname} ${authData?.name} sent a picture.`
									: contentType === 'video'
										? `${authData?.surname} ${authData?.name} sent a video.`
										: contentType === 'file'
											? `${authData?.surname} ${authData?.name} sent a file.`
											: content)
							: contentType === 'picture'
								? 'Sent a picture.'
								: contentType === 'video'
									? 'Sent a video.'
									: contentType === 'file'
										? 'Sent a file.'
										: content,
						data: {
							page: 'messaging',
							conversation: conversation.isGroup ? conversation.id : uid,
						},
					}).then((r) => {
						updateBadge(userId);
					});
				}
			});
	};

	const sendMessage = async (data) => {
		if (conversation.isGroup) {
			await firestore.doc(joinPaths('messaging', conversation.id, 'messages', data.id)).set({
				...data,
				sender: uid,
				conversation: conversation.id,
			});

			const memberIds = (
				await firestore.collection(joinPaths('messaging', conversation.id, 'participants')).get()
			).docs.map((doc) => doc.id);

			const batch = firestore.batch();
			memberIds.forEach((memberId) => {
				if (memberId !== uid) {
					batch.update(firestore.doc(joinPaths('users', memberId, 'messaging', conversation.id)), {
						lastMessageAt: new Date().toISOString(),
						isUnread: true,
					});
				} else {
					batch.update(firestore.doc(joinPaths('users', memberId, 'messaging', conversation.id)), {
						lastMessageAt: new Date().toISOString(),
						lastClickAt: new Date(new Date().getTime() + 10).toISOString(),
						isUnread: false,
					});
				}
			});

			await batch.commit();

			memberIds.forEach((memberId) => {
				if (memberId !== uid) {
					sendNotification(memberId, data.contents, data.type);
				}
			});
		} else {
			let message = {
				...data,
				sender: uid,
				conversation: uid,
			};

			const receiverPath =
				conversation.userType === 'clients'
					? joinPaths('clients')
					: joinPaths('agencies', conversation.userAgencyId, 'collaborators');
			await firestore
				.doc(joinPaths(receiverPath, conversation.id, 'messaging', uid, 'messages', message.id))
				.set(message);

			message = {
				...message,
				conversation: conversation.id,
			};

			const senderPath =
				type === 'clients' ? joinPaths('clients') : joinPaths('agencies', agencyData.id, 'collaborators');
			await firestore
				.doc(joinPaths(senderPath, uid, 'messaging', conversation.id, 'messages', message.id))
				.set(message);

			await firestore.doc(joinPaths('users', conversation.id, 'messaging', uid)).update({
				lastMessageAt: new Date().toISOString(),
				isUnread: true,
			});

			await firestore.doc(joinPaths('users', uid, 'messaging', conversation.id)).update({
				lastMessageAt: new Date().toISOString(),
				lastClickAt: new Date(new Date().getTime() + 10).toISOString(),
				isUnread: false,
			});

			sendNotification(conversation.id, data.contents, data.type);
		}
	};

	const processQueue = async () => {
		if (isSending.current) return;
		isSending.current = true;

		const totalMessages = messageQueue.current.length;
		let processedMessages = 0;

		try {
			while (messageQueue.current.length > 0) {
				const message = messageQueue.current[0];
				let retryCount = 0;

				while (retryCount < MAX_RETRIES) {
					try {
						setSendMessageProgress(Math.max((processedMessages / totalMessages) * 100, 10));

						if (message.file) {
							const { message: messageToSend, file } = message;

							const fileURL = await uploadOnStorage(
								messageToSend.id,
								file,
								messageToSend,
								`messaging/${conversation.id}/files`
							);
							messageToSend.contents = fileURL;

							await sendMessage(messageToSend);
						} else {
							await sendMessage(message);
						}

						processedMessages++;
						setSendMessageProgress(Math.max((processedMessages / totalMessages) * 100, 10));

						messageQueue.current.shift();
						break;
					} catch (error) {
						console.error(`Error sending message (attempt ${retryCount + 1}):`, error);
						retryCount++;

						if (retryCount === MAX_RETRIES) {
							setFailedMessages((prev) => [...prev, message]);
							messageQueue.current.shift();
							processedMessages++;
							setSendMessageProgress(Math.max((processedMessages / totalMessages) * 100, 10));
						} else {
							await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
						}
					}
				}
			}
		} finally {
			isSending.current = false;
			setSendMessageProgress(100);
		}
	};

	const retryFailedMessages = async () => {
		messageQueue.current.push(...failedMessages);
		setFailedMessages([]);
		processQueue();
	};

	const sendMessageText = async () => {
		if (inputText.trim().length < 1) return;

		const message = {
			contents: inputText,
			date: new Date().toISOString(),
			sender: uid,
			conversation: conversation.id,
			type: 'text',
			responseMessage: responseMessage ? responseMessage : null,
			id: generateUniqueFirestoreId(),
		};

		messageQueue.current.push(message);
		setMessages((prevState) => [{ ...message, isLocal: true }, ...prevState]);
		setInputText('');
		setResponseMessage(null);

		processQueue();

		mixpanel.track('Message Sent', {
			'Conversation ID': conversation.id,
			'Conversation Type': conversation.isGroup
				? conversation.parentId === agencyData?.id
					? 'intern group'
					: 'project group'
				: 'private',
			'Conversation Parent ID': conversation.parentId,
			Type: 'text',
		});
	};

	const sendFiles = async () => {
		if (temporaryFiles.length === 0) return;

		const files = [...temporaryFiles];
		setTemporaryFiles([]);
		const messages = [];

		for (const file of files) {
			const message = {
				contents: await new Promise((resolve) => {
					const reader = new FileReader();
					reader.onload = () => resolve(reader.result);
					reader.readAsDataURL(file);
				}),
				date: new Date().toISOString(),
				sender: uid,
				conversation: conversation.id,
				type: file.type.includes('video') ? 'video' : file.type.includes('image') ? 'picture' : 'file',
				id: generateUniqueFirestoreId(),
				fileName: file.name,
				fileType: file.type,
				fileSize: file.size,
			};

			messages.push({ message, file });
			setMessages((prevState) => [{ ...message, isLocal: true }, ...prevState]);
		}

		for (const message of messages) {
			messageQueue.current.push(message);
		}

		processQueue();

		mixpanel.track('Message Sent', {
			'Conversation ID': conversation.id,
			'Conversation Type': conversation.isGroup
				? conversation.parentId === agencyData?.id
					? 'intern group'
					: 'project group'
				: 'private',
			Type: 'files',
		});
	};

	const onSend = async () => {
		const hasFiles = temporaryFiles.length > 0;
		const hasText = inputText.length > 0;

		if (hasFiles || hasText) {
			setSendMessageProgress(10);
		}

		if (hasFiles) {
			await sendFiles();
		}

		if (hasText) {
			await sendMessageText();
		}
	};

	// ================================ RENDER ================================

	return (
		<div
			className={`containerInputConv ${
				temporaryFiles.length > 0 || inputText.length > 0 || responseMessage ? 'opaque' : ''
			}`}>
			{failedMessages.length > 0 && (
				<div className="retryFailedMessages">
					<p className="retryFailedMessagesText">{t('translation.some_messages_failed_to_send')}</p>
					<p className="retryButton" onClick={retryFailedMessages}>
						{t('translation.retry?')}
					</p>
				</div>
			)}

			{temporaryFiles.length > 0 && (
				<div className="temporaryFiles">
					<div className="picturesList">
						{temporaryFiles
							.filter((file) => file.type.includes('image'))
							.map((file, index) => (
								<div
									key={file.name}
									className={`pictureContainer ${index === 0 ? 'firstPicture' : ''}`}>
									<img src={URL.createObjectURL(file)} alt="" className="previewImage" />
									<img
										src={crossIcon}
										alt=""
										className="removePicture"
										onClick={() =>
											setTemporaryFiles((prevState) => prevState.filter((f) => f !== file))
										}
									/>
								</div>
							))}
					</div>
					<div className="filesList">
						{temporaryFiles
							.filter((file) => !file.type.includes('image'))
							.map((file, index) => (
								<div key={file.name} className={`fileContainer ${index === 0 ? 'firstFile' : ''}`}>
									<div className="filePreview">
										<img src={chooseIconFile(file.type)} alt="" className="fileTypeIcon" />
										<div className="fileInfo">
											<p className="fileName">{file.name}</p>
											<p className="fileSize">{formatBytes(file.size)}</p>
										</div>
										<img
											src={crossIcon}
											alt=""
											className="removeFile"
											onClick={() =>
												setTemporaryFiles((prevState) => prevState.filter((f) => f !== file))
											}
										/>
									</div>
								</div>
							))}
					</div>
				</div>
			)}

			<div className="inputActions">
				<label htmlFor={`inputFiles`}>
					<img src={newLinkIcon} alt="" className={'linkIcon hover'} />
				</label>
				<input
					type={'file'}
					id={`inputFiles`}
					className={'input-hidden'}
					multiple={true}
					onChange={handleFiles}
				/>

				<div className="messageInput">
					{temporaryFiles.length === 0 && responseMessage && (
						<div className="responsePreview">
							<ResponseMessage message={responseMessage} contacts={contacts} />
							<img src={crossIcon} alt="" className={'cross'} onClick={() => setResponseMessage(null)} />
						</div>
					)}

					<textarea
						ref={inputRef}
						className={'inputConv'}
						rows={1}
						placeholder={t('translation.message')}
						autoCapitalize={'true'}
						value={inputText}
						onChange={(e) => setInputText(e.target.value)}
						onPaste={handlePaste}
					/>
				</div>

				<img
					src={sendIcon}
					alt=""
					className={`sendIcon hover ${inputText.length < 1 && temporaryFiles.length === 0 ? 'disabled' : ''}`}
					onClick={onSend}
				/>
			</div>
		</div>
	);
}
