import React, { useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export default function DropZoneFile({ isDragging, setIsDragging, handleDrop, actualPath }) {
	const { t } = useTranslation();
	const dropZoneRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropZoneRef.current && !dropZoneRef.current.contains(event.target)) {
				setIsDragging(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isDragging, handleDrop, actualPath]);

	const onDropCallback = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles?.length > 0) {
				handleDrop(acceptedFiles, actualPath);
			}
		},
		[actualPath, handleDrop]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropCallback });

	return (
		<>
			{isDragging ? (
				<div {...getRootProps()} className={'dropZone'} style={{ zIndex: 2 }} ref={dropZoneRef}>
					<input {...getInputProps()} />
					<div className={'containerTextDropZone'}>
						<p style={{ color: 'white', fontSize: 22, marginBottom: 0 }}>{t('translation.deposeFiles')}</p>
						<p style={{ color: 'white', fontSize: 13 }}>{t('translation.dropZonePlaceholder')}</p>
					</div>
				</div>
			) : null}
		</>
	);
}
