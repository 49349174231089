import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import Resizer from 'react-image-file-resizer';
import { deleteStorageFromUrl, generateUniqueFirestoreId } from '../../../../firebase/utils';
import crossIcon from '../../../../assets/crossIcon.png';
import ImgUpload from '../../../../assets/ImgUpload.png';
import { useTranslation } from 'react-i18next';
import '../_photos.scss';
import ItemAccessibilityPhotos from '../components/ItemAccessibilityPhotos/ItemAccessibilityPhotos';
import { auth, firestore, storage } from '../../../../firebase/config';
import { MEDIA_COLLECTION, PROJECT_COLLECTION } from '../../../../firebase/paths';
import ModalTyping from '../../../../components/ModalTyping/ModalTyping';
import TypeContext from '../../../../contexts/typeContext';
import AgencyContext from '../../../../contexts/agencyContext';
import { useProjectContext } from '../../../../contexts/projectContext';
import { usePartnersContext } from '../../../../contexts/partnersContext';
import { useClientsContext } from '../../../../contexts/clientsContext';

export default function ModalModifAlbum({
	projectId,
	selectedAlbum,
	setAlbumSelected,
	modalModifAlbum,
	setModalModifAlbum,
	setNameAlbumSelected,
	setAlbums,
	selectedAlbumIndex,
	isVisit,
	setDisplayAlbum,
	setOtherTypeAlbums,
}) {
	const { t } = useTranslation();
	const uid = auth.currentUser.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [partners] = usePartnersContext();
	const [clients] = useClientsContext();

	const [img, setImg] = useState();
	const [imgFile, setImgFile] = useState();
	const [name, setName] = useState('');
	const [clientPermission, setClientPermission] = useState(true);
	const [permissions, setPermissions] = useState([]);
	const [orderDate, setOrderDate] = useState('');

	const [loading, setLoading] = useState(false);
	const [modalName, setModalName] = useState(false);
	const [modalDate, setModalDate] = useState(false);

	useEffect(() => {
		if (selectedAlbum) {
			setName(selectedAlbum.name);
			setOrderDate(selectedAlbum.orderDate?.split('T')[0]);
			setImg(selectedAlbum.url);
			setClientPermission(selectedAlbum.clientPermission ?? true);
			setPermissions(selectedAlbum.permissions ?? []);
		}
	}, [selectedAlbum]);

	const imageHandler = async (e) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setImg(reader.result);
			}
		};
		reader.readAsDataURL(e.target.files[0]);
		setImgFile(e.target.files[0]);
	};

	async function saveEdits() {
		let data = null;
		if (imgFile && img !== selectedAlbum.url) {
			await new Promise((resolve, reject) => {
				Resizer.imageFileResizer(
					imgFile,
					700,
					700,
					'jpeg',
					80,
					0,
					async (file) => {
						if (selectedAlbum.url) {
							await deleteStorageFromUrl(selectedAlbum.url);
						}
						const storageRef = storage.ref(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`);
						const fileRef = storageRef.child(generateUniqueFirestoreId());
						await fileRef.put(file);
						const url = await fileRef.getDownloadURL();

						setNameAlbumSelected(name);

						data = {
							name: name ?? selectedAlbum.name,
							orderDate: orderDate ?? selectedAlbum.orderDate,
							url,
							clientPermission: clientPermission,
							permissions: permissions?.includes('all')
								? ['all']
								: [agencyId, ...(permissions ? permissions.filter((id) => id !== agencyId) : [])],
						};

						resolve();
					},
					'file',
					500,
					500
				);
			});
		} else if (
			selectedAlbum.name !== name ||
			selectedAlbum.orderDate !== orderDate ||
			permissions !== selectedAlbum.permissions ||
			clientPermission !== selectedAlbum.clientPermission
		) {
			setNameAlbumSelected(name);
			data = {
				name: name ?? selectedAlbum.name,
				orderDate: orderDate ?? selectedAlbum.orderDate,
				clientPermission: clientPermission,
				permissions: permissions?.includes('all')
					? ['all']
					: [agencyId, ...(permissions ? permissions.filter((id) => id !== agencyId) : [])],
			};
		}

		if (data) {
			setAlbums((oldValue) => {
				const newValue = [...oldValue];
				newValue[selectedAlbumIndex] = { ...selectedAlbum, ...data };
				setAlbumSelected(newValue[selectedAlbumIndex]);
				return newValue;
			});
			await firestore
				.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${selectedAlbum.id}`)
				.update(data);
			setModalModifAlbum(false);
			setImgFile(null);
		}
	}

	async function deleteAlbum() {
		if (window.confirm(t('photos.sure_delete_album'))) {
			setLoading(true);
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
				.where('albumId', '==', selectedAlbum.id)
				.get()
				.then(async (querySnapshot) => {
					if (querySnapshot) {
						querySnapshot.forEach(async (documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								deleteStorageFromUrl(documentSnapshot.data().url);
								deleteStorageFromUrl(documentSnapshot.data().thumbnailUrl);
								documentSnapshot.ref.delete();
							}
						});
					}
					await deleteStorageFromUrl(selectedAlbum.url);
					await firestore
						.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${selectedAlbum.id}`)
						.delete();
					setModalModifAlbum(false);
					setDisplayAlbum(true);
					setLoading(false);
				});
			setAlbums((oldValue) => {
				const newValue = [...oldValue];
				newValue.splice(selectedAlbumIndex, 1);
				return newValue;
			});
		}
	}

	const handleAlbumToVisit = async (value) => {
		setLoading(true);
		const data = {
			orderDate: value,
			type: 'visit',
		};
		setAlbums((oldValue) => oldValue.filter((album) => album.id !== selectedAlbum.id));
		setOtherTypeAlbums((oldValue) =>
			[...oldValue, { ...selectedAlbum, ...data }].sort((a, b) => b.orderDate?.localeCompare(a.orderDate))
		);
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${selectedAlbum.id}`).update(data);
		setLoading(false);
		setModalModifAlbum(false);
		setDisplayAlbum(true);
	};

	const handleVisitToAlbum = async (name) => {
		setLoading(true);
		const data = {
			name,
			type: 'album',
		};
		setAlbums((oldValue) =>
			[...oldValue, { ...selectedAlbum, ...data }].sort((a, b) => b.orderDate?.localeCompare(a.orderDate))
		);
		setOtherTypeAlbums((oldValue) => oldValue.filter((album) => album.id !== selectedAlbum.id));
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${selectedAlbum.id}`).update(data);
		setLoading(false);
		setModalModifAlbum(false);
		setDisplayAlbum(true);
	};

	async function handleCloseModal() {
		saveEdits();
		setModalModifAlbum(false);
		setName(selectedAlbum.name);
		setOrderDate(selectedAlbum.orderDate?.split('T')[0]);
		setClientPermission(selectedAlbum.clientPermission ?? true);
		setPermissions(selectedAlbum.permissions ?? []);
		setImg(selectedAlbum.url);
		setImgFile(null);
	}

	return (
		<>
			{selectedAlbum && (
				<Modal
					isOpen={modalModifAlbum}
					className={'modalAlbumPhoto'}
					overlayClassName={'overlayModalAlbumPhoto'}
					onRequestClose={handleCloseModal}
					shouldCloseOnOverlayClick={true}
					closeTimeoutMS={300}>
					<h2 className={'title'}>{isVisit ? t('photos.edit_visit') : t('photos.edit_album')}</h2>

					<div style={{ display: 'flex' }}>
						<div className={'bar'} />
						<div
							className={'hover'}
							style={{
								height: 25,
								marginTop: -37,
								marginLeft: -25,
								backgroundColor: '#fff',
								border: 0,
							}}
							onClick={handleCloseModal}>
							<img
								src={crossIcon}
								alt="crossIcon"
								style={{
									width: 23,
									height: 23,
									marginTop: 3,
									objectFit: 'contain',
								}}
							/>
						</div>
					</div>

					<div className={'scrollModalAlbum'}>
						<div className={'inner'}>
							<div style={{ display: 'flex' }}>
								{!isVisit && (
									<div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: 20,
												marginTop: 10,
											}}>
											<div className="photos">
												<div className="img-holder">
													<label htmlFor="inputAlbum">
														<img
															className={'couvImageAlbum'}
															style={{
																marginTop: 10,
																width: 150,
																marginLeft: 20,
																borderRadius: 10,
																height: 150,
																objectFit: 'cover',
															}}
															src={img ? img : ImgUpload}
															alt={''}
														/>
													</label>
												</div>
											</div>
											<input
												type="file"
												id="inputAlbum"
												accept="image/*,.heic, .heif"
												onChange={imageHandler}
											/>
										</div>
									</div>
								)}

								<div style={{ marginTop: 20 }}>
									<p className={'subtitle'}>
										{isVisit ? t('photos.visit_date') : t('photos.album_name')}
									</p>

									{selectedAlbum.type === 'visit' ? (
										<input
											type={'date'}
											className={'inputNomAlbum'}
											id={'date'}
											value={orderDate?.split('T')[0]}
											placeholder={'Date de la visite'}
											onChange={(e) => setOrderDate(new Date(e.target.value).toISOString())}
										/>
									) : (
										<input
											type={'text'}
											className={'inputNomAlbum'}
											id={'name'}
											value={name}
											placeholder={t('photos.album_name')}
											onChange={(e) => setName(e.target.value)}
										/>
									)}
								</div>
							</div>

							{type !== 'clients' && !clients?.map((item) => item.id).includes(agencyId) && (
								<p className={'subtitle'} style={{ marginTop: 0, marginBottom: 20 }}>
									{t('translation.accessibility')}
								</p>
							)}

							{type !== 'clients' && !clients?.map((item) => item.id).includes(agencyId) && (
								<div style={{ marginLeft: 26, marginBottom: 10, width: 344 }}>
									<ItemAccessibilityPhotos
										handleSimpleChoice={() => {
											setPermissions((v) => {
												if (v.includes('all')) {
													setClientPermission(
														selectedAlbum.createdBy.userType == 'client' ||
															clients
																?.map((item) => item.id)
																.includes(selectedAlbum.createdBy.agencyId)
													);
													return selectedAlbum.createdBy.agencyId &&
														selectedAlbum.createdBy.agencyId !== agencyId
														? [agencyId, selectedAlbum.createdBy.agencyId]
														: [agencyId];
												} else {
													setClientPermission(true);
													return ['all'];
												}
											});
										}}
										name={t('translation.allUsers')}
										isActivated={permissions.includes('all')}
									/>
								</div>
							)}

							<div
								style={{
									display: 'flex',
									height: 2,
									margin: '10px 35px 10px 35px',
									backgroundColor: '#f8f8f8',
									borderRadius: 10,
								}}
							/>

							<div
								className={'listAccessibility'}
								style={{
									opacity: permissions.includes('all') ? 0.4 : 1,
									transition: 'opacity 0.3s ease-in-out',
								}}>
								{type !== 'clients' &&
									!clients?.map((item) => item.id).includes(agencyId) &&
									(selectedAlbum.createdBy.agencyId === agencyId ||
										selectedAlbum.createdBy.userId === uid ||
										agencyId === project?.creator) && (
										<ItemAccessibilityPhotos
											handleSimpleChoice={() => {
												if (permissions.includes('all')) {
													setClientPermission(false);
													setPermissions([...partners.map((item) => item.id)]);
												} else {
													setClientPermission((v) => !v);
													setPermissions((v) => v.filter((id) => id !== 'all'));
												}
											}}
											name={t('common.mo')}
											isActivated={clientPermission || permissions.includes('all')}
											isDisabled={
												selectedAlbum.createdBy.userType === 'client' ||
												clients
													?.map((item) => item.id)
													.includes(selectedAlbum.createdBy.agencyId)
											}
											isOwner={
												selectedAlbum.createdBy.userType === 'client' ||
												clients
													?.map((item) => item.id)
													.includes(selectedAlbum.createdBy.agencyId)
											}
										/>
									)}

								{type !== 'clients' &&
									!clients?.map((item) => item.id).includes(agencyId) &&
									partners &&
									partners.map((item) => (
										<ItemAccessibilityPhotos
											key={item.id}
											handleSimpleChoice={() =>
												setPermissions((v) => {
													if (v.includes('all')) {
														return [
															...partners
																.map((item) => item.id)
																.filter((id) => id !== item.id),
														];
													} else if (v.includes(item.id)) {
														return v.filter((id) => id !== item.id);
													} else {
														return [...v, item.id];
													}
												})
											}
											name={item.name}
											isActivated={permissions.includes(item.id) || permissions.includes('all')}
											isDisabled={selectedAlbum.createdBy.agencyId === item.id}
											isOwner={selectedAlbum.createdBy.agencyId === item.id}
										/>
									))}
							</div>
						</div>
					</div>

					<div className={'bar'} style={{ marginTop: 18 }} />

					<div className={'containerButtonsModalModifAlbum'} style={{ display: 'flex', marginTop: 10 }}>
						<div className={'supprimer'} onClick={deleteAlbum}>
							<p
								style={{
									fontSize: 14,
									fontWeight: 600,
									color: '#ff3232',
								}}>
								{t('common.delete')} {isVisit ? t('photos.the_visit') : t('photos.the_album')}
							</p>
						</div>

						<div
							className={'supprimer'}
							style={{
								backgroundColor: '#FFF',
							}}
							onClick={() => {
								if (isVisit) {
									setModalName(true);
								} else {
									setModalDate(true);
								}
							}}>
							<p
								style={{
									fontSize: 14,
									fontWeight: 700,
									color: '#4F66E3',
									textAlign: 'center',
								}}>
								{isVisit ? t('photos.visit_to_alum') : t('photos.album_to_visit')}
							</p>
						</div>

						<div
							className={'supprimer'}
							style={{
								backgroundColor: '#D2E7F8',
								marginRight: 20,
							}}
							onClick={saveEdits}>
							<p
								style={{
									fontSize: 14,
									fontWeight: 700,
									color: '#4F66E3',
								}}>
								{t('common.save')}
							</p>
						</div>
					</div>

					{loading && (
						<div className="deletion-overlay">
							<div className="deletion-spinner"></div>
							<p>{t('translation.deletingAlbum')}</p>
						</div>
					)}
				</Modal>
			)}

			<ModalTyping
				modalTyping={modalDate}
				setModalTyping={setModalDate}
				isDate={true}
				title={t('photos.visit_date')}
				func={handleAlbumToVisit}
			/>

			<ModalTyping
				modalTyping={modalName}
				setModalTyping={setModalName}
				title={t('photos.album_name')}
				func={handleVisitToAlbum}
			/>
		</>
	);
}
