import React, { useContext, useEffect, useState } from 'react';
import crossIcon from '../../../assets/crossIcon.png';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { useLocation, useNavigate } from 'react-router-dom';
import TypeContext from '../../../contexts/typeContext';
import AgencyContext from '../../../contexts/agencyContext';
import { auth, firestore } from '../../../firebase/config';
import AuthDataContext from '../../../contexts/authDataContext';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import {
	AGENCY_COLLECTION,
	CLIENT_COLLECTION,
	COLLABORATOR_COLLECTION,
	PROJECT_COLLECTION,
	USER_COLLECTION,
} from '../../../firebase/paths';
import { createdByConstructor, rolesWithMOA } from '../../Fiche Projet/utils';
import { sendNotificationToCollaborators } from '../../Fiche Projet/Notifications/utils';
import leftArrow from '../../../assets/leftArrow.svg';

export default function ModalAccessDenied({ setModalAccessDenied, modalAccessDenied }) {
	const { t } = useTranslation();
	const { show } = useIntercom();
	const location = useLocation();
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const uid = auth.currentUser?.uid;
	const navigate = useNavigate();
	const userData = useContext(AuthDataContext);
	const agencyData = useContext(AgencyDataContext);

	const projectId = modalAccessDenied ?? '';

	const [isLoadingAskAgain, setIsLoadingAskAgain] = useState(false);
	const [projectName, setProjectName] = useState('');
	const [projectImg, setProjectImg] = useState('');
	const [roleIds, setRoleIds] = useState(['']);
	const [projectMandataireId, setProjectMandataireId] = useState('');
	const [partnerIds, setPartnerIds] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);
	const [agencyNameToNotify, setAgencyNameToNotify] = useState('');

	useEffect(() => {
		if (projectId) {
			firestore
				.doc(`${PROJECT_COLLECTION}/${projectId}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setProjectMandataireId(doc.data().creator);
					}
				});
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${USER_COLLECTION}`)
				.where('type', '==', 'agencies')
				.get()
				.then((query) => {
					const entreprises = [];
					query.forEach((doc) => {
						if (doc.exists) {
							entreprises.push(doc.id);
						}
					});
					setPartnerIds(entreprises);
					setDataLoading(false);
				});
		}
	}, [projectId]);

	useEffect(() => {
		if (partnerIds?.includes(agencyId)) {
			setAgencyNameToNotify(agencyData?.name);
		} else if (projectMandataireId) {
			firestore
				.doc(`${AGENCY_COLLECTION}/${projectMandataireId}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setAgencyNameToNotify(doc.data().name);
					}
				});
		}
	}, [partnerIds, projectMandataireId]);

	const handleAskAgain = async () => {
		if (!isLoadingAskAgain && !dataLoading) {
			setIsLoadingAskAgain(true);
			if (type === 'collaborators') {
				firestore
					.doc(`${PROJECT_COLLECTION}/${projectId}/${USER_COLLECTION}/${agencyId}`)
					.get()
					.then(async (doc) => {
						if (doc.exists) {
							firestore
								.doc(`${AGENCY_COLLECTION}/${agencyId}/${COLLABORATOR_COLLECTION}/${uid}`)
								.get()
								.then(async (doc) => {
									if (doc.exists) {
										if (doc.data().status === 'denied') {
											await addToWaitingList(agencyId);
										}
									}
								});
						} else {
							await addToWaitingList(projectMandataireId);
						}
					});
			} else {
				await addToWaitingList(projectMandataireId);
			}
		}
	};

	const addToWaitingList = async (agencyToSend) => {
		await firestore
			.doc(
				type === 'collaborators'
					? `${AGENCY_COLLECTION}/${agencyId}/${COLLABORATOR_COLLECTION}/${uid}/${PROJECT_COLLECTION}/${projectId}`
					: `${CLIENT_COLLECTION}/${uid}/${PROJECT_COLLECTION}/${projectId}`
			)
			.set({
				id: projectId,
				lastClick: null,
				active: true,
				name: projectName,
				tag: projectName,
				waitingStatus: 'pending',
				roleIds: type === 'clients' ? ['0'] : roleIds,
			});
		sendNotificationToCollaborators(
			createdByConstructor(uid, type, agencyId),
			agencyToSend,
			false,
			projectId,
			'joinProject',
			type === 'collaborators' ? { ...agencyData, roleIds } : null,
			[{ ...userData, status: 'pending' }],
			userData.name + ' ' + userData.surname,
			projectName,
			projectImg
		);
		setIsLoadingAskAgain(false);
		setModalAccessDenied(false);
	};

	const handleQuitProject = async () => {
		await firestore
			.doc(
				type === 'collaborators'
					? `${AGENCY_COLLECTION}/${agencyId}/${COLLABORATOR_COLLECTION}/${uid}/${PROJECT_COLLECTION}/${projectId}`
					: `${CLIENT_COLLECTION}/${uid}/${PROJECT_COLLECTION}/${projectId}`
			)
			.delete();
	};

	const handleContactOpus = () => {
		show();
		setModalAccessDenied(false);
	};

	return (
		<Modal
			isOpen={modalAccessDenied}
			className={'modalAccessDenied projectToJoin'}
			overlayClassName="OverlayModal"
			onRequestClose={() => {
				setModalAccessDenied(false);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalAccessDenied(false);
				}}
			/>
			<h1 style={{ fontSize: 50 }}>🚫</h1>
			<p className={'titleModal'}>{t('translation.accessDenied')} </p>
			<p className={'subtitleModal'}>{t('translation.wevAsk')}</p>
			<p className={'whichRole'}>{t('translation.whatIsYourRole')}</p>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
				{roleIds.map((roleId) => (
					<div style={{ display: 'flex', alignItems: 'center' }} key={roleId}>
						<select
							className={'select'}
							value={roleId}
							onChange={(e) => {
								let newRoles = roleIds;
								if (e.target.value === '') {
									newRoles = [''];
								} else {
									newRoles = [e.target.value];
								}
								setRoleIds(newRoles);
							}}>
							<option value="">{t('translation.selectARole')}</option>
							{[...new Set(rolesWithMOA(t).map((role) => role.category))].map((category) => (
								<optgroup label={category} key={category}>
									{rolesWithMOA(t)
										.filter((role) => role.category === category)
										.map((role) => (
											<option key={role.id} value={role.id}>
												{role.name}
											</option>
										))}
								</optgroup>
							))}
						</select>
						<img src={leftArrow} alt="" className={'leftArrow'} />
					</div>
				))}
			</div>
			<div style={{ display: 'flex', gap: 20 }}>
				<div className={'buttonModalBlue hover'} onClick={handleAskAgain}>
					{isLoadingAskAgain ? t('common.loading') : t('translation.askAgain')}
				</div>
				<div className={'buttonModal hover'}>{t('translation.quitProject')}</div>
			</div>
			<p className={'contact hover'} onClick={handleContactOpus}>
				{t('translation.contactOpus')}
			</p>
		</Modal>
	);
}
