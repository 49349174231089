import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import multipleSelection from '../../../../../assets/multipleSelection.svg';
import Modal from 'react-modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import { useProjectContext } from '../../../../../contexts/projectContext';
import ItemAccessibility from '../Document Infos/components/ItemAccessibility';
import { usePartnersContext } from '../../../../../contexts/partnersContext';
import { chooseIconFile, modifyPermissionsFile } from '../../utils';
import { auth } from '../../../../../firebase/config';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import { createdByConstructor } from '../../../utils';
import {
	NotificationSource,
	sendNotificationToClients,
	sendNotificationToCollaborators,
} from '../../../Notifications/utils';
import AuthDataContext from '../../../../../contexts/authDataContext';
import { useClientsContext } from '../../../../../contexts/clientsContext';
import ItemAccessibilityInternal from '../Document Infos/components/ItemAccessibilityInternal';

export default function ModalPermissions({
	modal,
	setModal,
	uploadedFileIds,
	setUploadedFileIds,
	files,
	setFiles,
	parentFolder,
}) {
	const { t } = useTranslation();
	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [partners] = usePartnersContext();
	const [clients] = useClientsContext();
	const authData = useContext(AuthDataContext);

	const [clientPermission, setClientPermission] = useState(parentFolder?.clientPermission ?? false);
	const [clientPermissionReadOnly, setClientPermissionReadOnly] = useState(
		parentFolder?.clientPermissionReadOnly ?? false
	);
	const [permissions, setPermissions] = useState(parentFolder?.permissions ?? []);
	const [permissionsReadOnly, setPermissionsReadOnly] = useState(parentFolder?.permissionsReadOnly ?? []);
	const [isAdminPermission, setIsAdminPermission] = useState(
		(parentFolder?.adminPermissions ?? []).includes(agencyId) ?? false
	);

	const [permissionsLoading, setPermissionsLoading] = useState(false);

	const isAdmin = authData?.subtype === 'administrator';
	const uploadedFiles = files.filter((f) => uploadedFileIds.includes(f.id));
	const filesLoading = uploadedFiles.some((f) => f.isLoading);
	const baseFiles = uploadedFiles.filter(
		(file) => file.path.length === Math.min(...uploadedFiles.map((f) => f.path.length))
	);

	useEffect(() => {
		setClientPermission(parentFolder?.clientPermission ?? false);
		setClientPermissionReadOnly(parentFolder?.clientPermissionReadOnly ?? false);
		setPermissions(parentFolder?.permissions ?? []);
		setPermissionsReadOnly(parentFolder?.permissionsReadOnly ?? []);
		setIsAdminPermission((parentFolder?.adminPermissions ?? []).includes(agencyId) ?? false);
	}, [parentFolder, modal]);

	const onValidate = async () => {
		if (!filesLoading) {
			setPermissionsLoading(true);
			for (const file of baseFiles) {
				await modifyPermissionsFile(
					project,
					setFiles,
					file,
					[agencyId, ...permissions],
					clientPermission,
					permissionsReadOnly,
					clientPermissionReadOnly,
					false,
					isAdminPermission ? agencyId : null
				);
			}

			for (const id of [agencyId, ...(permissions ?? []).filter((id) => id !== agencyId)]) {
				sendNotificationToCollaborators(
					createdByConstructor(uid, userType, agencyId),
					id,
					(isAdminPermission && id === agencyId) || (parentFolder?.adminPermissions ?? []).includes(id),
					project.id,
					NotificationSource.FILES,
					parentFolder,
					baseFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
			}
			if (clientPermission) {
				sendNotificationToClients(
					createdByConstructor(uid, userType, agencyId),
					project.id,
					NotificationSource.FILES,
					parentFolder,
					baseFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
			}

			setPermissionsLoading(false);
			setModal(false);
			setUploadedFileIds([]);
		}
	};

	return (
		<Modal
			isOpen={modal}
			className={'modalSetAccessDuringImport'}
			overlayClassName="OverlayModal"
			closeTimeoutMS={300}>
			<p className={'titleModal'}>{t('modalSetAccessDuringImport.newImport')}</p>
			<div className={'containerFirstPart'}>
				<div className={'folderSelected'}>
					<img
						src={
							baseFiles?.length > 1
								? multipleSelection
								: chooseIconFile(baseFiles[0]?.isFolder ? 'folder' : baseFiles[0]?.type)
						}
						alt="crossIcon"
						className={'folderIcon'}
					/>
					<div>
						<p className={'folderName'}>
							{baseFiles?.length > 1 ? t('translation.multipleImport') : baseFiles[0]?.name}
						</p>
					</div>
				</div>
			</div>
			<div className={'bar'} />

			<div className={'choiceActorsComponent'}>
				<p className={'question'}>{t('modalSetAccessDuringImport.selectActorsQuestion')}</p>
				<p className={'subtitle'}>{t('modalSetAccessDuringImport.selectActors')}</p>

				<div className={'listActors'}>
					{isAdmin && (
						<ItemAccessibilityInternal
							permission={isAdminPermission ? 'adminOnly' : 'all'}
							onClick={(permission) => {
								setIsAdminPermission(permission === 'adminOnly');
							}}
							disabled={(parentFolder?.adminPermissions ?? []).includes(agencyId)}
							withLoading={false}
						/>
					)}

					<ItemAccessibility
						name={t('common.mo')}
						diminutif={t('translation.terms.0.acronym')}
						permission={clientPermissionReadOnly ? 'readonly' : clientPermission ? 'all' : 'hidden'}
						onClick={(permission) => {
							if (permission === 'readonly') {
								setClientPermissionReadOnly(true);
								setClientPermission(true);
							} else if (permission === 'all') {
								setClientPermissionReadOnly(false);
								setClientPermission(true);
							} else {
								setClientPermissionReadOnly(false);
								setClientPermission(false);
							}
						}}
						disabled={clients?.length === 0}
						withLoading={false}
					/>

					{partners.map((it, index) => (
						<ItemAccessibility
							isModalPermission={true}
							key={index}
							name={it.name}
							diminutif={it.diminutifAgency}
							permission={
								permissionsReadOnly.includes(it.id)
									? 'readonly'
									: permissions.includes(it.id)
										? 'all'
										: 'hidden'
							}
							onClick={(permission) => {
								if (permission === 'readonly') {
									setPermissionsReadOnly((prev) => [...prev, it.id]);
									setPermissions((prev) => [...(prev ?? []).filter((id) => id !== it.id), it.id]);
								} else if (permission === 'all') {
									setPermissionsReadOnly((prev) => prev.filter((id) => id !== it.id));
									setPermissions((prev) => [...prev, it.id]);
								} else {
									setPermissionsReadOnly((prev) => prev.filter((id) => id !== it.id));
									setPermissions((prev) => prev.filter((id) => id !== it.id));
								}
							}}
							disabled={false}
							withLoading={false}
						/>
					))}
				</div>
			</div>

			<div className={filesLoading ? 'largeButtonUpload' : 'largeButton'} onClick={onValidate}>
				{filesLoading
					? t('photos.importation')
					: permissionsLoading
						? t('common.loading')
						: t('modalAccessibilityFolder.saveMyChoices')}
			</div>
		</Modal>
	);
}
