import React, { useState, useContext, useEffect, useMemo } from 'react';
import './_ticketsPage.scss';
import TicketsHeader from './components/Tickets Header/TicketsHeader';
import TicketsColumn from './components/Tickets Column/TicketsColumn';
import ModalTicket from './components/Modal Ticket/ModalTicket';
import { generateUniqueFirestoreId } from '../../../firebase/utils';
import { createdByConstructor } from '../utils';
import { auth } from '../../../firebase/config';
import TypeContext from '../../../contexts/typeContext';
import AgencyContext from '../../../contexts/agencyContext';
import { firestore } from '../../../firebase/config';
import { joinPaths } from '../../../firebase/utils';
import { Paths } from '../../../firebase/paths';
import { useProjectContext } from '../../../contexts/projectContext';
import { useTranslation } from 'react-i18next';
import { TICKET_LOG_TYPE, useTicketColumns, useTicketProperties } from './data';
import ModalLots from '../components/Modal Lots/ModalLots';
import { arrayUnion } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import chargement from '../../../assets/chargement.png';
import { TICKET_STATUS } from './components/Modal Ticket/Follow Up/FollowUp';
import { getTicketStatus } from './utils';
import { useClientsContext } from 'src/contexts/clientsContext';
import { useProjectUsersContext } from 'src/contexts/projectUsersContext';
import PropTypes from 'prop-types';
import AuthDataContext from 'src/contexts/authDataContext';
import ModalPrintSnag from '../../Reports/Solo Snag Report/SoloSnagReport';

Tickets.propTypes = {
	isSnagging: PropTypes.bool.isRequired,
	isReadOnly: PropTypes.bool.isRequired,
};

export default function Tickets({ isSnagging, isReadOnly }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();
	const [users] = useProjectUsersContext();
	const authData = useContext(AuthDataContext);

	const { properties } = useTicketProperties();
	const { columns, setColumns } = useTicketColumns();

	const [tickets, setTickets] = useState([]);
	const [selectedTicketId, setSelectedTicketId] = useState(null);

	const [modalTicket, setModalTicket] = useState(false);
	const [dataLoading, setDataLoading] = useState(true);

	// ========================================= Sort Options =========================================

	const [sortChoice, setSortChoice] = useState('issueDate');
	const [sortUp, setSortUp] = useState(false);

	// ========================================= Filters =========================================

	const [isStatusShown, setIsStatusShown] = React.useState(true);
	const [isLotShown, setIsLotShown] = React.useState(true);
	const [isLocationShown, setIsLocationShown] = React.useState(true);
	const [isTicketOwnerShown, setIsTicketOwnerShown] = React.useState(true);
	const [isCompanyInChargeShown, setIsCompanyInChargeShown] = React.useState(true);
	const [isDueDateShown, setIsDueDateShown] = React.useState(true);
	const [isWorkDurationShown, setIsWorkDurationShown] = React.useState(true);
	const [isLinkedFileShown, setIsLinkedFileShown] = React.useState(true);
	const [isDescriptionShown, setIsDescriptionShown] = React.useState(true);

	const [companyInvolvedSelected, setCompanyInvolvedSelected] = useState([]);
	const [lotsConcernedSelected, setLotsConcernedSelected] = useState([]);
	const [locationOnSiteSelected, setLocationOnSiteSelected] = useState([]);
	const [rolesSelected, setRolesSelected] = useState([]);
	const [statusSelected, setStatusSelected] = useState([]);

	const filteredTickets = useMemo(() => {
		const isClient = userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		return tickets?.filter((ticket) => {
			return (
				(ticket.properties?.owner?.userId === uid ||
					(((ticket.properties.type === 'issue' &&
						(ticket.properties?.owner?.agencyId === agencyId ||
							ticket.properties?.companies?.includes(agencyId) ||
							ticket.properties?.reviewers?.includes(agencyId))) ||
						ticket.properties?.users?.includes(uid)) &&
						ticket.properties?.visibility !== 'private') ||
					(isClient &&
						(ticket.properties?.companies?.includes('clients') ||
							ticket.properties?.reviewers?.includes('clients') ||
							ticket.properties?.users?.includes('clients') ||
							ticket.properties?.owner?.userType === 'client' ||
							clients?.map((item) => item.id).includes(ticket.properties.owner?.agencyId)))) &&
				(companyInvolvedSelected.length < 1 ||
					companyInvolvedSelected.some(
						(company) =>
							ticket.properties?.owner?.agencyId === company ||
							ticket.properties?.companies?.includes(company) ||
							ticket.properties?.reviewers?.includes(company) ||
							(company === 'clients' &&
								(ticket.properties?.users?.includes(company) ||
									ticket.properties?.owner?.userType === 'client' ||
									clients?.map((item) => item.id)?.includes(ticket.properties?.owner?.agencyId)))
					)) &&
				(lotsConcernedSelected.length < 1 ||
					lotsConcernedSelected.some((lot) => ticket.properties?.lots?.includes(lot))) &&
				(locationOnSiteSelected.length < 1 ||
					locationOnSiteSelected.some((location) => ticket.properties?.locations?.includes(location))) &&
				(rolesSelected.length < 1 || rolesSelected.some((role) => ticketHasRole(ticket, role))) &&
				(statusSelected.length < 1 ||
					statusSelected.some(
						(status) => getTicketStatus(ticket, isClient ? 'clients' : agencyId) === status
					))
			);
		});
	}, [
		tickets,
		companyInvolvedSelected,
		lotsConcernedSelected,
		locationOnSiteSelected,
		uid,
		agencyId,
		rolesSelected,
		statusSelected,
		userType,
		clients,
	]);

	function ticketHasRole(ticket, role) {
		const isClient = userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		if (role === 'creator')
			return (
				ticket.properties?.owner?.agencyId === agencyId ||
				ticket.properties?.owner?.userId === uid ||
				(isClient &&
					(ticket.properties?.owner?.userType === 'client' ||
						clients?.map((client) => client.id)?.includes(ticket.properties?.owner?.agencyId)))
			);
		if (role === 'company')
			return (
				ticket.properties?.companies?.includes(agencyId) ||
				(isClient && ticket.properties?.companies?.includes('clients'))
			);
		if (role === 'user')
			return (
				ticket.properties?.users?.includes(uid) || (isClient && ticket.properties?.users?.includes('clients'))
			);
		if (role === 'reviewer')
			return (
				ticket.properties?.reviewers?.includes(agencyId) ||
				(isClient && ticket.properties?.reviewers?.includes('clients'))
			);
		return false;
	}

	// ========================================= Get Tickets =========================================

	useEffect(() => {
		if (project.id) {
			if (!isReadOnly || isSnagging) {
				const unsubscribe = firestore
					.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS))
					.where('properties.type', '==', isSnagging ? 'issue' : 'mission')
					.onSnapshot((snapshot) => {
						const ticketsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
						setTickets(ticketsData);
						setDataLoading(false);
					});
				return () => unsubscribe();
			} else {
				setDataLoading(false);
			}
		}
	}, [project.id, isSnagging, isReadOnly]);

	// ========================================= Create Ticket =========================================

	const handleCreateTicket = (type) => {
		mixpanel.track('Work Created', {
			'Project ID': project.id,
			Page: 'workspace',
			'Work Type': type,
		});

		const id = generateUniqueFirestoreId();
		const data = {
			id,
			status: 'ongoing',
			name: type === 'mission' ? t('translation.newMission') : t('translation.newSnag'),
			properties: {
				visibility: 'collaborative',
				type,
				owner: createdByConstructor(uid, userType, agencyId),
				issueAt: new Date().toISOString(),
				reviewers:
					userType === 'clients' || clients?.map((item) => item.id)?.includes(agencyId)
						? ['clients']
						: [agencyId],
				users:
					type === 'mission' ? users?.filter((user) => user.agency === agencyId)?.map((user) => user.id) : [],
			},
			createdBy: createdByConstructor(uid, userType, agencyId),
			createdAt: new Date().toISOString(),
			modifiedAt: new Date().toISOString(),
			companiesViewed:
				userType === 'clients'
					? ['clients', uid]
					: clients?.map((item) => item.id)?.includes(agencyId)
						? ['clients', agencyId, uid]
						: [agencyId, uid],
			issueStatus: TICKET_STATUS.inProgress,
			missionStatus: 'ongoing',
		};
		setTickets((prev) => [...prev, data]);
		setSelectedTicketId(id);
		setModalTicket(true);

		firestore
			.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS))
			.doc(id)
			.set(data);
		firestore
			.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.CONTENT))
			.doc(id)
			.set({
				type: 'text',
				title: t('translation.description'),
				text: '',
				createdBy: createdByConstructor(uid, userType, agencyId),
				createdAt: new Date().toISOString(),
				modifiedAt: new Date().toISOString(),
			});
		if (type === 'issue') {
			firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.LOGS)).add({
				type: TICKET_LOG_TYPE.created,
				name: authData.surname + ' ' + authData.name,
				createdAt: new Date().toISOString(),
				createdBy: createdByConstructor(uid, userType, agencyId),
			});
		}
	};

	// ========================================= Select Ticket =========================================

	const handleSelectTicket = (id) => {
		setSelectedTicketId(id);
		setModalTicket(true);
		if (uid && (userType === 'clients' || agencyId)) {
			firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id)).update({
				companiesViewed:
					userType === 'clients'
						? arrayUnion('clients', uid)
						: clients?.map((item) => item.id)?.includes(agencyId)
							? arrayUnion('clients', agencyId, uid)
							: arrayUnion(agencyId, uid),
				companiesViewedRefused:
					userType === 'clients'
						? arrayUnion('clients', uid)
						: clients?.map((item) => item.id)?.includes(agencyId)
							? arrayUnion('clients', agencyId, uid)
							: arrayUnion(agencyId, uid),
			});
		}
	};

	// ========================================= Change Number Of Columns =========================================

	const handleChangeNumberOfColumns = (column, func) => {
		setColumns((prev) => {
			const updatedColumns = prev.map((col) =>
				col.key === column.key ? { ...col, columns: func(col.columns) } : col
			);

			const columnValues = updatedColumns.reduce((acc, col) => {
				acc[col.key] = col.columns;
				return acc;
			}, {});
			localStorage.setItem('ticketColumns', JSON.stringify(columnValues));

			return updatedColumns;
		});
	};

	// ========================================= Render =========================================

	return (
		<div className={'ticketsPage'}>
			<TicketsHeader
				isSnagging={isSnagging}
				// Properties shown
				isStatusShown={isStatusShown}
				setIsStatusShown={setIsStatusShown}
				isLotShown={isLotShown}
				setIsLotShown={setIsLotShown}
				isLocationShown={isLocationShown}
				setIsLocationShown={setIsLocationShown}
				isTicketOwnerShown={isTicketOwnerShown}
				setIsTicketOwnerShown={setIsTicketOwnerShown}
				isCompanyInChargeShown={isCompanyInChargeShown}
				setIsCompanyInChargeShown={setIsCompanyInChargeShown}
				isDueDateShown={isDueDateShown}
				setIsDueDateShown={setIsDueDateShown}
				isWorkDurationShown={isWorkDurationShown}
				setIsWorkDurationShown={setIsWorkDurationShown}
				isLinkedFileShown={isLinkedFileShown}
				setIsLinkedFileShown={setIsLinkedFileShown}
				isDescriptionShown={isDescriptionShown}
				setIsDescriptionShown={setIsDescriptionShown}
				// Display filters
				companyInvolvedSelected={companyInvolvedSelected}
				setCompanyInvolvedSelected={setCompanyInvolvedSelected}
				lotsConcernedSelected={lotsConcernedSelected}
				setLotsConcernedSelected={setLotsConcernedSelected}
				locationOnSiteSelected={locationOnSiteSelected}
				setLocationOnSiteSelected={setLocationOnSiteSelected}
				rolesSelected={rolesSelected}
				setRolesSelected={setRolesSelected}
				statusSelected={statusSelected}
				setStatusSelected={setStatusSelected}
				// Sort options
				sortChoice={sortChoice}
				setSortChoice={setSortChoice}
				sortUp={sortUp}
				setSortUp={setSortUp}
				// Functions
				handleCreateTicket={handleCreateTicket}
				isReadOnly={isReadOnly}
				tickets={filteredTickets?.sort(
					(a, b) =>
						new Date(a.properties?.issueAt ?? '').getTime() -
						new Date(b.properties?.issueAt ?? '').getTime()
				)}
			/>

			{!dataLoading ? (
				isReadOnly && !isSnagging ? (
					<div className={'containerInfoLectureSeule'}>
						<span>{t('translation.youDontHaveRequiredAuth')}</span>
						<span>{t('translation.joinTheProjectToaAccessMissions')}</span>
					</div>
				) : (
					<div className={'containerTicketsColumns'}>
						{columns.length > 0 && (
							<TicketsColumn
								key={isSnagging ? 0 : 1}
								label={columns[isSnagging ? 0 : 1].label}
								numberOfColumns={columns[isSnagging ? 0 : 1].columns}
								setNumberOfColumns={(func) =>
									handleChangeNumberOfColumns(columns[isSnagging ? 0 : 1], func)
								}
								ticketsCount={
									filteredTickets?.filter(
										(ticket) => ticket.properties.type === columns[isSnagging ? 0 : 1].key
									).length
								}
								tickets={filteredTickets?.filter(
									(ticket) => ticket.properties.type === columns[isSnagging ? 0 : 1].key
								)}
								setModalTicket={setModalTicket}
								isStatusShown={isStatusShown}
								isLotShown={isLotShown}
								isLocationShown={isLocationShown}
								isTicketOwnerShown={isTicketOwnerShown}
								isCompanyInChargeShown={isCompanyInChargeShown}
								isDueDateShown={isDueDateShown}
								isWorkDurationShown={isWorkDurationShown}
								isLinkedFileShown={isLinkedFileShown}
								isDescriptionShown={isDescriptionShown}
								type={columns[isSnagging ? 0 : 1].key}
								sortChoice={sortChoice}
								setSortChoice={setSortChoice}
								sortUp={sortUp}
								setSortUp={setSortUp}
								handleCreateTicket={handleCreateTicket}
								handleSelectTicket={handleSelectTicket}
								isSnagging={isSnagging}
							/>
						)}
						<div style={{ display: 'flex' }}>
							<div style={{ width: 1, height: 20 }} />
						</div>
					</div>
				)
			) : (
				<div className={'chargementContainer'}>
					<img src={chargement} alt="" className={'chargement'} />
				</div>
			)}

			<ModalTicket
				isSnagging={isSnagging}
				isOpen={modalTicket}
				data={tickets?.find((ticket) => ticket.id === selectedTicketId)}
				onClose={() => {
					setModalTicket(false);
					setTimeout(() => {
						setSelectedTicketId(null);
					}, 100);
				}}
			/>
		</div>
	);
}
