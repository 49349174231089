import React, { useContext, useState, useEffect } from 'react';
import './_documentInfos.scss';
import ItemAccessibility from './components/ItemAccessibility';
import ButtonsDocumentInfos from './components/ButtonsDocumentInfos';
import { chooseIconFile, formatBytes, modifyPermissionsFile, trashId } from '../../utils';
import AgencyContext from '../../../../../contexts/agencyContext';
import TypeContext from '../../../../../contexts/typeContext';
import PdfViewer from '../../../../../components/PdfViewer';
import SeenByComponent from './components/SeenByComponent';
import { auth, firestore } from '../../../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../firebase/paths';
import multipleSelection from '../../../../../assets/multipleSelection.svg';
import eye from '../../../../../assets/eye.svg';
import { useTranslation } from 'react-i18next';
import { createdByConstructor } from '../../../utils';
import { usePartnersContext } from '../../../../../contexts/partnersContext';
import {
	NotificationSource,
	sendNotificationToClients,
	sendNotificationToCollaborators,
} from '../../../Notifications/utils';
import AuthDataContext from '../../../../../contexts/authDataContext';
import { useProjectContext } from '../../../../../contexts/projectContext';
import { useClientsContext } from '../../../../../contexts/clientsContext';
import ModalAddVersion from './modaux/Modal Add Version/ModalAddVersion';
import SeeVersions from './components/SeeVersions';
import { getFirstLetters } from 'src/useful/UsefulFunctions';
import ModalDelete from '../../../../../components/Modal Delete/ModalDelete';
import ItemAccessibilityInternal from './components/ItemAccessibilityInternal';

export default function DocumentInfos({
	projectId,
	selectedFileIds,
	setSelectedFileIds,
	files,
	setFiles,
	path,
	setPath,
	setModalMoveItem,
	filterPartnerId,
	parentFolder,
	setModalPreview,
	setPreviewFile,
}) {
	const { t, i18n } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const userType = useContext(TypeContext);
	const [partners] = usePartnersContext();
	const authData = useContext(AuthDataContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();

	const isAdmin = authData?.subtype === 'administrator';
	const isSelecting = selectedFileIds.length > 1;
	const file = files.find((file) => file.id === selectedFileIds[0]);
	const selectedFiles = files.filter((file) => selectedFileIds.includes(file.id));
	const canModify = selectedFiles.every(
		(it) =>
			it.createdBy?.agencyId === agencyId ||
			it.createdBy?.userId === uid ||
			agencyId === project?.creator ||
			((file.createdBy?.userType === 'client' ||
				clients?.map((client) => client.id)?.includes(file.createdBy?.agencyId)) &&
				(userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId)))
	);
	const isInTrash = file?.path?.includes(trashId);

	const [extendConsult, setExtendConsult] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [isVersionsOpen, setIsVersionOpen] = useState(false);
	const [modalAddVersion, setModalAddVersion] = useState(false);
	const [selectedVersionId, setSelectedVersionId] = useState(null);
	const [notes, setNotes] = useState(null);

	const selectedVersion =
		file?.versions?.length > 0
			? selectedVersionId
				? file?.versions?.find((it) => it.id === selectedVersionId)
				: file?.versions?.at(-1)
			: file;

	useEffect(() => {
		setIsVersionOpen(false);
		setSelectedVersionId(null);
	}, [file.id]);

	useEffect(() => {
		setNotes(selectedVersion?.notes || '');
	}, [selectedVersion]);

	const restoreFile = async () => {
		setSelectedFileIds([]);
		setIsLoading(true);
		const batch = firestore.batch();
		for (const it of selectedFiles) {
			const docRef = firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${it.id}`);

			if (it.isFolder) {
				const subFiles = (
					await firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('path', '>=', `${it.path}${it.id}/`)
						.where('path', '<', `${it.path}${it.id}/\uf8ff`)
						.get()
				).docs.map((it) => ({ ...it.data(), id: it.id }));
				for (const subFile of subFiles) {
					batch.update(firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${subFile.id}`), {
						path: subFile.previousPath,
						previousPath: null,
						deletedAt: null,
						modifiedAt: new Date().toISOString(),
					});
				}
			}

			batch.update(docRef, {
				path: it.previousPath,
				previousPath: null,
				deletedAt: null,
				modifiedAt: new Date().toISOString(),
			});
		}
		await batch.commit();
		setIsLoading(false);
	};

	const handlePermission = async (partnerId, previousPermission, permission) => {
		if (partnerId === agencyId && isAdmin) {
			for (let i = 0; i < selectedFiles.length; i++) {
				await modifyPermissionsFile(
					project,
					setFiles,
					selectedFiles[i],
					null,
					false,
					null,
					false,
					permission === 'all',
					agencyId
				);

				if (permission === 'all') {
					sendNotificationToCollaborators(
						createdByConstructor(uid, userType, agencyId),
						agencyId,
						false,
						projectId,
						NotificationSource.FILES,
						parentFolder,
						selectedFiles,
						authData?.surname + ' ' + authData?.name,
						project?.name,
						project?.imgUrl
					);
				}
			}
		} else if (partnerId === 'client') {
			for (let i = 0; i < selectedFiles.length; i++) {
				await modifyPermissionsFile(
					project,
					setFiles,
					selectedFiles[i],
					null,
					true,
					null,
					permission === 'readonly',
					permission === 'hidden'
				);
			}
			if (permission !== 'hidden' && previousPermission === 'hidden') {
				sendNotificationToClients(
					createdByConstructor(uid, userType, agencyId),
					projectId,
					NotificationSource.FILES,
					parentFolder,
					selectedFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
				for (const client of clients) {
					if (client.type === 'agencies') {
						sendNotificationToCollaborators(
							createdByConstructor(uid, userType, agencyId),
							client.id,
							false,
							projectId,
							NotificationSource.FILES,
							parentFolder,
							selectedFiles,
							authData?.surname + ' ' + authData?.name,
							project?.name,
							project?.imgUrl
						);
					}
				}
			}
		} else {
			for (let i = 0; i < selectedFiles.length; i++) {
				await modifyPermissionsFile(
					project,
					setFiles,
					selectedFiles[i],
					[partnerId],
					false,
					permission === 'readonly' ? [partnerId] : null,
					false,
					permission === 'hidden'
				);
			}
			if (permission !== 'hidden' && previousPermission === 'hidden') {
				sendNotificationToCollaborators(
					createdByConstructor(uid, userType, agencyId),
					partnerId,
					(parentFolder?.adminPermissions ?? []).includes(partnerId),
					projectId,
					NotificationSource.FILES,
					parentFolder,
					selectedFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
			}
		}
	};

	const updateVersion = async (versionId, data) => {
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${versionId}`).update(data);
	};

	return (
		<div className={'documentInfos'}>
			<div className={'itemInfos'}>
				{!file?.isFolder &&
					!isSelecting &&
					(file?.createdBy?.agencyId === agencyId ||
						file?.createdBy?.userId === uid ||
						((file?.createdBy?.userType === 'client' ||
							clients?.map((client) => client.id).includes(file?.createdBy?.agencyId)) &&
							(userType === 'clients' || clients?.map((client) => client.id).includes(agencyId))) ||
						file?.versions?.length > 0) && <div className={'separator'} />}

				{!file?.isFolder &&
					!isSelecting &&
					(file?.createdBy?.agencyId === agencyId ||
						file?.createdBy?.userId === uid ||
						((file?.createdBy?.userType === 'client' ||
							clients?.map((client) => client.id).includes(file?.createdBy?.agencyId)) &&
							(userType === 'clients' || clients?.map((client) => client.id).includes(agencyId))) ||
						file?.versions?.length > 0) && (
						<SeeVersions
							isVersionsOpen={isVersionsOpen}
							setIsVersionOpen={setIsVersionOpen}
							setModalAddVersion={setModalAddVersion}
							file={file}
							selectedVersionId={selectedVersionId ?? file.versions?.at(-1)?.id ?? null}
							setSelectedVersionId={setSelectedVersionId}
						/>
					)}

				{isSelecting ? (
					<img src={multipleSelection} alt="" className={'multipleSelection'} />
				) : (
					<div
						onClick={() => {
							if (!file?.isFolder) {
								setModalPreview(true);
								setPreviewFile(selectedVersion);
							}
						}}
						className={'containerIconOpening'}
						style={{
							marginTop: 40,
							display: 'flex',
							position: 'relative',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: !file?.isFolder ? 'pointer' : 'default',
						}}>
						<div>
							{selectedVersion?.type === 'application/pdf' ? (
								<div className={'folderIcon'}>
									{selectedVersion?.url ? (
										<PdfViewer file={selectedVersion.url} isPreview={true} width={100} />
									) : (
										<img
											src={chooseIconFile('application/pdf')}
											alt="PDF icon"
											className={'folderIcon'}
										/>
									)}
								</div>
							) : (
								<img
									src={
										isSelecting
											? chooseIconFile(null)
											: selectedVersion?.isFolder
												? chooseIconFile('folder')
												: selectedVersion?.type?.includes('image') ||
													  selectedVersion?.type?.includes('heic') ||
													  selectedVersion?.type?.includes('heif')
													? selectedVersion?.url
													: chooseIconFile(selectedVersion?.type)
									}
									alt=""
									className={'folderIcon'}
								/>
							)}
						</div>
						{(selectedVersion?.type?.includes('image') ||
							selectedVersion?.type?.includes('heic') ||
							selectedVersion?.type?.includes('heif') ||
							selectedVersion?.type === 'application/pdf') && (
							<div className={'containerOpenIcon'}>
								<img src={eye} alt="" className={'openIcon'} />
							</div>
						)}
					</div>
				)}

				<p className={'itemName'}>{isSelecting ? t('translation.selection') : selectedVersion?.name}</p>
				<p className={'complementaryInfo'}>
					{isSelecting
						? t('translation.selectedElements', { count: selectedFileIds.length })
						: (selectedVersion?.isFolder ? '' : formatBytes(selectedVersion?.size) + ' • ') +
							new Intl.DateTimeFormat(i18n.language, {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							}).format(new Date(selectedVersion?.createdAt))}
				</p>

				<p className={'adder'}>
					{t('translation.addedBy')}{' '}
					{file?.createdBy?.userType === 'client' ||
					clients?.map((client) => client.id).includes(file?.createdBy?.agencyId)
						? t('translation.terms.0.acronym')
						: file?.createdBy?.agencyId === agencyId
							? t('translation.you')
							: (partners?.find((it) => it.id === file?.createdBy?.agencyId)?.diminutifAgency ??
								getFirstLetters(partners?.find((it) => it.id === file?.createdBy?.agencyId)?.name))}
				</p>
			</div>

			{!file?.isOptionFolder && (
				<ButtonsDocumentInfos
					projectId={projectId}
					setFiles={setFiles}
					path={path}
					setPath={setPath}
					selectedFileIds={selectedFileIds}
					setSelectedFileIds={setSelectedFileIds}
					canModify={canModify}
					isInTrash={isInTrash}
					selectedFiles={selectedFiles}
					setModalMoveItem={setModalMoveItem}
					setIsLoading={setIsLoading}
					isLoading={isLoading}
					selectedVersionId={selectedVersionId}
					setSelectedVersionId={setSelectedVersionId}
				/>
			)}

			{!isSelecting && (
				<div className={'containerAccess'}>
					<p className={'accessibilityTitle'}>{t('translation.noteDescriptive')}</p>
					<textarea
						cols={30}
						rows={10}
						placeholder={t('translation.noteDescriptive')}
						className={'noteDescriptive'}
						value={notes}
						onChange={(e) => setNotes(e.target.value)}
						onBlur={() => {
							if (selectedVersion) {
								updateVersion(selectedVersion.id, { notes });
							}
						}}
						disabled={
							file?.createdBy?.agencyId !== agencyId &&
							file?.createdBy?.userId !== uid &&
							!(
								(file?.createdBy?.userType === 'client' ||
									clients?.map((client) => client.id).includes(file?.createdBy?.agencyId)) &&
								(userType === 'clients' || clients?.map((client) => client.id).includes(agencyId))
							)
						}
					/>
				</div>
			)}

			{!file?.isOptionFolder &&
				!isInTrash &&
				userType !== 'clients' &&
				!clients?.map((it) => it.id).includes(agencyId) && (
					<div className={'containerAccess'}>
						{(canModify || (filterPartnerId === agencyId && isAdmin)) && (
							<p className={'accessibilityTitle'}>{t('translation.accessibility')}</p>
						)}

						{filterPartnerId === agencyId && isAdmin && (
							<>
								<ItemAccessibilityInternal
									permission={
										selectedFiles.every((it) => it.adminPermissions?.includes(agencyId))
											? 'adminOnly'
											: 'all'
									}
									onClick={async (newValue) => {
										const previousValue = selectedFiles.every((it) =>
											it.adminPermissions?.includes(agencyId)
										)
											? 'adminOnly'
											: 'all';
										await handlePermission(agencyId, previousValue, newValue);
									}}
									disabled={false}
									withLoading={true}
								/>

								<div
									style={{
										width: 'calc(100% - 60px)',
										height: 1,
										backgroundColor: '#E5E5E5',
										marginBottom: 15,
										borderRadius: 10,
									}}
								/>
							</>
						)}

						{canModify && (
							<>
								{(filterPartnerId === agencyId || filterPartnerId === 'clients') &&
									selectedFiles.every(
										(it) =>
											it.createdBy.agencyId === agencyId ||
											it.createdBy.userId === uid ||
											(it.createdBy.userType !== 'client' &&
												!clients?.map((item) => item.id).includes(it.createdBy.agencyId) &&
												agencyId === project?.creator)
									) && (
										<ItemAccessibility
											name={t('common.mo')}
											permission={
												selectedFiles.every((it) => it.clientPermissionReadOnly)
													? 'readonly'
													: selectedFiles.every((it) => it.clientPermission)
														? 'all'
														: 'hidden'
											}
											onClick={async (newValue) => {
												const previousValue = selectedFiles.every(
													(it) => it.clientPermissionReadOnly
												)
													? 'readonly'
													: selectedFiles.every((it) => it.clientPermission)
														? 'all'
														: 'hidden';
												await handlePermission('client', previousValue, newValue);
											}}
											diminutif={t('translation.terms.0.acronym')}
											withLoading={true}
										/>
									)}
								{clients.length === 0 && partners.length === 0 && (
									<p className={'noPartners'}>{t('translation.addMoAndPartners')}</p>
								)}
								{userType !== 'clients' && clients.length > 0 && partners.length === 0 && (
									<p className={'noPartners'}>{t('translation.addPartners')}</p>
								)}
								{filterPartnerId === agencyId
									? partners
											.filter(
												(it) => !selectedFiles.some((file) => file.createdBy.agencyId === it.id)
											)
											.map((it) => (
												<ItemAccessibility
													key={it.id}
													name={it.name}
													permission={
														selectedFiles.every((file) =>
															file.permissionsReadOnly?.includes(it.id)
														)
															? 'readonly'
															: selectedFiles.every((file) =>
																		file.permissions?.includes(it.id)
																  )
																? 'all'
																: 'hidden'
													}
													onClick={async (newValue) => {
														const previousValue = selectedFiles.every((file) =>
															file.permissionsReadOnly?.includes(it.id)
														)
															? 'readonly'
															: selectedFiles.every((file) =>
																		file.permissions?.includes(it.id)
																  )
																? 'all'
																: 'hidden';
														await handlePermission(it.id, previousValue, newValue);
													}}
													diminutif={it.diminutifAgency ?? getFirstLetters(it.name)}
													withLoading={true}
												/>
											))
									: filterPartnerId !== 'clients' && (
											<ItemAccessibility
												name={partners.find((it) => it.id === filterPartnerId)?.name}
												permission={
													selectedFiles.every((file) =>
														file.permissionsReadOnly?.includes(filterPartnerId)
													)
														? 'readonly'
														: selectedFiles.every((file) =>
																	file.permissions?.includes(filterPartnerId)
															  )
															? 'all'
															: 'hidden'
												}
												onClick={async (newValue) => {
													const previousValue = selectedFiles.every((file) =>
														file.permissionsReadOnly?.includes(filterPartnerId)
													)
														? 'readonly'
														: selectedFiles.every((file) =>
																	file.permissions?.includes(filterPartnerId)
															  )
															? 'all'
															: 'hidden';
													await handlePermission(filterPartnerId, previousValue, newValue);
												}}
												diminutif={
													partners.find((it) => it.id === filterPartnerId)?.diminutifAgency ??
													getFirstLetters(
														partners.find((it) => it.id === filterPartnerId)?.name
													)
												}
												withLoading={true}
											/>
										)}
							</>
						)}
					</div>
				)}

			{isInTrash && (
				<button onClick={restoreFile} className={'restoreButton'}>
					{isLoading ? t('translation.restoreInProgress') : t('translation.restoreElementToOriginalPlace')}
				</button>
			)}

			{!file?.isOptionFolder && canModify && (
				<div className={'containerConfirmLecture'}>
					<div className={'bar'} />
					<div className={'header'}>
						<p className={'title'}>
							{t('translation.consultConfirm')}{' '}
							<span className={'seenLength'}>{selectedVersion?.seenBy?.length}</span>
						</p>
						<p
							className={'buttonPlusMoins'}
							onClick={() => {
								if (extendConsult) {
									setExtendConsult(false);
								} else {
									setExtendConsult(true);
								}
							}}>
							{extendConsult ? t('translation.reduce') : t('common.see_more')}
						</p>
					</div>

					<div className={'listConsult'} style={{ height: extendConsult ? null : 0 }}>
						{selectedVersion?.seenBy?.map((it, index) => (
							<SeenByComponent key={index} userId={it.userId} seenAt={it.seenAt} />
						))}
					</div>

					<div className={'bar'} style={{ marginTop: 3 }} />
				</div>
			)}

			<div style={{ height: 80, width: 30 }} />

			<ModalAddVersion
				modalAddVersion={modalAddVersion}
				setModalAddVersion={setModalAddVersion}
				file={file}
				parentFolder={parentFolder}
			/>
		</div>
	);
}
