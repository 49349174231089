import React, { useContext, useEffect, useState } from 'react';
import TextMessage from './TextMessage';
import '../../messaging.scss';
import ImageMessage from './ImageMessage';
import FileMessage from './FileMessage';
import { auth, firestore } from '../../../../firebase/config';
import { joinPaths } from '../../../../firebase/utils';
import DistributedSeen from './DistributedSeen';
import TypeContext from '../../../../contexts/typeContext';
import AgencyContext from '../../../../contexts/agencyContext';
import downIcon from '../../../../assets/downIcon.svg';
import ContextMenu from '../../../../components/ContextMenu/ContextMenu';
import { useTranslation } from 'react-i18next';

export default function Message({
	conversation,
	message,
	isMyMessage,
	isLastMessage,
	selectFile,
	selectPicture,
	setResponseMessage,
	receiverLastClickAt,
	contacts,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [isSeen, setIsSeen] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

	const sender = contacts?.find((contact) => contact.id === message?.sender);

	useEffect(() => {
		if (receiverLastClickAt && message?.date && receiverLastClickAt > message.date) {
			setIsSeen(true);
		}
	}, [receiverLastClickAt, message]);

	const handleContextMenu = (event) => {
		event.preventDefault();
		setMenuPosition({ x: event.pageX - 5, y: event.pageY + 15 });
		setTimeout(() => {
			setShowMenu(true);
		}, 100);
	};

	const onDelete = async () => {
		if (conversation.isGroup) {
			await firestore.doc(joinPaths('messaging', conversation.id, 'messages', message.id)).delete();
		} else {
			await firestore
				.doc(
					conversation.userType === 'collaborators'
						? joinPaths(
								'agencies',
								conversation.userAgencyId,
								'collaborators',
								conversation.id,
								'messaging',
								uid,
								'messages',
								message.id
							)
						: joinPaths('clients', conversation.id, 'messaging', uid, 'messages', message.id)
				)
				.delete();
			await firestore
				.doc(
					type === 'collaborators'
						? joinPaths(
								'agencies',
								agencyId,
								'collaborators',
								uid,
								'messaging',
								conversation.id,
								'messages',
								message.id
							)
						: joinPaths('users', uid, 'messaging', conversation.id, 'messages', message.id)
				)
				.delete();
		}
	};

	const onRespond = () => {
		setResponseMessage(message);
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(message.contents);
	};

	const handleDownload = () => {
		window.open(message.contents, '_blank');
	};

	const options =
		isMyMessage && !isSeen
			? [
					{ label: t('common.respond'), action: onRespond },
					{
						label: message.type === 'text' ? t('common.copy') : t('common.download'),
						action: message.type === 'text' ? handleCopy : handleDownload,
					},
					{ label: t('common.delete'), action: onDelete, color: '#d80000' },
				]
			: [
					{ label: t('common.respond'), action: onRespond },
					{
						label: message.type === 'text' ? t('common.copy') : t('common.download'),
						action: message.type === 'text' ? handleCopy : handleDownload,
					},
				];

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignSelf: isMyMessage ? 'end' : 'start' }}>
			<div className={`containerMessage ${isMyMessage ? 'sent' : 'received'}`}>
				<div className={'optionsContainer'} onClick={handleContextMenu}>
					<img src={downIcon} alt="" className={'optionsIcon'} />
				</div>

				{!isMyMessage && conversation.isGroup && <p className={'senderName'}>{sender?.name}</p>}

				{message.type === 'text' ? (
					<TextMessage
						isMyMessage={message.sender === uid}
						isGroup={conversation.isGroup}
						value={message.contents}
						responseMessage={message.responseMessage}
						contacts={contacts}
					/>
				) : message.type === 'picture' || message.type === 'video' ? (
					<ImageMessage message={message} selectPicture={selectPicture} />
				) : message.type === 'file' ? (
					<FileMessage message={message} selectFile={selectFile} />
				) : null}
			</div>

			{isLastMessage && isMyMessage && !message.isLocal && <DistributedSeen isSeen={isSeen} />}

			{showMenu && (
				<ContextMenu
					x={menuPosition.x}
					y={menuPosition.y}
					onClose={() => setShowMenu(false)}
					options={options}
				/>
			)}
		</div>
	);
}
