import React, { useState, useContext } from 'react';
import { firestore, auth } from '../../../../firebase/config';
import checkBlanc from '../../../../assets/checkBlanc.png';
import bluePlus from '../../../../assets/bluePlus.svg';
import './Step.scss';
import { useProjectContext } from '../../../../contexts/projectContext';
import ModalTyping from '../../../../components/ModalTyping/ModalTyping';
import { generateUniqueFirestoreId } from '../../../../firebase/utils';
import { useTranslation } from 'react-i18next';
import SubStep from '../SubStep/SubStep';
import { nextStatus, ProgressionStatus } from '../utils';
import Colors from '../../../../useful/Colors';
import deleteIcon from '../../../../assets/deleteIcon.png';
import { wait } from '../../../../useful/UsefulFunctions';
import mixpanel from 'mixpanel-browser';
import DuplicateIcon from '../../../../assets/plus.square.on.square.svg';
import AgencyContext from '../../../../contexts/agencyContext';

export default function Step({ setPhases, stepData, canModify, isEdition }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const projectId = project?.id;

	const [modalNewSubStep, setModalNewSubStep] = useState(false);
	const [indexCreation, setIndexCreation] = useState(-1);

	const toggleStatus = async () => {
		const newStatus = nextStatus(stepData.status);

		const batch = firestore.batch();
		const updates = [];

		setPhases((prevState) => {
			const nextState = [...prevState];

			const phase = nextState.find((phase) => phase.id === stepData.phaseId);
			const step = phase.stepList?.find((step) => step.id === stepData.id);
			step.status = newStatus;

			updates.push({
				docRef: firestore.doc(`projects/${projectId}/progression/${stepData.id}`),
				status: newStatus,
			});

			switch (newStatus) {
				case ProgressionStatus.COMPLETED: {
					step.subStepList?.forEach((subStep) => {
						subStep.status = ProgressionStatus.COMPLETED;

						const subStepRef = firestore.doc(`projects/${projectId}/progression/${subStep.id}`);
						updates.push({ docRef: subStepRef, status: ProgressionStatus.COMPLETED });
					});

					if (phase.stepList?.every((step) => step.status === ProgressionStatus.COMPLETED)) {
						phase.status = ProgressionStatus.COMPLETED;

						const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
						updates.push({ docRef: phaseRef, status: ProgressionStatus.COMPLETED });
					}

					mixpanel.track('Step Completed', {
						'Project ID': projectId,
						Page: 'progression',
					});

					break;
				}

				case ProgressionStatus.IN_PROGRESS: {
					phase.status = ProgressionStatus.IN_PROGRESS;

					const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
					updates.push({ docRef: phaseRef, status: ProgressionStatus.IN_PROGRESS });

					mixpanel.track('Step In Progress', {
						'Project ID': projectId,
						Page: 'progression',
					});

					break;
				}

				case ProgressionStatus.NOT_STARTED: {
					step.subStepList?.forEach((subStep) => {
						subStep.status = ProgressionStatus.NOT_STARTED;
						const subStepRef = firestore.doc(`projects/${projectId}/progression/${subStep.id}`);
						updates.push({ docRef: subStepRef, status: ProgressionStatus.NOT_STARTED });
					});

					if (phase.stepList?.every((step) => step.status === ProgressionStatus.NOT_STARTED)) {
						phase.status = ProgressionStatus.NOT_STARTED;

						const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
						updates.push({ docRef: phaseRef, status: ProgressionStatus.NOT_STARTED });
					} else {
						phase.status = ProgressionStatus.IN_PROGRESS;

						const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
						updates.push({ docRef: phaseRef, status: ProgressionStatus.IN_PROGRESS });
					}

					mixpanel.track('Step Not Started', {
						'Project ID': projectId,
						Page: 'progression',
					});

					break;
				}
			}

			return nextState;
		});

		await wait(500);

		updates.forEach(({ docRef, status }) => {
			batch.update(docRef, { status });
		});

		await batch.commit();

		mixpanel.track('Sub Step Created', {
			'Project ID': projectId,
			Page: 'progression',
		});
	};

	const newSubStep = async (name) => {
		const batch = firestore.batch();

		const data = {
			id: generateUniqueFirestoreId(),
			name: name ? name : t('progression_page.new_step'),
			completed: false,
			date: new Date().toISOString(),
			phaseId: stepData.phaseId,
			stepId: stepData.id,
			type: 'subStep',
		};

		setPhases((prevState) => {
			const newPhases = [...prevState];
			const newSteps = newPhases.find((phase) => phase.id === stepData.phaseId).stepList;
			const newStep = newSteps.find((step) => step.id === stepData.id);
			newStep.subStepList = newStep.subStepList ?? [];
			newStep.subStepList.splice(indexCreation, 0, data);

			newStep.subStepList.forEach((subStep, index) => {
				if (index > indexCreation) {
					const date = new Date(new Date().getTime() + index).toISOString();
					subStep.date = date;

					const subStepRef = firestore.doc(`projects/${projectId}/progression/${subStep.id}`);
					batch.update(subStepRef, { date });
				}
			});

			if (newStep.status === ProgressionStatus.COMPLETED) {
				batch.update(firestore.doc(`projects/${projectId}/progression/${stepData.id}`), {
					status: ProgressionStatus.IN_PROGRESS,
				});
				batch.update(firestore.doc(`projects/${projectId}/progression/${stepData.phaseId}`), {
					status: ProgressionStatus.IN_PROGRESS,
				});
			}

			return newPhases;
		});

		batch.set(firestore.doc(`projects/${projectId}/progression/${data.id}`), data);

		await wait(500);

		await batch.commit();
	};

	const changeName = (name) => {
		setPhases((prevState) => {
			const newPhases = [...prevState];
			const phase = newPhases?.find((phase) => phase.id === stepData.phaseId);
			const step = phase?.stepList?.find((step) => step.id === stepData.id);
			step.name = name;
			return newPhases;
		});
	};

	const endChangeName = async () => {
		await firestore.doc(`projects/${projectId}/progression/${stepData.id}`).update({ name: stepData.name });
	};

	const deleteStep = async () => {
		if (confirm(t('progression_page.sure_to_delete_step'))) {
			const batch = firestore.batch();

			setPhases((prevState) => {
				const newPhases = [...prevState];
				const phase = newPhases.find((phase) => phase.id === stepData.phaseId);
				const step = stepData;
				phase.stepList = phase.stepList?.filter((item) => item.id !== step.id);

				const stepRef = firestore.doc(`projects/${projectId}/progression/${step.id}`);
				batch.delete(stepRef);

				for (const subStep of step.subStepList ?? []) {
					const subStepRef = firestore.doc(`projects/${projectId}/progression/${subStep.id}`);
					batch.delete(subStepRef);
				}

				if (
					phase.stepList?.length > 0 &&
					phase.stepList.every((step) => step.status === ProgressionStatus.COMPLETED)
				) {
					phase.status = ProgressionStatus.COMPLETED;
					const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
					batch.update(phaseRef, { status: ProgressionStatus.COMPLETED });
				} else if (
					phase.stepList?.some(
						(step) =>
							step.status === ProgressionStatus.IN_PROGRESS || step.status === ProgressionStatus.COMPLETED
					)
				) {
					phase.status = ProgressionStatus.IN_PROGRESS;
					const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
					batch.update(phaseRef, { status: ProgressionStatus.IN_PROGRESS });
				} else {
					phase.status = ProgressionStatus.NOT_STARTED;
					const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
					batch.update(phaseRef, { status: ProgressionStatus.NOT_STARTED });
				}

				return newPhases;
			});

			await wait(100);

			await batch.commit();
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.target.blur();
		}
	};

	const duplicateStep = async () => {
		const batch = firestore.batch();

		setPhases((prevState) => {
			const newPhases = [...prevState];
			const phase = newPhases.find((phase) => phase.id === stepData.phaseId);

			const newStepId = generateUniqueFirestoreId();

			const nextStep = phase.stepList.find((step) => step.date > stepData.date);
			const newStepDate =
				nextStep === undefined
					? new Date().toISOString()
					: new Date(new Date(nextStep.date).getTime() - 1).toISOString();

			const newStep = {
				...stepData,
				id: newStepId,
				date: newStepDate,
				name: stepData.name,
				status: ProgressionStatus.NOT_STARTED,
				subStepList: stepData.subStepList?.map((subStep) => {
					const newSubStepId = generateUniqueFirestoreId();
					return {
						...subStep,
						id: newSubStepId,
						stepId: newStepId,
						status: ProgressionStatus.NOT_STARTED,
					};
				}),
			};

			// Trouver l'index du step original et insérer le nouveau juste après
			const originalIndex = phase.stepList.findIndex((step) => step.id === stepData.id);
			phase.stepList.splice(originalIndex + 1, 0, newStep);

			// Mettre à jour le statut de la phase si nécessaire
			if (phase.status === ProgressionStatus.COMPLETED) {
				phase.status = ProgressionStatus.IN_PROGRESS;
			}

			// Sauvegarder le nouveau step dans Firestore
			const newStepRef = firestore.doc(`projects/${projectId}/progression/${newStep.id}`);
			batch.set(newStepRef, {
				id: newStep.id,
				name: newStep.name,
				status: newStep.status,
				date: newStep.date,
				agency: agencyId,
				uid: uid,
				phaseId: newStep.phaseId,
				type: 'step',
			});

			// Sauvegarder les sub-steps si présents
			if (newStep.subStepList) {
				newStep.subStepList.forEach((subStep) => {
					const subStepRef = firestore.doc(`projects/${projectId}/progression/${subStep.id}`);
					batch.set(subStepRef, subStep);
				});
			}

			// Mettre à jour le statut de la phase si nécessaire
			const phaseRef = firestore.doc(`projects/${projectId}/progression/${stepData.phaseId}`);
			batch.update(phaseRef, { status: phase.status });

			return newPhases;
		});

		// Attendre que l'état soit mis à jour
		await wait(500);

		// Exécuter le batch
		await batch.commit();
	};

	return (
		<div className="etapePhaseContainer">
			{!isEdition && canModify && (
				<img className={'duplicateIcon'} src={DuplicateIcon} alt={'duplicate icon'} onClick={duplicateStep} />
			)}
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div
					className={`etapePhase ${canModify && !isEdition ? 'hover' : ''}`}
					onClick={canModify && !isEdition ? toggleStatus : null}>
					<div
						className={'stepCircle'}
						style={{
							backgroundColor:
								stepData.status === ProgressionStatus.COMPLETED
									? Colors.PROGRESSION_GREEN
									: stepData.status === ProgressionStatus.IN_PROGRESS
										? Colors.PROGRESSION_BLUE
										: Colors.PROGRESSION_VERY_LIGHT_BLUE,
						}}>
						{stepData.status === ProgressionStatus.COMPLETED && <img src={checkBlanc} alt="" />}
					</div>
					{isEdition ? (
						<input
							type={'text'}
							className={'titleEtapePhase progressionInput'}
							placeholder={t('progression_page.step_name')}
							value={stepData.name}
							onChange={(e) => changeName(e.target.value)}
							onBlur={endChangeName}
							onKeyDown={handleKeyDown}
						/>
					) : (
						<p className={'titleEtapePhase'}>{stepData.name}</p>
					)}

					{isEdition && (
						<img src={deleteIcon} alt={'delete'} className={'stepDeleteIcon hover'} onClick={deleteStep} />
					)}
				</div>

				{stepData.subStepList && stepData.subStepList.length > 0 ? (
					<div className={'stepContent'}>
						<div className={'barSubStep'}>
							<div
								className={'frontBarSubSteps'}
								style={{
									height: `calc(100% * ${
										stepData.subStepList.length
											? Math.round(
													(stepData.subStepList.filter(
														(item) =>
															item.status === ProgressionStatus.IN_PROGRESS ||
															item.status === ProgressionStatus.COMPLETED
													).length /
														stepData.subStepList.length) *
														100
												)
											: 0
									} / 100)`,
								}}>
								<div
									className={'frontBarSubSteps'}
									style={{
										height: `calc(100% * ${
											stepData.subStepList.length
												? Math.round(
														(stepData.subStepList.filter(
															(item) => item.status === ProgressionStatus.COMPLETED
														).length /
															stepData.subStepList.filter(
																(item) =>
																	item.status === ProgressionStatus.IN_PROGRESS ||
																	item.status === ProgressionStatus.COMPLETED
															).length) *
															100
													)
												: 0
										} / 100)`,
										backgroundColor: Colors.PROGRESSION_GREEN,
									}}
								/>
							</div>
						</div>
						<div className={'stepSubStepsContainer'}>
							{canModify && !isEdition ? (
								<AddStep
									onClick={() => {
										setIndexCreation(0);
										setModalNewSubStep(true);
									}}
								/>
							) : (
								<div style={{ height: 27 }} />
							)}
							{stepData.subStepList &&
								stepData.subStepList.map((subStep, index) => (
									<div key={subStep.id}>
										<SubStep
											subStepData={subStep}
											setPhases={setPhases}
											isEdition={isEdition}
											canModify={canModify}
										/>
										{canModify && !isEdition ? (
											<AddStep
												onClick={() => {
													setIndexCreation(index + 1);
													setModalNewSubStep(true);
												}}
											/>
										) : (
											<div style={{ height: 27 }} />
										)}
									</div>
								))}
						</div>
					</div>
				) : canModify && !isEdition ? (
					<div style={{ marginLeft: 32 }}>
						<AddStep
							onClick={() => {
								setIndexCreation(0);
								setModalNewSubStep(true);
							}}
						/>
					</div>
				) : (
					<div style={{ height: 27 }} />
				)}

				<ModalTyping
					modalTyping={modalNewSubStep}
					setModalTyping={setModalNewSubStep}
					title={t('translation.subStepName')}
					func={newSubStep}
				/>
			</div>
		</div>
	);
}

function AddStep({ onClick }) {
	const { t } = useTranslation();

	return (
		<div className={'addSubStep hover'} style={{ zIndex: 1 }} onClick={onClick}>
			<div className={'barAddSubStep'} />
			<img src={bluePlus} alt={''} />
			<p>{t('progression_page.add_a_sub_step')}</p>
		</div>
	);
}
