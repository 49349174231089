import React, { useContext, useState } from 'react';
import folderIcon from '../../../assets/folderIcon.png';
import searchIcon from '../../../assets/searchIcon.png';
import bigCheckBleu from '../../../assets/bigCheckBleu.png';
import upBlue from '../../../assets/upBlue.svg';
import { adjustText, dateString, desescapeSpecialChars } from '../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';

import { getNotificationObject, NotificationObject, NotificationSource } from './utils';
import { chooseIconFile } from '../Documents/utils';
import { Pages } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useProjectContext } from '../../../contexts/projectContext';
import { auth, firestore, functions } from '../../../firebase/config';
import {
	AGENCY_COLLECTION,
	CLIENT_COLLECTION,
	COLLABORATOR_COLLECTION,
	NEW_USER_COLLECTION,
	NOTIFICATION_COLLECTION,
	PROJECT_COLLECTION,
} from '../../../firebase/paths';
import AgencyContext from '../../../contexts/agencyContext';
import { httpsCallable } from 'firebase/functions';
import AuthDataContext from '../../../contexts/authDataContext';
import ModalPartnerPermissions from '../../../components/Modal Partner Permissions/ModalPartnerPermissions';
import { joinPaths } from '../../../firebase/utils';
import { Paths } from '../../../firebase/paths';
import { useClientsContext } from 'src/contexts/clientsContext';

export function ProfileNotification({ initiales, entreprise, type, collabEntreprise }) {
	return (
		<div
			className={'profileComponent'}
			style={{
				borderRadius: entreprise && 8,
				backgroundColor: collabEntreprise ? '#E7ECFE' : type === 'client' ? '#83d98a' : null,
				color: collabEntreprise && '#5b84e8',
			}}>
			{initiales}
		</div>
	);
}

export function TextContentNotification({
	initializer,
	object,
	multiple,
	sameAgency,
	folderName,
	visitDate,
	numberVisit,
	phaseName,
	percentage,
	type,
	albumName,
	agencyName,
	role,
}) {
	const { t } = useTranslation();

	return (
		<p className={'textContentNotification'}>
			<span style={{ fontWeight: 600 }}>{initializer}</span> {''}
			{desescapeSpecialChars(
				t(
					`notifications.${object}${multiple ? 'Multiple' : ''}${
						object?.includes('photosAdded') || type === NotificationSource.PROGRESSION
							? ''
							: sameAgency
								? 'SameAgency'
								: ''
					}`,
					{
						percentage: percentage,
						phaseName: phaseName,
						numberVisit: numberVisit,
						visitDate: visitDate,
						agencyName: agencyName,
						role: role,
					}
				)
			)}
			{folderName && `"${folderName}".`}
			{albumName && `"${albumName}".`}
		</p>
	);
}

export function DecorationNotification({ notification, senderName }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const uid = auth.currentUser?.uid;

	const [project] = useProjectContext();
	const agencyId = useContext(AgencyContext);
	const authData = useContext(AuthDataContext);
	const [clients] = useClientsContext();

	const projectId = project?.id;

	const [isLoading, setIsLoading] = useState(false);
	const [modalPermissions, setModalPermissions] = useState(false);

	const handleSavePermissions = (permissions) => {
		setModalPermissions(false);
		handleAcceptJoinProject(permissions);
	};

	const handleAcceptJoinProject = async (permissions) => {
		setIsLoading(true);
		firestore
			.collection(AGENCY_COLLECTION)
			.doc(agencyId)
			.collection(COLLABORATOR_COLLECTION)
			.get()
			.then((querySnapshot) => {
				const promises = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists && doc.id !== notification.createdBy.userId) {
						promises.push(
							new Promise((resolve, reject) => {
								firestore
									.collection(NEW_USER_COLLECTION)
									.doc(doc.id)
									.collection(NOTIFICATION_COLLECTION)
									.doc(notification.id)
									.update({
										data: [
											{
												...notification.data[0],
												status: 'accepted',
												responseUserName: authData.surname + ' ' + authData.name,
												responseUser: {
													userId: uid,
													userType: 'collaborator',
													agencyId: agencyId,
												},
											},
										],
									})
									.then(() => {
										resolve();
									})
									.catch((error) => {
										reject(error);
									});
							})
						);
					}
				});
				Promise.all(promises).then(() => {
					setIsLoading(false);
				});
			});
		if (notification.createdBy.agencyId === agencyId) {
			await firestore
				.collection(AGENCY_COLLECTION)
				.doc(agencyId)
				.collection(COLLABORATOR_COLLECTION)
				.doc(notification.createdBy.userId)
				.update({
					waitingStatus: 'accepted',
				});
		}
		const call = httpsCallable(functions, 'addUserToProject');
		call({
			createdBy: notification.createdBy,
			projectId,
			parent: notification.parent,
			data: notification.data,
			senderName: senderName,
			projectName: project.name,
			projectImgUrl: project.imgUrl,
		}).then((r) => {
			if (
				notification.createdBy?.userType === 'collaborator' &&
				!notification.data?.roleIds?.includes('0') &&
				notification.createdBy.agencyId !== agencyId
			) {
				firestore
					.doc(joinPaths(Paths.PROJECTS, projectId, Paths.ACCOUNTS, notification.createdBy.agencyId))
					.update({
						permissions: permissions,
					});
			}
		});
	};

	const handleDenyJoinProject = async () => {
		setIsLoading(true);
		firestore
			.collection(AGENCY_COLLECTION)
			.doc(agencyId)
			.collection(COLLABORATOR_COLLECTION)
			.get()
			.then((querySnapshot) => {
				const promises = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists && doc.id !== notification.createdBy.userId) {
						promises.push(
							new Promise((resolve, reject) => {
								firestore
									.collection(NEW_USER_COLLECTION)
									.doc(doc.id)
									.collection(NOTIFICATION_COLLECTION)
									.doc(notification.id)
									.update({
										data: [
											{
												...notification.data[0],
												status: 'denied',
												responseUserName: authData.surname + ' ' + authData.name,
												responseUser: {
													userId: uid,
													userType: 'collaborator',
													agencyId: agencyId,
												},
											},
										],
									})
									.then(() => {
										resolve();
									})
									.catch((error) => {
										reject(error);
									});
							})
						);
					}
				});
				Promise.all(promises).then(() => {
					setIsLoading(false);
				});
			});
		if (notification.createdBy.agencyId === agencyId) {
			await firestore
				.collection(AGENCY_COLLECTION)
				.doc(agencyId)
				.collection(COLLABORATOR_COLLECTION)
				.doc(notification.createdBy.userId)
				.update({
					waitingStatus: 'denied',
				});
		}
		if (notification.createdBy.userType === 'collaborator') {
			await firestore
				.collection(AGENCY_COLLECTION)
				.doc(notification.createdBy.agencyId)
				.collection(COLLABORATOR_COLLECTION)
				.doc(notification.createdBy.userId)
				.collection(PROJECT_COLLECTION)
				.doc(projectId)
				.update({
					waitingStatus: 'denied',
				});
		} else {
			await firestore
				.collection(CLIENT_COLLECTION)
				.doc(notification.createdBy.userId)
				.collection(PROJECT_COLLECTION)
				.doc(projectId)
				.update({
					waitingStatus: 'denied',
				});
		}
	};

	switch (getNotificationObject(notification)) {
		case NotificationObject.NEW_FOLDER_ADDED:
		case NotificationObject.NEW_DOC_ADDED:
			return (
				<div className={'newDocAdded'}>
					<div
						className={'folderContainer hover'}
						onClick={async () => {
							localStorage.setItem('page', Pages.FICHIERS);
							navigate(`/fiche-projet`, {
								state: {
									page: Pages.FICHIERS,
									projectId: projectId,
									path: notification.parent.path.split('/').filter((e) => e !== ''),
									selectedFileIds: [notification.parent.id],
								},
							});
						}}>
						<img src={folderIcon} alt="" />
						{adjustText(notification.parent?.name, 35)}
						<div className={'buttonItem'}>
							<img src={searchIcon} alt="" className={'searchIconItem'} />
						</div>
					</div>
					<div className={'documentsList'}>
						<div className={'blueBar'} style={{ height: `calc(41px * ${notification?.data?.length}` }} />
						<div className={'documentsListContent'}>
							{Array.isArray(notification?.data) &&
								notification?.data?.map((doc, index) => (
									<div
										key={index}
										className={'documentItemNotification hover'}
										onClick={async () => {
											localStorage.setItem('page', Pages.FICHIERS);
											navigate(`/fiche-projet`, {
												state: {
													page: Pages.FICHIERS,
													projectId: projectId,
													path: doc.path.split('/').filter((e) => e !== ''),
													selectedFileIds: [doc.id],
												},
											});
										}}>
										<img src={chooseIconFile(doc.isFolder ? 'folder' : doc.type)} alt="" />
										{adjustText(doc.name, 26)}
										<div className={'buttonItem'}>
											<img src={searchIcon} alt="" className={'searchIconItem'} />
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			);
		case NotificationObject.NEW_ALBUM_CREATED:
			return (
				<div
					className={'newAlbumCreated hover'}
					onClick={async () => {
						localStorage.setItem('page', Pages.PHOTOS);
						navigate(`/fiche-projet`, {
							state: {
								page: Pages.PHOTOS,
								projectId: projectId,
								selectedAlbumId: notification.data[0]?.id,
							},
						});
					}}>
					<img src={notification.data[0]?.url ?? project.imgUrl} alt="" className={'albumPicture'} />
					<div style={{ marginTop: 5 }}>
						{notification.data[0]?.name}
						<div className={'buttonItem'} style={{ margin: 0, marginTop: 8 }}>
							<img src={searchIcon} alt="" className={'searchIconItem'} style={{ margin: 0 }} />
						</div>
					</div>
				</div>
			);
		case NotificationObject.NEW_VISIT_CREATED:
			return (
				<div
					className={'newAlbumCreated hover'}
					onClick={async () => {
						localStorage.setItem('page', Pages.PHOTOS);
						navigate(`/fiche-projet`, {
							state: {
								page: Pages.PHOTOS,
								projectId: projectId,
								selectedAlbumId: notification.data[0]?.id,
								visit: true,
							},
						});
					}}>
					<div className={'mosaiquePicture newVisitCreated'}>
						{notification.data[0]?.thumbnails?.map((thumbnail, index) => (
							<img src={thumbnail.url} alt="" className={'pictureMosaique'} key={index} />
						))}
					</div>
					<div style={{ marginTop: 5 }}>
						{t('common.visit')} {notification.data[0]?.name?.split(' ')[1]} :{' '}
						{dateString(t, notification.data[0]?.orderDate, true)}
						<div className={'buttonItem'} style={{ margin: 0, marginTop: 8 }}>
							<img src={searchIcon} alt="" className={'searchIconItem'} style={{ margin: 0 }} />
						</div>
					</div>
				</div>
			);
		case NotificationObject.PHOTOS_ADDED_TO_ALBUM:
			return (
				<div
					className={'listPhotos'}
					onClick={async () => {
						localStorage.setItem('page', Pages.PHOTOS);
						navigate(`/fiche-projet`, {
							state: {
								page: Pages.PHOTOS,
								projectId: projectId,
								selectedAlbumId: notification.data.id,
							},
						});
					}}>
					{notification.data.map((photo, index) => (
						<img src={photo.url} alt="" className={'photoList'} key={index} />
					))}
				</div>
			);
		case NotificationObject.PHOTOS_ADDED_TO_VISIT:
			return (
				<div
					className={'listPhotos'}
					onClick={async () => {
						localStorage.setItem('page', Pages.PHOTOS);
						navigate(`/fiche-projet`, {
							state: {
								page: Pages.PHOTOS,
								projectId: projectId,
								selectedAlbumId: notification.data.id,
								visit: true,
							},
						});
					}}>
					{notification.data.map((photo, index) => (
						<img src={photo.url} alt="" className={'photoList'} key={index} />
					))}
				</div>
			);
		case NotificationObject.VALID_PHASE:
			return (
				<div
					onClick={async () => {
						localStorage.setItem('page', Pages.PROGRESSION);
						navigate(`/fiche-projet`, {
							state: {
								page: Pages.PROGRESSION,
								projectId: projectId,
							},
						});
					}}>
					<div className={'validPhase'}>
						<img src={bigCheckBleu} alt="" className={'checkBleu'} />
						{adjustText(`${t('notifications.phase')} : ${notification.parent?.name}`, 33)}

						<div className={'buttonItem'}>
							<img src={searchIcon} alt="" className={'searchIconItem'} style={{ margin: 0 }} />
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div className={'blueBar'} />
						<div className={'validPhase'}>
							<img src={bigCheckBleu} alt="" className={'checkBleu'} />
							{adjustText(notification.data[0]?.name, 29)}
							<div className={'buttonItem'}>
								<img src={searchIcon} alt="" className={'searchIconItem'} style={{ margin: 0 }} />
							</div>
						</div>
					</div>
				</div>
			);
		case NotificationObject.VALID_STEP:
			return (
				<div
					className={'validPhase'}
					onClick={async () => {
						localStorage.setItem('page', Pages.PROGRESSION);
						navigate(`/fiche-projet`, {
							state: {
								page: Pages.PROGRESSION,
								projectId: projectId,
							},
						});
					}}>
					<img src={bigCheckBleu} alt="" className={'checkBleu'} />
					{adjustText(notification.data[0]?.name, 33)}
					<div className={'buttonItem'}>
						<img src={searchIcon} alt="" className={'searchIconItem'} style={{ margin: 0 }} />
					</div>
				</div>
			);
		case NotificationObject.PROGRESSION_PERCENTAGE_MODIFIED:
			return (
				<div
					style={{ display: 'flex', alignItems: 'center' }}
					onClick={async () => {
						localStorage.setItem('page', Pages.PROGRESSION);
						navigate(`/fiche-projet`, {
							state: {
								page: Pages.PROGRESSION,
								projectId: projectId,
							},
						});
					}}>
					<p className={'percentage'}>{notification.parent?.progression}%</p>
					<img src={upBlue} alt="" className={'upBlue'} />
				</div>
			);
		case NotificationObject.NEW_COLLAB_IN_PROJECT:
			return null;
		case NotificationObject.NEW_AGENCY_IN_PROJECT:
			return null;
		case NotificationObject.ACCEPT_TO_JOIN_PROJECT:
			if (notification.data[0].status === 'pending') {
				return (
					<div className={'containerButtons'}>
						<button
							className={'acceptButton'}
							onClick={() => {
								if (
									notification?.createdBy?.userType === 'collaborator' &&
									notification.createdBy.agencyId !== agencyId &&
									!notification.parent?.roleIds?.includes('0')
								) {
									setModalPermissions(true);
								} else {
									handleAcceptJoinProject();
								}
							}}
							disabled={isLoading}>
							{t('translation.accept')}
						</button>
						<button className={'refuseButton'} onClick={handleDenyJoinProject} disabled={isLoading}>
							{t('translation.refuse')}{' '}
						</button>

						<ModalPartnerPermissions
							isOpen={modalPermissions}
							onClose={() => setModalPermissions(false)}
							partnerName={notification.parent?.name}
							withOverlay={true}
							withSaveButton={true}
							handleSave={handleSavePermissions}
						/>
					</div>
				);
			} else if (notification.data[0].status === 'accepted') {
				return (
					<div>
						<p className={'detailNotif'}>
							<span className={'blueText'}>{notification.data[0].responseUserName}</span>{' '}
							{t('translation.hasAccepted')}
						</p>
					</div>
				);
			} else if (notification.data[0].status === 'denied') {
				return (
					<div>
						<p className={'detailNotif'}>
							<span className={'blueText'}>{notification.data[0].responseUserName}</span>{' '}
							{t('translation.hasRefused')}
						</p>
					</div>
				);
			}
		default:
			return null;
	}
}
