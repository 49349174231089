import { adjustText, transformString } from '../../../../../useful/UsefulFunctions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth, firestore, functions } from '../../../../../firebase/config';
import AgencyContext from '../../../../../contexts/agencyContext';
import { deleteUserInProject } from '../../../../../useful/UsefulForProject';
import { createdByConstructor } from '../../../utils';
import AgencyDataContext from '../../../../../contexts/agencyDataContext';
import { httpsCallable } from 'firebase/functions';

export default function ItemListAddCollab({
	collabId,
	projectId,
	actors,
	research,
	isActive,
	setResearchNumber,
	projectName,
	projectTag,
	mandataireUid,
	agencyAccessMessaging,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const uid = auth.currentUser?.uid;
	const agencyData = useContext(AgencyDataContext);

	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState();
	const [surname, setSurname] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
	const [imgUrl, setImgUrl] = useState();
	const [subtype, setSubtype] = useState();
	const [oldResearch, setOldResearch] = useState(false);
	const [collabData, setCollabData] = useState();

	useEffect(() => {
		firestore
			.doc(`agencies/${agency}/collaborators/${collabId}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setName(documentSnapshot.data().name);
					setSurname(documentSnapshot.data().surname);
					setPhone(documentSnapshot.data().phone);
					setEmail(documentSnapshot.data().email);
					setImgUrl(documentSnapshot.data().imgUrl);
					setSubtype(documentSnapshot.data().subtype);
					setCollabData(documentSnapshot.data());
				}
			});
	}, [collabId, agency, projectId]);

	useEffect(() => {
		if (name) {
			if (transformString(name).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(name).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, name]);

	return (
		<>
			{transformString(surname + name + email).includes(transformString(research)) && (
				<div className={'itemListUser'}>
					<div className={'leftPartItemUser'}>
						<div className={'profilePictureBleu'}>
							<p
								style={{
									letterSpacing: 1,
									fontSize: 15,
									margin: 0,
								}}>
								{surname && surname?.charAt(0)?.toUpperCase()}
								{name && name?.charAt(0)?.toUpperCase()}
							</p>
						</div>

						<div className={'nameEmail'}>
							<p className={'name'}>{name && adjustText(surname + ' ' + name, 26)}</p>
							<p className={'email'}>{name && adjustText(email, 32)}</p>
						</div>
					</div>

					<div className={'rightPartItemUser'}>
						<div className={'phoneNumber'}>{phone ? phone : t('translation.noPhoneNumber')}</div>

						{collabId !== uid || mandataireUid !== agency ? (
							actors && actors.includes(collabId) ? (
								<div
									className={'retirer hover'}
									onClick={async () => {
										if (
											window.confirm(
												`${t('collab_list_item.sure_to_remove_collab', { name: name })}`
											)
										) {
											setIsLoading(true);
											await deleteUserInProject(
												collabId,
												'collaborators',
												agency,
												projectId,
												agency
											);
											setIsLoading(false);
										}
									}}>
									{t('translation.extirp')}
								</div>
							) : (
								<div
									className={'ajouter hover'}
									onClick={async () => {
										if (!isLoading) {
											setIsLoading(true);
											const call = httpsCallable(functions, 'addUserToProject');
											call({
												createdBy: createdByConstructor(collabId, 'collaborator', agency),
												projectId: projectId,
												parent: agencyData,
												data: collabData,
												senderName: surname + ' ' + name,
												projectName: projectName,
												projectImgUrl: '',
												withoutNotification: true,
											}).then((r) => {
												console.log(r);
												setIsLoading(false);
											});
										}
									}}>
									{isLoading ? t('common.loading') : t('translation.add')}
								</div>
							)
						) : null}
					</div>
				</div>
			)}
		</>
	);
}
