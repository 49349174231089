import React, { useContext, useEffect, useState } from 'react';
import NotificationItem from './NotificationItem';
import { useProjectContext } from '../../../contexts/projectContext';
import { auth, firestore, functions } from '../../../firebase/config';
import { NEW_USER_COLLECTION, NOTIFICATION_COLLECTION } from '../../../firebase/paths';
import whiteBell from '../../../assets/whiteBell.svg';
import wraning from '../../../assets/wraning.svg';
import ONotifications from '../../../assets/ONotifications.png';
import { useTranslation } from 'react-i18next';
import { updateBadge } from './utils';
import { createdByConstructor } from '../utils';
import { httpsCallable } from 'firebase/functions';
import AuthDataContext from 'src/contexts/authDataContext';
import AgencyDataContext from 'src/contexts/agencyDataContext';
import AgencyContext from 'src/contexts/agencyContext';

export default function NotificationsContainer({ isModeLecture, displayNotifications, setDisplayNotifications }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const authData = useContext(AuthDataContext);
	const agencyId = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const [project] = useProjectContext();

	const [notifications, setNotifications] = useState([]);
	const [lastDocumentSnapshot, setLastDocumentSnapshot] = useState(null);
	const [becomeStakeholderIsLoading, setBecomeStakeholderIsLoading] = useState(false);

	useEffect(() => {
		if (uid && project.id) {
			const subscriber = firestore
				.collection(NEW_USER_COLLECTION)
				.doc(uid)
				.collection(NOTIFICATION_COLLECTION)
				.where('projectId', '==', project.id)
				.where('isUnread', 'in', [true, false])
				.orderBy('createdAt', 'desc')
				.limit(31)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const notifications = [];
						querySnapshot.forEach((doc) => {
							if (doc.exists) {
								notifications.push({ id: doc.id, ...doc.data() });
							}
						});
						setLastDocumentSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
						setNotifications(notifications);

						if (notifications.filter((item) => item.isUnread).length > 0) {
							setDisplayNotifications(true);
						}
					}
				});
			return () => subscriber();
		}
	}, [uid && project.id]);

	const loadMoreNotifications = (e) => {
		if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
			if (uid && project.id && lastDocumentSnapshot) {
				firestore
					.collection(NEW_USER_COLLECTION)
					.doc(uid)
					.collection(NOTIFICATION_COLLECTION)
					.where('projectId', '==', project.id)
					.where('isUnread', 'in', [true, false])
					.orderBy('createdAt', 'desc')
					.startAfter(lastDocumentSnapshot)
					.limit(31)
					.get()
					.then((querySnapshot) => {
						if (querySnapshot) {
							const notifications = [];
							querySnapshot.forEach((doc) => {
								if (doc.exists) {
									notifications.push({ id: doc.id, ...doc.data() });
								}
							});
							setLastDocumentSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
							setNotifications((prevNotifications) => [...prevNotifications, ...notifications]);
						}
					});
			}
		}
	};

	const readAllNotifications = () => {
		if (uid && project.id) {
			firestore
				.collection(NEW_USER_COLLECTION)
				.doc(uid)
				.collection(NOTIFICATION_COLLECTION)
				.where('projectId', '==', project.id)
				.where('isUnread', '==', true)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						doc.ref.update({ isUnread: false }).then((r) => null);
					});
					updateBadge(uid);
					setDisplayNotifications(!displayNotifications);
				});
		}
	};

	const becomeStakeholder = () => {
		if (project && authData && agencyData) {
			setBecomeStakeholderIsLoading(true);
			const call = httpsCallable(functions, 'addUserToProject');
			call({
				createdBy: createdByConstructor(uid, 'collaborator', agencyId),
				projectId: project.id,
				parent: agencyData,
				data: authData,
				senderName: authData.surname + ' ' + authData.name,
				projectName: project.name,
				projectImgUrl: '',
			}).then((r) => {
				setBecomeStakeholderIsLoading(false);
			});
		}
	};

	return (
		<>
			<div className={'notificationsButton'} onClick={() => setDisplayNotifications(!displayNotifications)}>
				{notifications.filter((item) => item.isUnread).length > 0 && (
					<div
						className={'notifBadge'}
						style={{
							padding: notifications.filter((item) => item.isUnread).length < 10 && 0,
							width: notifications.filter((item) => item.isUnread).length < 10 && 20,
						}}>
						{notifications.filter((item) => item.isUnread).length > 30
							? '30+'
							: notifications.filter((item) => item.isUnread).length}
					</div>
				)}
				{isModeLecture && (
					<div className={'wraning'}>
						<img src={wraning} alt="" />
					</div>
				)}
				<img src={whiteBell} alt="" className={'whiteBell'} />
			</div>
			<div className={`notificationContainer ${displayNotifications ? 'animate' : ''}`}>
				<div className={'headerContainerNotifs'}>
					<p className={'title'}>{t('translation.notifications')}</p>
					{!isModeLecture && (
						<p className={'buttonRead'} onClick={readAllNotifications}>
							{t('translation.markAllAsRead')}
						</p>
					)}
				</div>

				<div className={'notificationsList'} onScroll={loadMoreNotifications}>
					{isModeLecture ? (
						<div className={'noNotifLecture'}>
							<img src={wraning} alt="" style={{ width: 30, height: 30, marginBottom: 10 }} />
							<p style={{ opacity: '0.7' }}>
								{t('translation.noNotification')}
								<br />
								<br />
								{t('translation.needToBePartOfThisProject')} <br /> <br />
								{t('translation.canAskAdmin')}
							</p>

							<div
								className={'becomeStockholderButton hover'}
								onClick={becomeStakeholder}
								style={{ opacity: becomeStakeholderIsLoading ? '0.5' : '1' }}>
								{becomeStakeholderIsLoading
									? t('common.loading')
									: t('translation.becomeStakeholderOfThisProject')}
							</div>
						</div>
					) : notifications.length > 0 ? (
						notifications.map((notification, index) => (
							<NotificationItem key={index} notification={notification} />
						))
					) : (
						<div className={'placeholderNotifications'}>
							<img src={ONotifications} alt="" className={'ONotifications'} />
							<p className={'titleNotifications'}>{t('translation.notifications')}</p>
							<p className={'subtitlePlaceholderNoNotif'}>{t('translation.placeholderNotifications')}</p>
							<p className={'subtitlePlaceholderNoNotif'}>{t('translation.placeholderNotifications2')}</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
