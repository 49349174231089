import pdfIcon from '../../../assets/pdfIcon.svg';
import textIcon from '../../../assets/textIcon.svg';
import csvIcon from '../../../assets/csvIcon.svg';
import folder from '../../../assets/folder.png';
import emptyFolder from '../../../assets/emptyFolder.png';
import fileIcon from '../../../assets/fileIcon.svg';
import videoIcon from '../../../assets/videoIcon.svg';
import pictureIcon from '../../../assets/pictureIcon.png';
import { firestore, functions } from '../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../firebase/paths';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

/**
 * Return a formatted string of the given bytes
 * @param {number} bytes
 * @param {number} decimals
 * @returns {string}
 */
export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Octets';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	let formattedNumber = (bytes / Math.pow(k, i)).toFixed(dm);

	// Remplacer le point par une virgule et supprimer les zéros inutiles
	formattedNumber = formattedNumber.replace('.', ',').replace(/,?0+$/, '');

	return formattedNumber + ' ' + sizes[i];
}

/**
 * Return the icon file corresponding to the given type
 * @param {string} type
 * @returns {string}
 */
export function chooseIconFile(type) {
	if (type === 'application/pdf') {
		return pdfIcon;
	} else if (type?.includes('image') || type?.includes('video') || type?.includes('heic') || type?.includes('heif')) {
		return pictureIcon;
	} else if (type?.includes('video')) {
		return videoIcon;
	} else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
		return textIcon;
	} else if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
		return csvIcon;
	} else if (type === 'folder') {
		return folder;
	} else if (type === 'emptyFolder') {
		return emptyFolder;
	} else {
		return fileIcon;
	}
}

export async function modifyPermissionsFile(
	project,
	setFiles,
	file,
	permissionIds,
	clientPermission,
	permissionsReadOnly,
	clientPermissionReadOnly,
	deletePermissions,
	adminPermissionId
) {
	const previousData = { ...file };

	setFiles((prevState) => {
		const nextState = [...prevState];
		const index = nextState.findIndex((f) => f.id === file.id);
		if (index !== -1) {
			if (adminPermissionId) {
				if (!deletePermissions) {
					nextState[index].adminPermissions = [
						...(nextState[index].adminPermissions ?? []),
						adminPermissionId,
					];
				} else {
					nextState[index].adminPermissions = nextState[index].adminPermissions.filter(
						(permissionId) => permissionId !== adminPermissionId
					);
				}
			}
			if (permissionIds) {
				if (!deletePermissions) {
					nextState[index].permissions = [...(nextState[index].permissions ?? []), ...permissionIds];
					nextState[index].permissionsReadOnly = [
						...(nextState[index].permissionsReadOnly?.filter(
							(permissionId) => !permissionIds.includes(permissionId)
						) ?? []),
						...(permissionsReadOnly ?? []),
					];
				} else {
					nextState[index].permissions = nextState[index].permissions.filter(
						(permissionId) => !permissionIds.includes(permissionId)
					);
					nextState[index].permissionsReadOnly =
						nextState[index].permissionsReadOnly?.filter(
							(permissionId) => !permissionIds.includes(permissionId)
						) ?? [];
				}
			}
			if (clientPermission) {
				nextState[index].clientPermission = !deletePermissions;
				nextState[index].clientPermissionReadOnly = clientPermissionReadOnly && !deletePermissions;
			}
		}
		return nextState;
	});

	const nextData = { ...previousData };
	if (adminPermissionId) {
		if (!deletePermissions) {
			nextData.adminPermissions = [...(nextData.adminPermissions ?? []), adminPermissionId];
		} else {
			nextData.adminPermissions = nextData.adminPermissions.filter(
				(permissionId) => permissionId !== adminPermissionId
			);
		}
	}
	if (permissionIds) {
		if (!deletePermissions) {
			nextData.permissions = [...(nextData.permissions ?? []), ...permissionIds];
			nextData.permissionsReadOnly = [
				...(nextData.permissionsReadOnly?.filter((permissionId) => !permissionIds.includes(permissionId)) ??
					[]),
				...(permissionsReadOnly ?? []),
			];
		} else {
			nextData.permissions = nextData.permissions.filter((permissionId) => !permissionIds.includes(permissionId));
			nextData.permissionsReadOnly =
				nextData.permissionsReadOnly?.filter((permissionId) => !permissionIds.includes(permissionId)) ?? [];
		}
	}
	if (clientPermission) {
		nextData.clientPermission = !deletePermissions;
		nextData.clientPermissionReadOnly = clientPermissionReadOnly && !deletePermissions;
	}

	const send = httpsCallable(functions, 'modifyFilePermissions');
	await send({
		projectId: project.id,
		previousData,
		nextData,
	});
}

export const favoritesId = 'favorites';
export const recentsId = 'recents';
export const trashId = 'trash';

export const optionFolders = [
	{
		id: favoritesId,
		isFolder: true,
		name: 'Favoris',
		path: '/',
		isOptionFolder: true,
	},
	{
		id: recentsId,
		isFolder: true,
		name: 'Récents',
		path: '/',
		isOptionFolder: true,
	},
	{
		id: trashId,
		isFolder: true,
		name: 'Supprimés récemment',
		path: '/',
		isOptionFolder: true,
	},
];
