import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AgencyContext from '../../../contexts/agencyContext';
import TypeContext from '../../../contexts/typeContext';
import white from '../../../assets/white.jpg';
import lockIcon from '../../../assets/lockIcon.svg';
import { useTranslation } from 'react-i18next';
import { auth, firestore } from '../../../firebase/config';
import { Pages } from '../../Fiche Projet/utils';
import { NEW_USER_COLLECTION, NOTIFICATION_COLLECTION } from '../../../firebase/paths';
import { convertHeicToJpeg } from '../../../useful/medias';
import { TICKET_STATUS } from '../../Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';
import { collection, query, where, or, onSnapshot, and } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { Paths } from '../../../firebase/paths';

export default function ProjetCard({
	id,
	waitingForApproval,
	setModalWaitForApproval,
	accessDenied,
	setModalAccessDenied,
	projectData,
	isMandataire,
	isModeLecture,
	showNotifications,
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const uid = auth.currentUser?.uid;
	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);

	const [notificationsCount, setNotificationsCount] = useState(0);
	const [ticketPastille, setTicketPastille] = useState(0);

	const [realBar1, setRealBar1] = useState(0);
	const [realBar2, setRealBar2] = useState(0);
	const [realBar3, setRealBar3] = useState(0);
	const [realBar4, setRealBar4] = useState(0);

	const [count, setCount] = useState(false);

	const [projectImg, setProjectImg] = useState(projectData.imgUrl ?? white);

	useEffect(() => {
		if (uid && id) {
			const subscriber = firestore
				.collection(`${NEW_USER_COLLECTION}/${uid}/${NOTIFICATION_COLLECTION}`)
				.where('projectId', '==', id)
				.where('isUnread', '==', true)
				.limit(31)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						setNotificationsCount(querySnapshot.size);
					}
				});
			return () => {
				subscriber();
			};
		}
	}, [uid, id]);

	useEffect(() => {
		let isMounted = true;

		const loadImage = async () => {
			if (projectData.imgUrl) {
				try {
					const convertedUrl = await convertHeicToJpeg(projectData.imgUrl);
					if (isMounted) {
						setProjectImg(convertedUrl);
					}
				} catch (error) {
					console.error('Error loading image:', error);
					if (isMounted) {
						setProjectImg(white);
					}
				}
			}
		};

		loadImage();

		return () => {
			isMounted = false;
		};
	}, [projectData.imgUrl]);

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 200);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (count && projectData) {
				if (isNaN(projectData.progression)) {
					null;
				} else {
					setRealBar1(
						projectData.progression < 100
							? (projectData.progression - 5 > 0
									? projectData.progression - 5
									: projectData.progression) / 100
							: 100
					);
					setRealBar2(
						projectData.progression < 100
							? (projectData.progression > 0
									? parseInt(projectData.progression) + 5
									: projectData.progression) / 100
							: 100
					);
					setRealBar4(
						projectData.progression < 100
							? (projectData.progression - 2 > 0
									? projectData.progression - 2
									: projectData.progression) / 100
							: 100
					);
					setRealBar3(
						projectData.progression < 100
							? (projectData.progression > 0
									? parseInt(projectData.progression) + 2
									: projectData.progression) / 100
							: 100
					);
				}
			}
		}, 400);
	}, [count, projectData]);

	useEffect(() => {
		if (id && agency && uid) {
			const q = query(
				collection(db, Paths.PROJECTS, id, Paths.TICKETS),
				or(
					where('properties.companies', 'array-contains', agency),
					where('properties.reviewers', 'array-contains', agency),
					where('properties.users', 'array-contains', uid),
					where('properties.owner.userId', '==', uid)
				)
			);
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
				if (querySnapshot) {
					setTicketPastille(
						querySnapshot.docs.filter(
							(doc) =>
								(doc.data().properties?.type === 'issue' &&
									(!doc.data().companiesViewed?.includes(agency) ||
										(doc.data().properties?.companies?.includes(agency) &&
											(doc.data().issueStatus === TICKET_STATUS.refused ||
												doc.data().issueStatus === TICKET_STATUS.inProgress)) ||
										(doc.data().properties?.reviewers?.includes(agency) &&
											doc.data().issueStatus === TICKET_STATUS.pendingApproval &&
											!doc.data().companiesReviewed?.includes(agency)))) ||
								(doc.data().properties?.type === 'mission' &&
									!doc.data().companiesViewed?.includes(uid) &&
									((doc.data().properties?.accessibility !== 'private' &&
										doc.data().properties?.users?.includes(uid)) ||
										doc.data().owner?.userId === uid))
						).length
					);
				}
			});
			return () => unsubscribe();
		}
	}, [id, agency, uid]);

	return (
		<div>
			{projectData && (!showNotifications || notificationsCount > 0) && (
				<div
					onClick={async () => {
						if (waitingForApproval) {
							setModalWaitForApproval(id);
						} else if (accessDenied) {
							setModalAccessDenied(id);
						} else {
							navigate('/fiche-projet', { state: { projectId: id, isModeLecture: isModeLecture } });
							localStorage.setItem('projectId', id);
							localStorage.setItem('page', Pages.FICHIERS);
							if (process.env.NODE_ENV !== 'development') {
								if (type === 'collaborators') {
									await firestore
										.doc(`agencies/${agency}/${type}/${uid}/projects/${id}`)
										.update({ lastClick: new Date().toISOString(), seen: true });
									await firestore
										.doc(`agencies/${agency}/collaborators/${uid}`)
										.update({ lastConnexion: new Date().toISOString() });
								} else {
									await firestore
										.doc(`${type}/${uid}/projects/${id}`)
										.update({ lastClick: new Date().toISOString(), seen: true });
								}
							}
						}
					}}>
					<div
						className={'projetCard'}
						style={{
							cursor: 'pointer',
						}}>
						<img src={projectImg ?? white} />

						<div className="content">
							<div className="head">
								<div style={{ display: 'flex' }}>
									{waitingForApproval ? (
										<p className="name">
											{projectData.name}
											{' • '}
											<span className={'clientName'}>
												{t('translation.waitingForApproval')}
											</span>{' '}
											<img src={lockIcon} alt="" className={'lockIcon'} />
										</p>
									) : accessDenied ? (
										<p className="name">
											{projectData.name}
											{' • '}
											<span className={'clientName'}>{t('translation.noApproval')}</span>{' '}
											<img src={lockIcon} alt="" className={'lockIcon'} />
										</p>
									) : (
										<p className="name">
											{projectData.name}
											{!isMandataire && type !== 'clients' && ' • '}
											{isMandataire && projectData.clientName && ' • '}
											<span className={'clientName'}>
												{isMandataire && projectData.clientName && projectData.clientName}
											</span>{' '}
											{type === 'clients' || isMandataire ? null : (
												<span className={'sharedProject'}>
													{t('translation.sharedProject')}
												</span>
											)}
										</p>
									)}
								</div>

								<div className={'row'}>
									<div className="bar" />

									<div className={'containerPercentage'}>
										{isModeLecture && !waitingForApproval && !accessDenied && (
											<div className={'lectureSeule'}>{t('translation.readOnly')}</div>
										)}

										{type !== 'clients' && (
											<p className="pourcentage">
												{projectData.progression ? projectData.progression : 0} %
											</p>
										)}

										{type === 'clients' && (
											<p className="pourcentage">
												{projectData.progression ? projectData.progression : 0} %
											</p>
										)}

										<div
											style={{
												display: 'flex',
												marginTop: -68,
												justifyContent: 'flex-end',
												marginRight: -22,
											}}>
											{ticketPastille > 0 ? (
												<div
													className={'notifBadgeProjet'}
													style={{
														backgroundColor: '#FCF0E8',
														color: '#ff6900',
														border: 'solid 2px #FFA261FF',
														fontWeight: 700,
														marginRight: 0,
														height: 16,
														padding: ticketPastille < 10 && 0,
														width: ticketPastille < 10 && 16,
														marginLeft: 6,
													}}>
													{ticketPastille}
												</div>
											) : (
												!(notificationsCount > 0) && <div style={{ width: 17, height: 18 }} />
											)}

											{notificationsCount > 0 && (
												<div
													className={'notifBadgeProjet'}
													style={{
														padding: notificationsCount < 10 && 0,
														width: notificationsCount < 10 && 20,
														marginLeft: 6,
													}}>
													{notificationsCount > 30 ? '30+' : notificationsCount}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="progressBars">
								<div className="firstBar">
									<div
										className="secondBar"
										style={{
											width: `calc(100% * ${realBar1}`,
											maxWidth: '100%',
											backgroundColor: '#4867EC',
										}}
									/>
								</div>

								<div className="firstBar">
									<div
										className="secondBar"
										style={{
											width: `calc(100% * ${realBar2}`,
											maxWidth: '100%',
											backgroundColor: '#4867EC',
										}}
									/>
								</div>

								<div className="firstBar">
									<div
										className="secondBar"
										style={{
											width: `calc(100% * ${realBar3}`,
											maxWidth: '100%',
											backgroundColor: '#64a64a',
										}}
									/>
								</div>

								<div className="firstBar">
									<div
										className="secondBar"
										style={{
											width: `calc(100% * ${realBar4}`,
											maxWidth: '100%',
											backgroundColor: '#64a64a',
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
